@font-face {
font-family: "uicons-solid-rounded";
src: url("../../webfonts/uicons-solid-rounded.eot#iefix") format("embedded-opentype"),
url("../../webfonts/uicons-solid-rounded.woff2") format("woff2"),
url("../../webfonts/uicons-solid-rounded.woff") format("woff");
}

    i[class^="fi-sr-"]:before, i[class*=" fi-sr-"]:before, span[class^="fi-sr-"]:before, span[class*="fi-sr-"]:before {
font-family: uicons-solid-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-sr-a:before {
    content: "\f101";
    }
        .fi-sr-ad:before {
    content: "\f102";
    }
        .fi-sr-add-document:before {
    content: "\f103";
    }
        .fi-sr-add-folder:before {
    content: "\f104";
    }
        .fi-sr-add:before {
    content: "\f105";
    }
        .fi-sr-address-book:before {
    content: "\f106";
    }
        .fi-sr-air-conditioner:before {
    content: "\f107";
    }
        .fi-sr-air-freshener:before {
    content: "\f108";
    }
        .fi-sr-alarm-clock:before {
    content: "\f109";
    }
        .fi-sr-alarm-exclamation:before {
    content: "\f10a";
    }
        .fi-sr-alarm-plus:before {
    content: "\f10b";
    }
        .fi-sr-alarm-snooze:before {
    content: "\f10c";
    }
        .fi-sr-align-justify:before {
    content: "\f10d";
    }
        .fi-sr-align-left:before {
    content: "\f10e";
    }
        .fi-sr-ambulance:before {
    content: "\f10f";
    }
        .fi-sr-amusement-park:before {
    content: "\f110";
    }
        .fi-sr-angle-circle-down:before {
    content: "\f111";
    }
        .fi-sr-angle-circle-left:before {
    content: "\f112";
    }
        .fi-sr-angle-circle-right:before {
    content: "\f113";
    }
        .fi-sr-angle-circle-up:before {
    content: "\f114";
    }
        .fi-sr-angle-double-left:before {
    content: "\f115";
    }
        .fi-sr-angle-double-right:before {
    content: "\f116";
    }
        .fi-sr-angle-double-small-down:before {
    content: "\f117";
    }
        .fi-sr-angle-double-small-left:before {
    content: "\f118";
    }
        .fi-sr-angle-double-small-right:before {
    content: "\f119";
    }
        .fi-sr-angle-double-small-up:before {
    content: "\f11a";
    }
        .fi-sr-angle-down:before {
    content: "\f11b";
    }
        .fi-sr-angle-left:before {
    content: "\f11c";
    }
        .fi-sr-angle-right:before {
    content: "\f11d";
    }
        .fi-sr-angle-small-down:before {
    content: "\f11e";
    }
        .fi-sr-angle-small-left:before {
    content: "\f11f";
    }
        .fi-sr-angle-small-right:before {
    content: "\f120";
    }
        .fi-sr-angle-small-up:before {
    content: "\f121";
    }
        .fi-sr-angle-square-down:before {
    content: "\f122";
    }
        .fi-sr-angle-square-left:before {
    content: "\f123";
    }
        .fi-sr-angle-square-right:before {
    content: "\f124";
    }
        .fi-sr-angle-square-up:before {
    content: "\f125";
    }
        .fi-sr-angle-up:before {
    content: "\f126";
    }
        .fi-sr-angry:before {
    content: "\f127";
    }
        .fi-sr-apple-whole:before {
    content: "\f128";
    }
        .fi-sr-apps-add:before {
    content: "\f129";
    }
        .fi-sr-apps-delete:before {
    content: "\f12a";
    }
        .fi-sr-apps-sort:before {
    content: "\f12b";
    }
        .fi-sr-apps:before {
    content: "\f12c";
    }
        .fi-sr-archive:before {
    content: "\f12d";
    }
        .fi-sr-arrow-alt-circle-down:before {
    content: "\f12e";
    }
        .fi-sr-arrow-alt-circle-left:before {
    content: "\f12f";
    }
        .fi-sr-arrow-alt-circle-right:before {
    content: "\f130";
    }
        .fi-sr-arrow-alt-circle-up:before {
    content: "\f131";
    }
        .fi-sr-arrow-alt-down:before {
    content: "\f132";
    }
        .fi-sr-arrow-alt-from-bottom:before {
    content: "\f133";
    }
        .fi-sr-arrow-alt-from-left:before {
    content: "\f134";
    }
        .fi-sr-arrow-alt-from-right:before {
    content: "\f135";
    }
        .fi-sr-arrow-alt-from-top:before {
    content: "\f136";
    }
        .fi-sr-arrow-alt-left:before {
    content: "\f137";
    }
        .fi-sr-arrow-alt-right:before {
    content: "\f138";
    }
        .fi-sr-arrow-alt-square-down:before {
    content: "\f139";
    }
        .fi-sr-arrow-alt-square-left:before {
    content: "\f13a";
    }
        .fi-sr-arrow-alt-square-right:before {
    content: "\f13b";
    }
        .fi-sr-arrow-alt-square-up:before {
    content: "\f13c";
    }
        .fi-sr-arrow-alt-to-bottom:before {
    content: "\f13d";
    }
        .fi-sr-arrow-alt-to-left:before {
    content: "\f13e";
    }
        .fi-sr-arrow-alt-to-right:before {
    content: "\f13f";
    }
        .fi-sr-arrow-alt-to-top:before {
    content: "\f140";
    }
        .fi-sr-arrow-alt-up:before {
    content: "\f141";
    }
        .fi-sr-arrow-circle-down:before {
    content: "\f142";
    }
        .fi-sr-arrow-circle-left:before {
    content: "\f143";
    }
        .fi-sr-arrow-circle-right:before {
    content: "\f144";
    }
        .fi-sr-arrow-circle-up:before {
    content: "\f145";
    }
        .fi-sr-arrow-down:before {
    content: "\f146";
    }
        .fi-sr-arrow-from-bottom:before {
    content: "\f147";
    }
        .fi-sr-arrow-from-left:before {
    content: "\f148";
    }
        .fi-sr-arrow-from-right:before {
    content: "\f149";
    }
        .fi-sr-arrow-from-top:before {
    content: "\f14a";
    }
        .fi-sr-arrow-left:before {
    content: "\f14b";
    }
        .fi-sr-arrow-right:before {
    content: "\f14c";
    }
        .fi-sr-arrow-small-down:before {
    content: "\f14d";
    }
        .fi-sr-arrow-small-left:before {
    content: "\f14e";
    }
        .fi-sr-arrow-small-right:before {
    content: "\f14f";
    }
        .fi-sr-arrow-small-up:before {
    content: "\f150";
    }
        .fi-sr-arrow-square-down:before {
    content: "\f151";
    }
        .fi-sr-arrow-square-left:before {
    content: "\f152";
    }
        .fi-sr-arrow-square-right:before {
    content: "\f153";
    }
        .fi-sr-arrow-square-up:before {
    content: "\f154";
    }
        .fi-sr-arrow-to-bottom:before {
    content: "\f155";
    }
        .fi-sr-arrow-to-left:before {
    content: "\f156";
    }
        .fi-sr-arrow-to-right:before {
    content: "\f157";
    }
        .fi-sr-arrow-to-top:before {
    content: "\f158";
    }
        .fi-sr-arrow-trend-down:before {
    content: "\f159";
    }
        .fi-sr-arrow-trend-up:before {
    content: "\f15a";
    }
        .fi-sr-arrow-up-from-square:before {
    content: "\f15b";
    }
        .fi-sr-arrow-up-left-from-circle:before {
    content: "\f15c";
    }
        .fi-sr-arrow-up-left:before {
    content: "\f15d";
    }
        .fi-sr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f15e";
    }
        .fi-sr-arrow-up-right-from-square:before {
    content: "\f15f";
    }
        .fi-sr-arrow-up-right:before {
    content: "\f160";
    }
        .fi-sr-arrow-up-square-triangle:before {
    content: "\f161";
    }
        .fi-sr-arrow-up-to-dotted-line:before {
    content: "\f162";
    }
        .fi-sr-arrow-up:before {
    content: "\f163";
    }
        .fi-sr-arrows-alt-h:before {
    content: "\f164";
    }
        .fi-sr-arrows-alt-v:before {
    content: "\f165";
    }
        .fi-sr-arrows-alt:before {
    content: "\f166";
    }
        .fi-sr-arrows-cross:before {
    content: "\f167";
    }
        .fi-sr-arrows-from-dotted-line:before {
    content: "\f168";
    }
        .fi-sr-arrows-from-line:before {
    content: "\f169";
    }
        .fi-sr-arrows-h-copy:before {
    content: "\f16a";
    }
        .fi-sr-arrows-h:before {
    content: "\f16b";
    }
        .fi-sr-arrows-repeat-1:before {
    content: "\f16c";
    }
        .fi-sr-arrows-repeat:before {
    content: "\f16d";
    }
        .fi-sr-arrows-to-dotted-line:before {
    content: "\f16e";
    }
        .fi-sr-arrows-to-line:before {
    content: "\f16f";
    }
        .fi-sr-arrows:before {
    content: "\f170";
    }
        .fi-sr-assept-document:before {
    content: "\f171";
    }
        .fi-sr-assistive-listening-systems:before {
    content: "\f172";
    }
        .fi-sr-asterik:before {
    content: "\f173";
    }
        .fi-sr-at:before {
    content: "\f174";
    }
        .fi-sr-aubergine:before {
    content: "\f175";
    }
        .fi-sr-avocado:before {
    content: "\f176";
    }
        .fi-sr-b:before {
    content: "\f177";
    }
        .fi-sr-baby-carriage:before {
    content: "\f178";
    }
        .fi-sr-backpack:before {
    content: "\f179";
    }
        .fi-sr-bacon:before {
    content: "\f17a";
    }
        .fi-sr-badge-check:before {
    content: "\f17b";
    }
        .fi-sr-badge-dollar:before {
    content: "\f17c";
    }
        .fi-sr-badge-percent:before {
    content: "\f17d";
    }
        .fi-sr-badge-sheriff:before {
    content: "\f17e";
    }
        .fi-sr-badge:before {
    content: "\f17f";
    }
        .fi-sr-bags-shopping:before {
    content: "\f180";
    }
        .fi-sr-bahai:before {
    content: "\f181";
    }
        .fi-sr-balance-scale-left:before {
    content: "\f182";
    }
        .fi-sr-balance-scale-right:before {
    content: "\f183";
    }
        .fi-sr-balance-scale:before {
    content: "\f184";
    }
        .fi-sr-balloons:before {
    content: "\f185";
    }
        .fi-sr-ballot:before {
    content: "\f186";
    }
        .fi-sr-ban:before {
    content: "\f187";
    }
        .fi-sr-band-aid:before {
    content: "\f188";
    }
        .fi-sr-bank:before {
    content: "\f189";
    }
        .fi-sr-barber-shop:before {
    content: "\f18a";
    }
        .fi-sr-barcode-read:before {
    content: "\f18b";
    }
        .fi-sr-barcode-scan:before {
    content: "\f18c";
    }
        .fi-sr-barcode:before {
    content: "\f18d";
    }
        .fi-sr-bars-progress:before {
    content: "\f18e";
    }
        .fi-sr-baseball-alt:before {
    content: "\f18f";
    }
        .fi-sr-baseball:before {
    content: "\f190";
    }
        .fi-sr-basketball-hoop:before {
    content: "\f191";
    }
        .fi-sr-basketball:before {
    content: "\f192";
    }
        .fi-sr-bed-alt:before {
    content: "\f193";
    }
        .fi-sr-bed:before {
    content: "\f194";
    }
        .fi-sr-beer:before {
    content: "\f195";
    }
        .fi-sr-bell-ring:before {
    content: "\f196";
    }
        .fi-sr-bell-school:before {
    content: "\f197";
    }
        .fi-sr-bell:before {
    content: "\f198";
    }
        .fi-sr-bike:before {
    content: "\f199";
    }
        .fi-sr-biking-mountain:before {
    content: "\f19a";
    }
        .fi-sr-biking:before {
    content: "\f19b";
    }
        .fi-sr-billiard:before {
    content: "\f19c";
    }
        .fi-sr-blinds-open:before {
    content: "\f19d";
    }
        .fi-sr-blinds-raised:before {
    content: "\f19e";
    }
        .fi-sr-blinds:before {
    content: "\f19f";
    }
        .fi-sr-blossom:before {
    content: "\f1a0";
    }
        .fi-sr-bold:before {
    content: "\f1a1";
    }
        .fi-sr-bolt:before {
    content: "\f1a2";
    }
        .fi-sr-book-alt:before {
    content: "\f1a3";
    }
        .fi-sr-book-arrow-right:before {
    content: "\f1a4";
    }
        .fi-sr-book-arrow-up:before {
    content: "\f1a5";
    }
        .fi-sr-book-bookmark:before {
    content: "\f1a6";
    }
        .fi-sr-book-open-cover:before {
    content: "\f1a7";
    }
        .fi-sr-book-open-reader:before {
    content: "\f1a8";
    }
        .fi-sr-book:before {
    content: "\f1a9";
    }
        .fi-sr-bookmark:before {
    content: "\f1aa";
    }
        .fi-sr-books:before {
    content: "\f1ab";
    }
        .fi-sr-bottle:before {
    content: "\f1ac";
    }
        .fi-sr-bow-arrow:before {
    content: "\f1ad";
    }
        .fi-sr-bowling-ball:before {
    content: "\f1ae";
    }
        .fi-sr-bowling-pins:before {
    content: "\f1af";
    }
        .fi-sr-bowling:before {
    content: "\f1b0";
    }
        .fi-sr-box-alt:before {
    content: "\f1b1";
    }
        .fi-sr-box-ballot:before {
    content: "\f1b2";
    }
        .fi-sr-box-check:before {
    content: "\f1b3";
    }
        .fi-sr-box-tissue:before {
    content: "\f1b4";
    }
        .fi-sr-box:before {
    content: "\f1b5";
    }
        .fi-sr-boxes:before {
    content: "\f1b6";
    }
        .fi-sr-boxing-glove:before {
    content: "\f1b7";
    }
        .fi-sr-braille:before {
    content: "\f1b8";
    }
        .fi-sr-brain-circuit:before {
    content: "\f1b9";
    }
        .fi-sr-bread-slice:before {
    content: "\f1ba";
    }
        .fi-sr-bread:before {
    content: "\f1bb";
    }
        .fi-sr-briefcase:before {
    content: "\f1bc";
    }
        .fi-sr-broccoli:before {
    content: "\f1bd";
    }
        .fi-sr-broom:before {
    content: "\f1be";
    }
        .fi-sr-browser:before {
    content: "\f1bf";
    }
        .fi-sr-brush:before {
    content: "\f1c0";
    }
        .fi-sr-bug:before {
    content: "\f1c1";
    }
        .fi-sr-building:before {
    content: "\f1c2";
    }
        .fi-sr-bulb:before {
    content: "\f1c3";
    }
        .fi-sr-bullseye-pointer:before {
    content: "\f1c4";
    }
        .fi-sr-bullseye:before {
    content: "\f1c5";
    }
        .fi-sr-burrito:before {
    content: "\f1c6";
    }
        .fi-sr-bus-alt:before {
    content: "\f1c7";
    }
        .fi-sr-bus:before {
    content: "\f1c8";
    }
        .fi-sr-business-time:before {
    content: "\f1c9";
    }
        .fi-sr-butterfly:before {
    content: "\f1ca";
    }
        .fi-sr-c:before {
    content: "\f1cb";
    }
        .fi-sr-cake-birthday:before {
    content: "\f1cc";
    }
        .fi-sr-cake-wedding:before {
    content: "\f1cd";
    }
        .fi-sr-calculator:before {
    content: "\f1ce";
    }
        .fi-sr-calendar-check:before {
    content: "\f1cf";
    }
        .fi-sr-calendar-clock:before {
    content: "\f1d0";
    }
        .fi-sr-calendar-exclamation:before {
    content: "\f1d1";
    }
        .fi-sr-calendar-lines-pen:before {
    content: "\f1d2";
    }
        .fi-sr-calendar-lines:before {
    content: "\f1d3";
    }
        .fi-sr-calendar-minus:before {
    content: "\f1d4";
    }
        .fi-sr-calendar-pen:before {
    content: "\f1d5";
    }
        .fi-sr-calendar-plus:before {
    content: "\f1d6";
    }
        .fi-sr-calendar:before {
    content: "\f1d7";
    }
        .fi-sr-calendars:before {
    content: "\f1d8";
    }
        .fi-sr-call-history:before {
    content: "\f1d9";
    }
        .fi-sr-call-incoming:before {
    content: "\f1da";
    }
        .fi-sr-call-missed:before {
    content: "\f1db";
    }
        .fi-sr-call-outgoing:before {
    content: "\f1dc";
    }
        .fi-sr-camcorder:before {
    content: "\f1dd";
    }
        .fi-sr-camera:before {
    content: "\f1de";
    }
        .fi-sr-camping:before {
    content: "\f1df";
    }
        .fi-sr-candy-alt:before {
    content: "\f1e0";
    }
        .fi-sr-candy:before {
    content: "\f1e1";
    }
        .fi-sr-canned-food:before {
    content: "\f1e2";
    }
        .fi-sr-car-alt:before {
    content: "\f1e3";
    }
        .fi-sr-car-battery:before {
    content: "\f1e4";
    }
        .fi-sr-car-bolt:before {
    content: "\f1e5";
    }
        .fi-sr-car-building:before {
    content: "\f1e6";
    }
        .fi-sr-car-bump:before {
    content: "\f1e7";
    }
        .fi-sr-car-bus:before {
    content: "\f1e8";
    }
        .fi-sr-car-circle-bolt:before {
    content: "\f1e9";
    }
        .fi-sr-car-crash:before {
    content: "\f1ea";
    }
        .fi-sr-car-garage:before {
    content: "\f1eb";
    }
        .fi-sr-car-mechanic:before {
    content: "\f1ec";
    }
        .fi-sr-car-side-bolt:before {
    content: "\f1ed";
    }
        .fi-sr-car-side:before {
    content: "\f1ee";
    }
        .fi-sr-car-tilt:before {
    content: "\f1ef";
    }
        .fi-sr-car-wash:before {
    content: "\f1f0";
    }
        .fi-sr-car:before {
    content: "\f1f1";
    }
        .fi-sr-caravan-alt:before {
    content: "\f1f2";
    }
        .fi-sr-caravan:before {
    content: "\f1f3";
    }
        .fi-sr-caret-circle-down:before {
    content: "\f1f4";
    }
        .fi-sr-caret-circle-right:before {
    content: "\f1f5";
    }
        .fi-sr-caret-circle-up:before {
    content: "\f1f6";
    }
        .fi-sr-caret-down:before {
    content: "\f1f7";
    }
        .fi-sr-caret-left:before {
    content: "\f1f8";
    }
        .fi-sr-caret-quare-up:before {
    content: "\f1f9";
    }
        .fi-sr-caret-right:before {
    content: "\f1fa";
    }
        .fi-sr-caret-square-down:before {
    content: "\f1fb";
    }
        .fi-sr-caret-square-left_1:before {
    content: "\f1fc";
    }
        .fi-sr-caret-square-left:before {
    content: "\f1fd";
    }
        .fi-sr-caret-square-right:before {
    content: "\f1fe";
    }
        .fi-sr-caret-up:before {
    content: "\f1ff";
    }
        .fi-sr-carrot:before {
    content: "\f200";
    }
        .fi-sr-cars:before {
    content: "\f201";
    }
        .fi-sr-cash-register:before {
    content: "\f202";
    }
        .fi-sr-chair-office:before {
    content: "\f203";
    }
        .fi-sr-chair:before {
    content: "\f204";
    }
        .fi-sr-charging-station:before {
    content: "\f205";
    }
        .fi-sr-chart-area:before {
    content: "\f206";
    }
        .fi-sr-chart-connected:before {
    content: "\f207";
    }
        .fi-sr-chart-histogram:before {
    content: "\f208";
    }
        .fi-sr-chart-line-up:before {
    content: "\f209";
    }
        .fi-sr-chart-network:before {
    content: "\f20a";
    }
        .fi-sr-chart-pie-alt:before {
    content: "\f20b";
    }
        .fi-sr-chart-pie:before {
    content: "\f20c";
    }
        .fi-sr-chart-pyramid:before {
    content: "\f20d";
    }
        .fi-sr-chart-scatter:before {
    content: "\f20e";
    }
        .fi-sr-chart-set-theory:before {
    content: "\f20f";
    }
        .fi-sr-chart-tree:before {
    content: "\f210";
    }
        .fi-sr-chat-arrow-down:before {
    content: "\f211";
    }
        .fi-sr-chat-arrow-grow:before {
    content: "\f212";
    }
        .fi-sr-check:before {
    content: "\f213";
    }
        .fi-sr-checkbox:before {
    content: "\f214";
    }
        .fi-sr-cheese:before {
    content: "\f215";
    }
        .fi-sr-cherry:before {
    content: "\f216";
    }
        .fi-sr-chess-bishop:before {
    content: "\f217";
    }
        .fi-sr-chess-board:before {
    content: "\f218";
    }
        .fi-sr-chess-clock-alt:before {
    content: "\f219";
    }
        .fi-sr-chess-clock:before {
    content: "\f21a";
    }
        .fi-sr-chess-king-alt:before {
    content: "\f21b";
    }
        .fi-sr-chess-king:before {
    content: "\f21c";
    }
        .fi-sr-chess-knight-alt:before {
    content: "\f21d";
    }
        .fi-sr-chess-knight:before {
    content: "\f21e";
    }
        .fi-sr-chess-pawn-alt:before {
    content: "\f21f";
    }
        .fi-sr-chess-pawn:before {
    content: "\f220";
    }
        .fi-sr-chess-piece:before {
    content: "\f221";
    }
        .fi-sr-chess-queen-alt:before {
    content: "\f222";
    }
        .fi-sr-chess-queen:before {
    content: "\f223";
    }
        .fi-sr-chess-rook-alt:before {
    content: "\f224";
    }
        .fi-sr-chess-rook:before {
    content: "\f225";
    }
        .fi-sr-chess:before {
    content: "\f226";
    }
        .fi-sr-chevron-double-down:before {
    content: "\f227";
    }
        .fi-sr-chevron-double-up:before {
    content: "\f228";
    }
        .fi-sr-child-head:before {
    content: "\f229";
    }
        .fi-sr-chocolate:before {
    content: "\f22a";
    }
        .fi-sr-circle-envelope:before {
    content: "\f22b";
    }
        .fi-sr-circle-phone-flip:before {
    content: "\f22c";
    }
        .fi-sr-circle-phone-hangup:before {
    content: "\f22d";
    }
        .fi-sr-circle-phone:before {
    content: "\f22e";
    }
        .fi-sr-circle-small:before {
    content: "\f22f";
    }
        .fi-sr-circle:before {
    content: "\f230";
    }
        .fi-sr-clip:before {
    content: "\f231";
    }
        .fi-sr-clipboard-list-check:before {
    content: "\f232";
    }
        .fi-sr-clipboard-list:before {
    content: "\f233";
    }
        .fi-sr-clock-eight-thirty:before {
    content: "\f234";
    }
        .fi-sr-clock-eleven-thirty:before {
    content: "\f235";
    }
        .fi-sr-clock-eleven:before {
    content: "\f236";
    }
        .fi-sr-clock-five-thirty:before {
    content: "\f237";
    }
        .fi-sr-clock-five:before {
    content: "\f238";
    }
        .fi-sr-clock-four-thirty:before {
    content: "\f239";
    }
        .fi-sr-clock-nine-thirty:before {
    content: "\f23a";
    }
        .fi-sr-clock-nine:before {
    content: "\f23b";
    }
        .fi-sr-clock-one-thirty:before {
    content: "\f23c";
    }
        .fi-sr-clock-one:before {
    content: "\f23d";
    }
        .fi-sr-clock-seven-thirty:before {
    content: "\f23e";
    }
        .fi-sr-clock-seven:before {
    content: "\f23f";
    }
        .fi-sr-clock-six-thirty:before {
    content: "\f240";
    }
        .fi-sr-clock-six:before {
    content: "\f241";
    }
        .fi-sr-clock-ten-thirty:before {
    content: "\f242";
    }
        .fi-sr-clock-ten:before {
    content: "\f243";
    }
        .fi-sr-clock-three-thirty:before {
    content: "\f244";
    }
        .fi-sr-clock-three:before {
    content: "\f245";
    }
        .fi-sr-clock-twelve-thirty:before {
    content: "\f246";
    }
        .fi-sr-clock-twelve:before {
    content: "\f247";
    }
        .fi-sr-clock-two-thirty:before {
    content: "\f248";
    }
        .fi-sr-clock-two:before {
    content: "\f249";
    }
        .fi-sr-clock:before {
    content: "\f24a";
    }
        .fi-sr-cloud-check:before {
    content: "\f24b";
    }
        .fi-sr-cloud-disabled:before {
    content: "\f24c";
    }
        .fi-sr-cloud-download-alt:before {
    content: "\f24d";
    }
        .fi-sr-cloud-download:before {
    content: "\f24e";
    }
        .fi-sr-cloud-drizzle:before {
    content: "\f24f";
    }
        .fi-sr-cloud-hail-mixed:before {
    content: "\f250";
    }
        .fi-sr-cloud-hail:before {
    content: "\f251";
    }
        .fi-sr-cloud-moon-rain:before {
    content: "\f252";
    }
        .fi-sr-cloud-moon:before {
    content: "\f253";
    }
        .fi-sr-cloud-rain:before {
    content: "\f254";
    }
        .fi-sr-cloud-rainbow:before {
    content: "\f255";
    }
        .fi-sr-cloud-share:before {
    content: "\f256";
    }
        .fi-sr-cloud-showers-heavy:before {
    content: "\f257";
    }
        .fi-sr-cloud-showers:before {
    content: "\f258";
    }
        .fi-sr-cloud-sleet:before {
    content: "\f259";
    }
        .fi-sr-cloud-snow:before {
    content: "\f25a";
    }
        .fi-sr-cloud-sun-rain:before {
    content: "\f25b";
    }
        .fi-sr-cloud-sun:before {
    content: "\f25c";
    }
        .fi-sr-cloud-upload-alt:before {
    content: "\f25d";
    }
        .fi-sr-cloud-upload:before {
    content: "\f25e";
    }
        .fi-sr-cloud:before {
    content: "\f25f";
    }
        .fi-sr-clouds-moon:before {
    content: "\f260";
    }
        .fi-sr-clouds-sun:before {
    content: "\f261";
    }
        .fi-sr-clouds:before {
    content: "\f262";
    }
        .fi-sr-club:before {
    content: "\f263";
    }
        .fi-sr-cocktail-alt:before {
    content: "\f264";
    }
        .fi-sr-cocktail:before {
    content: "\f265";
    }
        .fi-sr-code-branch:before {
    content: "\f266";
    }
        .fi-sr-code-commit:before {
    content: "\f267";
    }
        .fi-sr-code-compare:before {
    content: "\f268";
    }
        .fi-sr-code-fork:before {
    content: "\f269";
    }
        .fi-sr-code-merge:before {
    content: "\f26a";
    }
        .fi-sr-code-pull-request-closed:before {
    content: "\f26b";
    }
        .fi-sr-code-pull-request-draft:before {
    content: "\f26c";
    }
        .fi-sr-code-pull-request:before {
    content: "\f26d";
    }
        .fi-sr-code-simple:before {
    content: "\f26e";
    }
        .fi-sr-coffee-pot:before {
    content: "\f26f";
    }
        .fi-sr-coffee:before {
    content: "\f270";
    }
        .fi-sr-coin:before {
    content: "\f271";
    }
        .fi-sr-coins:before {
    content: "\f272";
    }
        .fi-sr-comet:before {
    content: "\f273";
    }
        .fi-sr-comment-alt-middle-top:before {
    content: "\f274";
    }
        .fi-sr-comment-alt-middle:before {
    content: "\f275";
    }
        .fi-sr-comment-alt:before {
    content: "\f276";
    }
        .fi-sr-comment-arrow-down:before {
    content: "\f277";
    }
        .fi-sr-comment-arrow-up-right:before {
    content: "\f278";
    }
        .fi-sr-comment-arrow-up:before {
    content: "\f279";
    }
        .fi-sr-comment-check:before {
    content: "\f27a";
    }
        .fi-sr-comment-code:before {
    content: "\f27b";
    }
        .fi-sr-comment-dollar:before {
    content: "\f27c";
    }
        .fi-sr-comment-exclamation:before {
    content: "\f27d";
    }
        .fi-sr-comment-heart:before {
    content: "\f27e";
    }
        .fi-sr-comment-image:before {
    content: "\f27f";
    }
        .fi-sr-comment-info:before {
    content: "\f280";
    }
        .fi-sr-comment-pen:before {
    content: "\f281";
    }
        .fi-sr-comment-question:before {
    content: "\f282";
    }
        .fi-sr-comment-quote:before {
    content: "\f283";
    }
        .fi-sr-comment-slash:before {
    content: "\f284";
    }
        .fi-sr-comment-sms:before {
    content: "\f285";
    }
        .fi-sr-comment-text:before {
    content: "\f286";
    }
        .fi-sr-comment-user:before {
    content: "\f287";
    }
        .fi-sr-comment-xmark:before {
    content: "\f288";
    }
        .fi-sr-comment:before {
    content: "\f289";
    }
        .fi-sr-comments-dollar:before {
    content: "\f28a";
    }
        .fi-sr-comments-question-check:before {
    content: "\f28b";
    }
        .fi-sr-comments-question:before {
    content: "\f28c";
    }
        .fi-sr-comments:before {
    content: "\f28d";
    }
        .fi-sr-compress-alt:before {
    content: "\f28e";
    }
        .fi-sr-compress:before {
    content: "\f28f";
    }
        .fi-sr-computer:before {
    content: "\f290";
    }
        .fi-sr-confetti:before {
    content: "\f291";
    }
        .fi-sr-cookie:before {
    content: "\f292";
    }
        .fi-sr-copy-alt:before {
    content: "\f293";
    }
        .fi-sr-copy:before {
    content: "\f294";
    }
        .fi-sr-copyright:before {
    content: "\f295";
    }
        .fi-sr-corn:before {
    content: "\f296";
    }
        .fi-sr-cow:before {
    content: "\f297";
    }
        .fi-sr-cream:before {
    content: "\f298";
    }
        .fi-sr-credit-card:before {
    content: "\f299";
    }
        .fi-sr-cricket:before {
    content: "\f29a";
    }
        .fi-sr-croissant:before {
    content: "\f29b";
    }
        .fi-sr-cross-circle:before {
    content: "\f29c";
    }
        .fi-sr-cross-small:before {
    content: "\f29d";
    }
        .fi-sr-cross:before {
    content: "\f29e";
    }
        .fi-sr-crown:before {
    content: "\f29f";
    }
        .fi-sr-crystal-ball:before {
    content: "\f2a0";
    }
        .fi-sr-cube:before {
    content: "\f2a1";
    }
        .fi-sr-cupcake:before {
    content: "\f2a2";
    }
        .fi-sr-curling:before {
    content: "\f2a3";
    }
        .fi-sr-cursor-finger:before {
    content: "\f2a4";
    }
        .fi-sr-cursor-plus:before {
    content: "\f2a5";
    }
        .fi-sr-cursor-text-alt:before {
    content: "\f2a6";
    }
        .fi-sr-cursor-text:before {
    content: "\f2a7";
    }
        .fi-sr-cursor:before {
    content: "\f2a8";
    }
        .fi-sr-d:before {
    content: "\f2a9";
    }
        .fi-sr-dart:before {
    content: "\f2aa";
    }
        .fi-sr-dashboard:before {
    content: "\f2ab";
    }
        .fi-sr-data-transfer:before {
    content: "\f2ac";
    }
        .fi-sr-database:before {
    content: "\f2ad";
    }
        .fi-sr-delete-document:before {
    content: "\f2ae";
    }
        .fi-sr-delete-user:before {
    content: "\f2af";
    }
        .fi-sr-delete:before {
    content: "\f2b0";
    }
        .fi-sr-democrat:before {
    content: "\f2b1";
    }
        .fi-sr-dewpoint:before {
    content: "\f2b2";
    }
        .fi-sr-diagram-project:before {
    content: "\f2b3";
    }
        .fi-sr-diamond:before {
    content: "\f2b4";
    }
        .fi-sr-dice-alt:before {
    content: "\f2b5";
    }
        .fi-sr-dice-d10:before {
    content: "\f2b6";
    }
        .fi-sr-dice-d12:before {
    content: "\f2b7";
    }
        .fi-sr-dice-d20:before {
    content: "\f2b8";
    }
        .fi-sr-dice-d4:before {
    content: "\f2b9";
    }
        .fi-sr-dice-d6:before {
    content: "\f2ba";
    }
        .fi-sr-dice-d8:before {
    content: "\f2bb";
    }
        .fi-sr-dice-four:before {
    content: "\f2bc";
    }
        .fi-sr-dice-one:before {
    content: "\f2bd";
    }
        .fi-sr-dice-six:before {
    content: "\f2be";
    }
        .fi-sr-dice-three:before {
    content: "\f2bf";
    }
        .fi-sr-dice-two:before {
    content: "\f2c0";
    }
        .fi-sr-dice:before {
    content: "\f2c1";
    }
        .fi-sr-diploma:before {
    content: "\f2c2";
    }
        .fi-sr-disk:before {
    content: "\f2c3";
    }
        .fi-sr-display-code:before {
    content: "\f2c4";
    }
        .fi-sr-dizzy:before {
    content: "\f2c5";
    }
        .fi-sr-doctor:before {
    content: "\f2c6";
    }
        .fi-sr-document-signed:before {
    content: "\f2c7";
    }
        .fi-sr-document:before {
    content: "\f2c8";
    }
        .fi-sr-dollar:before {
    content: "\f2c9";
    }
        .fi-sr-donate:before {
    content: "\f2ca";
    }
        .fi-sr-down-left-and-up-right-to-center:before {
    content: "\f2cb";
    }
        .fi-sr-download:before {
    content: "\f2cc";
    }
        .fi-sr-drafting-compass:before {
    content: "\f2cd";
    }
        .fi-sr-dreidel:before {
    content: "\f2ce";
    }
        .fi-sr-drink-alt:before {
    content: "\f2cf";
    }
        .fi-sr-drumstick:before {
    content: "\f2d0";
    }
        .fi-sr-duplicate:before {
    content: "\f2d1";
    }
        .fi-sr-e-learning:before {
    content: "\f2d2";
    }
        .fi-sr-e:before {
    content: "\f2d3";
    }
        .fi-sr-earnings:before {
    content: "\f2d4";
    }
        .fi-sr-eclipse-alt:before {
    content: "\f2d5";
    }
        .fi-sr-eclipse:before {
    content: "\f2d6";
    }
        .fi-sr-edit-alt:before {
    content: "\f2d7";
    }
        .fi-sr-edit:before {
    content: "\f2d8";
    }
        .fi-sr-egg-fried:before {
    content: "\f2d9";
    }
        .fi-sr-egg:before {
    content: "\f2da";
    }
        .fi-sr-engine-warning:before {
    content: "\f2db";
    }
        .fi-sr-enter:before {
    content: "\f2dc";
    }
        .fi-sr-entertainment:before {
    content: "\f2dd";
    }
        .fi-sr-envelope-ban:before {
    content: "\f2de";
    }
        .fi-sr-envelope-bulk:before {
    content: "\f2df";
    }
        .fi-sr-envelope-download:before {
    content: "\f2e0";
    }
        .fi-sr-envelope-marker:before {
    content: "\f2e1";
    }
        .fi-sr-envelope-open-dollar:before {
    content: "\f2e2";
    }
        .fi-sr-envelope-open-text:before {
    content: "\f2e3";
    }
        .fi-sr-envelope-open:before {
    content: "\f2e4";
    }
        .fi-sr-envelope-plus:before {
    content: "\f2e5";
    }
        .fi-sr-envelope:before {
    content: "\f2e6";
    }
        .fi-sr-equipment:before {
    content: "\f2e7";
    }
        .fi-sr-euro:before {
    content: "\f2e8";
    }
        .fi-sr-exchange-alt:before {
    content: "\f2e9";
    }
        .fi-sr-exchange:before {
    content: "\f2ea";
    }
        .fi-sr-exclamation:before {
    content: "\f2eb";
    }
        .fi-sr-exit:before {
    content: "\f2ec";
    }
        .fi-sr-expand-arrows-alt:before {
    content: "\f2ed";
    }
        .fi-sr-expand-arrows:before {
    content: "\f2ee";
    }
        .fi-sr-expand:before {
    content: "\f2ef";
    }
        .fi-sr-eye-crossed:before {
    content: "\f2f0";
    }
        .fi-sr-eye:before {
    content: "\f2f1";
    }
        .fi-sr-f:before {
    content: "\f2f2";
    }
        .fi-sr-feather:before {
    content: "\f2f3";
    }
        .fi-sr-field-hockey:before {
    content: "\f2f4";
    }
        .fi-sr-fighter-jet:before {
    content: "\f2f5";
    }
        .fi-sr-file-ai:before {
    content: "\f2f6";
    }
        .fi-sr-file-chart-line:before {
    content: "\f2f7";
    }
        .fi-sr-file-chart-pie:before {
    content: "\f2f8";
    }
        .fi-sr-file-code:before {
    content: "\f2f9";
    }
        .fi-sr-file-eps:before {
    content: "\f2fa";
    }
        .fi-sr-file-invoice-dollar:before {
    content: "\f2fb";
    }
        .fi-sr-file-invoice:before {
    content: "\f2fc";
    }
        .fi-sr-file-psd:before {
    content: "\f2fd";
    }
        .fi-sr-file-spreadsheet:before {
    content: "\f2fe";
    }
        .fi-sr-file-video:before {
    content: "\f2ff";
    }
        .fi-sr-file:before {
    content: "\f300";
    }
        .fi-sr-fill:before {
    content: "\f301";
    }
        .fi-sr-film-slash:before {
    content: "\f302";
    }
        .fi-sr-film:before {
    content: "\f303";
    }
        .fi-sr-filter-slash:before {
    content: "\f304";
    }
        .fi-sr-filter:before {
    content: "\f305";
    }
        .fi-sr-filters:before {
    content: "\f306";
    }
        .fi-sr-fingerprint:before {
    content: "\f307";
    }
        .fi-sr-fish:before {
    content: "\f308";
    }
        .fi-sr-flag-alt:before {
    content: "\f309";
    }
        .fi-sr-flag-usa:before {
    content: "\f30a";
    }
        .fi-sr-flag:before {
    content: "\f30b";
    }
        .fi-sr-flame:before {
    content: "\f30c";
    }
        .fi-sr-flip-horizontal:before {
    content: "\f30d";
    }
        .fi-sr-flower-tulip:before {
    content: "\f30e";
    }
        .fi-sr-flower:before {
    content: "\f30f";
    }
        .fi-sr-flushed:before {
    content: "\f310";
    }
        .fi-sr-fog:before {
    content: "\f311";
    }
        .fi-sr-folder-download:before {
    content: "\f312";
    }
        .fi-sr-folder-minus:before {
    content: "\f313";
    }
        .fi-sr-folder-times:before {
    content: "\f314";
    }
        .fi-sr-folder-tree:before {
    content: "\f315";
    }
        .fi-sr-folder-upload:before {
    content: "\f316";
    }
        .fi-sr-folder:before {
    content: "\f317";
    }
        .fi-sr-folders:before {
    content: "\f318";
    }
        .fi-sr-following:before {
    content: "\f319";
    }
        .fi-sr-football:before {
    content: "\f31a";
    }
        .fi-sr-fork:before {
    content: "\f31b";
    }
        .fi-sr-form:before {
    content: "\f31c";
    }
        .fi-sr-forward:before {
    content: "\f31d";
    }
        .fi-sr-fox:before {
    content: "\f31e";
    }
        .fi-sr-french-fries:before {
    content: "\f31f";
    }
        .fi-sr-frown:before {
    content: "\f320";
    }
        .fi-sr-ftp:before {
    content: "\f321";
    }
        .fi-sr-funnel-dollar:before {
    content: "\f322";
    }
        .fi-sr-g:before {
    content: "\f323";
    }
        .fi-sr-gallery:before {
    content: "\f324";
    }
        .fi-sr-game-board-alt:before {
    content: "\f325";
    }
        .fi-sr-game:before {
    content: "\f326";
    }
        .fi-sr-gamepad:before {
    content: "\f327";
    }
        .fi-sr-games:before {
    content: "\f328";
    }
        .fi-sr-garage-car:before {
    content: "\f329";
    }
        .fi-sr-garage-open:before {
    content: "\f32a";
    }
        .fi-sr-garage:before {
    content: "\f32b";
    }
        .fi-sr-garlic:before {
    content: "\f32c";
    }
        .fi-sr-gas-pump-alt:before {
    content: "\f32d";
    }
        .fi-sr-gas-pump-slash:before {
    content: "\f32e";
    }
        .fi-sr-gas-pump:before {
    content: "\f32f";
    }
        .fi-sr-gem:before {
    content: "\f330";
    }
        .fi-sr-gif:before {
    content: "\f331";
    }
        .fi-sr-gift-card:before {
    content: "\f332";
    }
        .fi-sr-gift:before {
    content: "\f333";
    }
        .fi-sr-gifts:before {
    content: "\f334";
    }
        .fi-sr-gingerbread-man:before {
    content: "\f335";
    }
        .fi-sr-glass-cheers:before {
    content: "\f336";
    }
        .fi-sr-glass:before {
    content: "\f337";
    }
        .fi-sr-glasses:before {
    content: "\f338";
    }
        .fi-sr-globe-alt:before {
    content: "\f339";
    }
        .fi-sr-globe:before {
    content: "\f33a";
    }
        .fi-sr-golf-ball:before {
    content: "\f33b";
    }
        .fi-sr-golf-club:before {
    content: "\f33c";
    }
        .fi-sr-golf:before {
    content: "\f33d";
    }
        .fi-sr-graduation-cap:before {
    content: "\f33e";
    }
        .fi-sr-grape:before {
    content: "\f33f";
    }
        .fi-sr-graphic-tablet:before {
    content: "\f340";
    }
        .fi-sr-grid-alt:before {
    content: "\f341";
    }
        .fi-sr-grid:before {
    content: "\f342";
    }
        .fi-sr-grill:before {
    content: "\f343";
    }
        .fi-sr-grimace:before {
    content: "\f344";
    }
        .fi-sr-grin-alt:before {
    content: "\f345";
    }
        .fi-sr-grin-beam-sweat:before {
    content: "\f346";
    }
        .fi-sr-grin-beam:before {
    content: "\f347";
    }
        .fi-sr-grin-hearts:before {
    content: "\f348";
    }
        .fi-sr-grin-squint-tears:before {
    content: "\f349";
    }
        .fi-sr-grin-squint:before {
    content: "\f34a";
    }
        .fi-sr-grin-stars:before {
    content: "\f34b";
    }
        .fi-sr-grin-tears:before {
    content: "\f34c";
    }
        .fi-sr-grin-tongue-squint:before {
    content: "\f34d";
    }
        .fi-sr-grin-tongue-wink:before {
    content: "\f34e";
    }
        .fi-sr-grin-tongue:before {
    content: "\f34f";
    }
        .fi-sr-grin-wink:before {
    content: "\f350";
    }
        .fi-sr-grin:before {
    content: "\f351";
    }
        .fi-sr-guitar:before {
    content: "\f352";
    }
        .fi-sr-gym:before {
    content: "\f353";
    }
        .fi-sr-h:before {
    content: "\f354";
    }
        .fi-sr-hamburger-soda:before {
    content: "\f355";
    }
        .fi-sr-hamburger:before {
    content: "\f356";
    }
        .fi-sr-hand-holding-box:before {
    content: "\f357";
    }
        .fi-sr-hand-holding-heart:before {
    content: "\f358";
    }
        .fi-sr-hand-holding-seeding:before {
    content: "\f359";
    }
        .fi-sr-handshake:before {
    content: "\f35a";
    }
        .fi-sr-hastag:before {
    content: "\f35b";
    }
        .fi-sr-hat-birthday:before {
    content: "\f35c";
    }
        .fi-sr-hat-chef:before {
    content: "\f35d";
    }
        .fi-sr-head-side-thinking:before {
    content: "\f35e";
    }
        .fi-sr-headphones:before {
    content: "\f35f";
    }
        .fi-sr-headset:before {
    content: "\f360";
    }
        .fi-sr-heart-arrow:before {
    content: "\f361";
    }
        .fi-sr-heart:before {
    content: "\f362";
    }
        .fi-sr-heat:before {
    content: "\f363";
    }
        .fi-sr-helicopter-side:before {
    content: "\f364";
    }
        .fi-sr-highlighter:before {
    content: "\f365";
    }
        .fi-sr-hiking:before {
    content: "\f366";
    }
        .fi-sr-hockey-mask:before {
    content: "\f367";
    }
        .fi-sr-hockey-puck:before {
    content: "\f368";
    }
        .fi-sr-hockey-sticks:before {
    content: "\f369";
    }
        .fi-sr-home-location-alt:before {
    content: "\f36a";
    }
        .fi-sr-home-location:before {
    content: "\f36b";
    }
        .fi-sr-home:before {
    content: "\f36c";
    }
        .fi-sr-hotdog:before {
    content: "\f36d";
    }
        .fi-sr-hourglass-end:before {
    content: "\f36e";
    }
        .fi-sr-hourglass:before {
    content: "\f36f";
    }
        .fi-sr-house-flood:before {
    content: "\f370";
    }
        .fi-sr-hryvnia:before {
    content: "\f371";
    }
        .fi-sr-humidity:before {
    content: "\f372";
    }
        .fi-sr-hurricane:before {
    content: "\f373";
    }
        .fi-sr-i:before {
    content: "\f374";
    }
        .fi-sr-ice-cream:before {
    content: "\f375";
    }
        .fi-sr-id-badge:before {
    content: "\f376";
    }
        .fi-sr-inbox-in:before {
    content: "\f377";
    }
        .fi-sr-inbox-out:before {
    content: "\f378";
    }
        .fi-sr-inbox:before {
    content: "\f379";
    }
        .fi-sr-incognito:before {
    content: "\f37a";
    }
        .fi-sr-indent:before {
    content: "\f37b";
    }
        .fi-sr-infinity:before {
    content: "\f37c";
    }
        .fi-sr-info:before {
    content: "\f37d";
    }
        .fi-sr-interactive:before {
    content: "\f37e";
    }
        .fi-sr-interlining:before {
    content: "\f37f";
    }
        .fi-sr-interrogation:before {
    content: "\f380";
    }
        .fi-sr-italic:before {
    content: "\f381";
    }
        .fi-sr-j:before {
    content: "\f382";
    }
        .fi-sr-jam:before {
    content: "\f383";
    }
        .fi-sr-jpg:before {
    content: "\f384";
    }
        .fi-sr-k:before {
    content: "\f385";
    }
        .fi-sr-kerning:before {
    content: "\f386";
    }
        .fi-sr-key:before {
    content: "\f387";
    }
        .fi-sr-keyboard:before {
    content: "\f388";
    }
        .fi-sr-keynote:before {
    content: "\f389";
    }
        .fi-sr-kiss-beam:before {
    content: "\f38a";
    }
        .fi-sr-kiss-wink-heart:before {
    content: "\f38b";
    }
        .fi-sr-kiss:before {
    content: "\f38c";
    }
        .fi-sr-kite:before {
    content: "\f38d";
    }
        .fi-sr-knife:before {
    content: "\f38e";
    }
        .fi-sr-l:before {
    content: "\f38f";
    }
        .fi-sr-label:before {
    content: "\f390";
    }
        .fi-sr-laptop-code:before {
    content: "\f391";
    }
        .fi-sr-laptop:before {
    content: "\f392";
    }
        .fi-sr-lasso:before {
    content: "\f393";
    }
        .fi-sr-laugh-beam:before {
    content: "\f394";
    }
        .fi-sr-laugh-squint:before {
    content: "\f395";
    }
        .fi-sr-laugh-wink:before {
    content: "\f396";
    }
        .fi-sr-laugh:before {
    content: "\f397";
    }
        .fi-sr-layer-minus:before {
    content: "\f398";
    }
        .fi-sr-layer-plus:before {
    content: "\f399";
    }
        .fi-sr-layers:before {
    content: "\f39a";
    }
        .fi-sr-layout-fluid:before {
    content: "\f39b";
    }
        .fi-sr-leaf:before {
    content: "\f39c";
    }
        .fi-sr-lemon:before {
    content: "\f39d";
    }
        .fi-sr-letter-case:before {
    content: "\f39e";
    }
        .fi-sr-lettuce:before {
    content: "\f39f";
    }
        .fi-sr-level-down-alt:before {
    content: "\f3a0";
    }
        .fi-sr-level-down:before {
    content: "\f3a1";
    }
        .fi-sr-level-up-alt:before {
    content: "\f3a2";
    }
        .fi-sr-level-up:before {
    content: "\f3a3";
    }
        .fi-sr-life-ring:before {
    content: "\f3a4";
    }
        .fi-sr-lightbulb-dollar:before {
    content: "\f3a5";
    }
        .fi-sr-line-width:before {
    content: "\f3a6";
    }
        .fi-sr-link-alt:before {
    content: "\f3a7";
    }
        .fi-sr-link-horizontal-slash:before {
    content: "\f3a8";
    }
        .fi-sr-link-horizontal:before {
    content: "\f3a9";
    }
        .fi-sr-link-slash-alt:before {
    content: "\f3aa";
    }
        .fi-sr-link-slash:before {
    content: "\f3ab";
    }
        .fi-sr-link:before {
    content: "\f3ac";
    }
        .fi-sr-lipstick:before {
    content: "\f3ad";
    }
        .fi-sr-lira-sign:before {
    content: "\f3ae";
    }
        .fi-sr-list-check:before {
    content: "\f3af";
    }
        .fi-sr-list:before {
    content: "\f3b0";
    }
        .fi-sr-loading:before {
    content: "\f3b1";
    }
        .fi-sr-location-alt:before {
    content: "\f3b2";
    }
        .fi-sr-lock-alt:before {
    content: "\f3b3";
    }
        .fi-sr-lock:before {
    content: "\f3b4";
    }
        .fi-sr-luchador:before {
    content: "\f3b5";
    }
        .fi-sr-luggage-rolling:before {
    content: "\f3b6";
    }
        .fi-sr-m:before {
    content: "\f3b7";
    }
        .fi-sr-mailbox:before {
    content: "\f3b8";
    }
        .fi-sr-makeup-brush:before {
    content: "\f3b9";
    }
        .fi-sr-man-head:before {
    content: "\f3ba";
    }
        .fi-sr-map-marker-cross:before {
    content: "\f3bb";
    }
        .fi-sr-map-marker-home:before {
    content: "\f3bc";
    }
        .fi-sr-map-marker-minus:before {
    content: "\f3bd";
    }
        .fi-sr-map-marker-plus:before {
    content: "\f3be";
    }
        .fi-sr-map-marker:before {
    content: "\f3bf";
    }
        .fi-sr-map:before {
    content: "\f3c0";
    }
        .fi-sr-marker-time:before {
    content: "\f3c1";
    }
        .fi-sr-marker:before {
    content: "\f3c2";
    }
        .fi-sr-mars-double:before {
    content: "\f3c3";
    }
        .fi-sr-mars:before {
    content: "\f3c4";
    }
        .fi-sr-mask-carnival:before {
    content: "\f3c5";
    }
        .fi-sr-medicine:before {
    content: "\f3c6";
    }
        .fi-sr-megaphone:before {
    content: "\f3c7";
    }
        .fi-sr-meh-blank:before {
    content: "\f3c8";
    }
        .fi-sr-meh-rolling-eyes:before {
    content: "\f3c9";
    }
        .fi-sr-meh:before {
    content: "\f3ca";
    }
        .fi-sr-melon:before {
    content: "\f3cb";
    }
        .fi-sr-menu-burger:before {
    content: "\f3cc";
    }
        .fi-sr-menu-dots-vertical:before {
    content: "\f3cd";
    }
        .fi-sr-menu-dots:before {
    content: "\f3ce";
    }
        .fi-sr-message-code:before {
    content: "\f3cf";
    }
        .fi-sr-meteor:before {
    content: "\f3d0";
    }
        .fi-sr-microphone-alt:before {
    content: "\f3d1";
    }
        .fi-sr-microphone:before {
    content: "\f3d2";
    }
        .fi-sr-mind-share:before {
    content: "\f3d3";
    }
        .fi-sr-minus-small:before {
    content: "\f3d4";
    }
        .fi-sr-minus:before {
    content: "\f3d5";
    }
        .fi-sr-mobile-button:before {
    content: "\f3d6";
    }
        .fi-sr-mobile-notch:before {
    content: "\f3d7";
    }
        .fi-sr-mobile:before {
    content: "\f3d8";
    }
        .fi-sr-mode-landscape:before {
    content: "\f3d9";
    }
        .fi-sr-mode-portrait:before {
    content: "\f3da";
    }
        .fi-sr-money-bill-wave-alt:before {
    content: "\f3db";
    }
        .fi-sr-money-bill-wave:before {
    content: "\f3dc";
    }
        .fi-sr-money-check-edit-alt:before {
    content: "\f3dd";
    }
        .fi-sr-money-check-edit:before {
    content: "\f3de";
    }
        .fi-sr-money-check:before {
    content: "\f3df";
    }
        .fi-sr-money:before {
    content: "\f3e0";
    }
        .fi-sr-moon-stars:before {
    content: "\f3e1";
    }
        .fi-sr-moon:before {
    content: "\f3e2";
    }
        .fi-sr-motorcycle:before {
    content: "\f3e3";
    }
        .fi-sr-mountains:before {
    content: "\f3e4";
    }
        .fi-sr-mouse:before {
    content: "\f3e5";
    }
        .fi-sr-mug-alt:before {
    content: "\f3e6";
    }
        .fi-sr-mug-hot-alt:before {
    content: "\f3e7";
    }
        .fi-sr-mug-hot:before {
    content: "\f3e8";
    }
        .fi-sr-mug-tea:before {
    content: "\f3e9";
    }
        .fi-sr-mug:before {
    content: "\f3ea";
    }
        .fi-sr-mushroom:before {
    content: "\f3eb";
    }
        .fi-sr-music-alt:before {
    content: "\f3ec";
    }
        .fi-sr-music-file:before {
    content: "\f3ed";
    }
        .fi-sr-music:before {
    content: "\f3ee";
    }
        .fi-sr-n:before {
    content: "\f3ef";
    }
        .fi-sr-navigation:before {
    content: "\f3f0";
    }
        .fi-sr-network-cloud:before {
    content: "\f3f1";
    }
        .fi-sr-network:before {
    content: "\f3f2";
    }
        .fi-sr-noodles:before {
    content: "\f3f3";
    }
        .fi-sr-notebook:before {
    content: "\f3f4";
    }
        .fi-sr-o:before {
    content: "\f3f5";
    }
        .fi-sr-oil-can:before {
    content: "\f3f6";
    }
        .fi-sr-oil-temp:before {
    content: "\f3f7";
    }
        .fi-sr-olive-oil:before {
    content: "\f3f8";
    }
        .fi-sr-olives:before {
    content: "\f3f9";
    }
        .fi-sr-onion:before {
    content: "\f3fa";
    }
        .fi-sr-opacity:before {
    content: "\f3fb";
    }
        .fi-sr-overline:before {
    content: "\f3fc";
    }
        .fi-sr-p:before {
    content: "\f3fd";
    }
        .fi-sr-package:before {
    content: "\f3fe";
    }
        .fi-sr-page-break:before {
    content: "\f3ff";
    }
        .fi-sr-paint-roller:before {
    content: "\f400";
    }
        .fi-sr-paint:before {
    content: "\f401";
    }
        .fi-sr-palette:before {
    content: "\f402";
    }
        .fi-sr-pan:before {
    content: "\f403";
    }
        .fi-sr-paper-plane:before {
    content: "\f404";
    }
        .fi-sr-party:before {
    content: "\f405";
    }
        .fi-sr-password:before {
    content: "\f406";
    }
        .fi-sr-pause:before {
    content: "\f407";
    }
        .fi-sr-paw:before {
    content: "\f408";
    }
        .fi-sr-peach:before {
    content: "\f409";
    }
        .fi-sr-pencil-ruler:before {
    content: "\f40a";
    }
        .fi-sr-pencil:before {
    content: "\f40b";
    }
        .fi-sr-pennant:before {
    content: "\f40c";
    }
        .fi-sr-people-poll:before {
    content: "\f40d";
    }
        .fi-sr-pepper-hot:before {
    content: "\f40e";
    }
        .fi-sr-pepper:before {
    content: "\f40f";
    }
        .fi-sr-percentage:before {
    content: "\f410";
    }
        .fi-sr-pharmacy:before {
    content: "\f411";
    }
        .fi-sr-phone-call:before {
    content: "\f412";
    }
        .fi-sr-phone-cross:before {
    content: "\f413";
    }
        .fi-sr-phone-office:before {
    content: "\f414";
    }
        .fi-sr-phone-pause:before {
    content: "\f415";
    }
        .fi-sr-phone-slash:before {
    content: "\f416";
    }
        .fi-sr-photo-film-music:before {
    content: "\f417";
    }
        .fi-sr-photo-video:before {
    content: "\f418";
    }
        .fi-sr-physics:before {
    content: "\f419";
    }
        .fi-sr-picnic:before {
    content: "\f41a";
    }
        .fi-sr-picture:before {
    content: "\f41b";
    }
        .fi-sr-pie:before {
    content: "\f41c";
    }
        .fi-sr-piggy-bank:before {
    content: "\f41d";
    }
        .fi-sr-pineapple:before {
    content: "\f41e";
    }
        .fi-sr-pipette:before {
    content: "\f41f";
    }
        .fi-sr-pizza-slice:before {
    content: "\f420";
    }
        .fi-sr-plane-alt:before {
    content: "\f421";
    }
        .fi-sr-plane:before {
    content: "\f422";
    }
        .fi-sr-plate:before {
    content: "\f423";
    }
        .fi-sr-play-alt:before {
    content: "\f424";
    }
        .fi-sr-play-pause:before {
    content: "\f425";
    }
        .fi-sr-play:before {
    content: "\f426";
    }
        .fi-sr-plus-small:before {
    content: "\f427";
    }
        .fi-sr-plus:before {
    content: "\f428";
    }
        .fi-sr-podium-star:before {
    content: "\f429";
    }
        .fi-sr-podium:before {
    content: "\f42a";
    }
        .fi-sr-poo:before {
    content: "\f42b";
    }
        .fi-sr-popcorn:before {
    content: "\f42c";
    }
        .fi-sr-portrait:before {
    content: "\f42d";
    }
        .fi-sr-pot:before {
    content: "\f42e";
    }
        .fi-sr-pound:before {
    content: "\f42f";
    }
        .fi-sr-power:before {
    content: "\f430";
    }
        .fi-sr-presentation:before {
    content: "\f431";
    }
        .fi-sr-print:before {
    content: "\f432";
    }
        .fi-sr-protractor:before {
    content: "\f433";
    }
        .fi-sr-pulse:before {
    content: "\f434";
    }
        .fi-sr-pumpkin:before {
    content: "\f435";
    }
        .fi-sr-puzzle-piece:before {
    content: "\f436";
    }
        .fi-sr-pyramid:before {
    content: "\f437";
    }
        .fi-sr-q:before {
    content: "\f438";
    }
        .fi-sr-qrcode:before {
    content: "\f439";
    }
        .fi-sr-question-square:before {
    content: "\f43a";
    }
        .fi-sr-question:before {
    content: "\f43b";
    }
        .fi-sr-quote-right:before {
    content: "\f43c";
    }
        .fi-sr-r:before {
    content: "\f43d";
    }
        .fi-sr-racquet:before {
    content: "\f43e";
    }
        .fi-sr-radish:before {
    content: "\f43f";
    }
        .fi-sr-rainbow:before {
    content: "\f440";
    }
        .fi-sr-raindrops:before {
    content: "\f441";
    }
        .fi-sr-rec:before {
    content: "\f442";
    }
        .fi-sr-receipt:before {
    content: "\f443";
    }
        .fi-sr-record-vinyl:before {
    content: "\f444";
    }
        .fi-sr-rectabgle-vertical:before {
    content: "\f445";
    }
        .fi-sr-rectangle-barcode:before {
    content: "\f446";
    }
        .fi-sr-rectangle-code:before {
    content: "\f447";
    }
        .fi-sr-rectangle-horizontal:before {
    content: "\f448";
    }
        .fi-sr-rectangle-panoramic:before {
    content: "\f449";
    }
        .fi-sr-rectangle-xmark:before {
    content: "\f44a";
    }
        .fi-sr-recycle:before {
    content: "\f44b";
    }
        .fi-sr-redo-alt:before {
    content: "\f44c";
    }
        .fi-sr-redo:before {
    content: "\f44d";
    }
        .fi-sr-reflect:before {
    content: "\f44e";
    }
        .fi-sr-refresh:before {
    content: "\f44f";
    }
        .fi-sr-registered:before {
    content: "\f450";
    }
        .fi-sr-remove-user:before {
    content: "\f451";
    }
        .fi-sr-reply-all:before {
    content: "\f452";
    }
        .fi-sr-republican:before {
    content: "\f453";
    }
        .fi-sr-resize:before {
    content: "\f454";
    }
        .fi-sr-resources:before {
    content: "\f455";
    }
        .fi-sr-restaurant:before {
    content: "\f456";
    }
        .fi-sr-rewind:before {
    content: "\f457";
    }
        .fi-sr-rhombus:before {
    content: "\f458";
    }
        .fi-sr-rings-wedding:before {
    content: "\f459";
    }
        .fi-sr-road:before {
    content: "\f45a";
    }
        .fi-sr-rocket-lunch:before {
    content: "\f45b";
    }
        .fi-sr-rocket:before {
    content: "\f45c";
    }
        .fi-sr-room-service:before {
    content: "\f45d";
    }
        .fi-sr-rotate-left:before {
    content: "\f45e";
    }
        .fi-sr-rotate-right:before {
    content: "\f45f";
    }
        .fi-sr-ruble-sign:before {
    content: "\f460";
    }
        .fi-sr-rugby-helmet:before {
    content: "\f461";
    }
        .fi-sr-rugby:before {
    content: "\f462";
    }
        .fi-sr-ruler-combined:before {
    content: "\f463";
    }
        .fi-sr-ruler-horizontal:before {
    content: "\f464";
    }
        .fi-sr-ruler-triangle:before {
    content: "\f465";
    }
        .fi-sr-ruler-vertical:before {
    content: "\f466";
    }
        .fi-sr-running:before {
    content: "\f467";
    }
        .fi-sr-rupee-sign:before {
    content: "\f468";
    }
        .fi-sr-rv:before {
    content: "\f469";
    }
        .fi-sr-s:before {
    content: "\f46a";
    }
        .fi-sr-sack-dollar:before {
    content: "\f46b";
    }
        .fi-sr-sack:before {
    content: "\f46c";
    }
        .fi-sr-sad-cry:before {
    content: "\f46d";
    }
        .fi-sr-sad-tear:before {
    content: "\f46e";
    }
        .fi-sr-sad:before {
    content: "\f46f";
    }
        .fi-sr-salad:before {
    content: "\f470";
    }
        .fi-sr-salt-pepper:before {
    content: "\f471";
    }
        .fi-sr-sandwich:before {
    content: "\f472";
    }
        .fi-sr-sauce:before {
    content: "\f473";
    }
        .fi-sr-sausage:before {
    content: "\f474";
    }
        .fi-sr-scale:before {
    content: "\f475";
    }
        .fi-sr-school-bus:before {
    content: "\f476";
    }
        .fi-sr-school:before {
    content: "\f477";
    }
        .fi-sr-scissors:before {
    content: "\f478";
    }
        .fi-sr-screen:before {
    content: "\f479";
    }
        .fi-sr-search-alt:before {
    content: "\f47a";
    }
        .fi-sr-search-dollar:before {
    content: "\f47b";
    }
        .fi-sr-search-heart:before {
    content: "\f47c";
    }
        .fi-sr-search-location:before {
    content: "\f47d";
    }
        .fi-sr-search:before {
    content: "\f47e";
    }
        .fi-sr-select:before {
    content: "\f47f";
    }
        .fi-sr-sensor-alert:before {
    content: "\f480";
    }
        .fi-sr-sensor-fire:before {
    content: "\f481";
    }
        .fi-sr-sensor-on:before {
    content: "\f482";
    }
        .fi-sr-sensor-smoke:before {
    content: "\f483";
    }
        .fi-sr-sensor:before {
    content: "\f484";
    }
        .fi-sr-settings-sliders:before {
    content: "\f485";
    }
        .fi-sr-settings:before {
    content: "\f486";
    }
        .fi-sr-share:before {
    content: "\f487";
    }
        .fi-sr-shekel-sign:before {
    content: "\f488";
    }
        .fi-sr-shield-check:before {
    content: "\f489";
    }
        .fi-sr-shield-exclamation:before {
    content: "\f48a";
    }
        .fi-sr-shield-interrogation:before {
    content: "\f48b";
    }
        .fi-sr-shield-plus:before {
    content: "\f48c";
    }
        .fi-sr-shield:before {
    content: "\f48d";
    }
        .fi-sr-ship-side:before {
    content: "\f48e";
    }
        .fi-sr-ship:before {
    content: "\f48f";
    }
        .fi-sr-shop:before {
    content: "\f490";
    }
        .fi-sr-shopping-bag-add:before {
    content: "\f491";
    }
        .fi-sr-shopping-bag:before {
    content: "\f492";
    }
        .fi-sr-shopping-basket:before {
    content: "\f493";
    }
        .fi-sr-shopping-cart-add:before {
    content: "\f494";
    }
        .fi-sr-shopping-cart-check:before {
    content: "\f495";
    }
        .fi-sr-shopping-cart:before {
    content: "\f496";
    }
        .fi-sr-shrimp:before {
    content: "\f497";
    }
        .fi-sr-shuffle:before {
    content: "\f498";
    }
        .fi-sr-shuttle-van:before {
    content: "\f499";
    }
        .fi-sr-shuttlecock:before {
    content: "\f49a";
    }
        .fi-sr-sidebar-flip:before {
    content: "\f49b";
    }
        .fi-sr-sidebar:before {
    content: "\f49c";
    }
        .fi-sr-sign-in-alt:before {
    content: "\f49d";
    }
        .fi-sr-sign-out-alt:before {
    content: "\f49e";
    }
        .fi-sr-signal-alt-1:before {
    content: "\f49f";
    }
        .fi-sr-signal-alt-2:before {
    content: "\f4a0";
    }
        .fi-sr-signal-alt:before {
    content: "\f4a1";
    }
        .fi-sr-skateboard:before {
    content: "\f4a2";
    }
        .fi-sr-skating:before {
    content: "\f4a3";
    }
        .fi-sr-skewer:before {
    content: "\f4a4";
    }
        .fi-sr-ski-jump:before {
    content: "\f4a5";
    }
        .fi-sr-ski-lift:before {
    content: "\f4a6";
    }
        .fi-sr-skiing-nordic:before {
    content: "\f4a7";
    }
        .fi-sr-skiing:before {
    content: "\f4a8";
    }
        .fi-sr-sledding:before {
    content: "\f4a9";
    }
        .fi-sr-sleigh:before {
    content: "\f4aa";
    }
        .fi-sr-smartphone:before {
    content: "\f4ab";
    }
        .fi-sr-smile-beam:before {
    content: "\f4ac";
    }
        .fi-sr-smile-wink:before {
    content: "\f4ad";
    }
        .fi-sr-smile:before {
    content: "\f4ae";
    }
        .fi-sr-smog:before {
    content: "\f4af";
    }
        .fi-sr-smoke:before {
    content: "\f4b0";
    }
        .fi-sr-snow-blowing:before {
    content: "\f4b1";
    }
        .fi-sr-snowboarding:before {
    content: "\f4b2";
    }
        .fi-sr-snowflake:before {
    content: "\f4b3";
    }
        .fi-sr-snowflakes:before {
    content: "\f4b4";
    }
        .fi-sr-snowmobile:before {
    content: "\f4b5";
    }
        .fi-sr-snowplow:before {
    content: "\f4b6";
    }
        .fi-sr-soap:before {
    content: "\f4b7";
    }
        .fi-sr-sort-alpha-down-alt:before {
    content: "\f4b8";
    }
        .fi-sr-sort-alpha-down:before {
    content: "\f4b9";
    }
        .fi-sr-sort-alpha-up-alt:before {
    content: "\f4ba";
    }
        .fi-sr-sort-alpha-up:before {
    content: "\f4bb";
    }
        .fi-sr-sort-alt:before {
    content: "\f4bc";
    }
        .fi-sr-sort-amount-down-alt:before {
    content: "\f4bd";
    }
        .fi-sr-sort-amount-down:before {
    content: "\f4be";
    }
        .fi-sr-sort-amount-up-alt:before {
    content: "\f4bf";
    }
        .fi-sr-sort-amount-up:before {
    content: "\f4c0";
    }
        .fi-sr-sort-down:before {
    content: "\f4c1";
    }
        .fi-sr-sort-numeric-down-alt:before {
    content: "\f4c2";
    }
        .fi-sr-sort-numeric-down:before {
    content: "\f4c3";
    }
        .fi-sr-sort:before {
    content: "\f4c4";
    }
        .fi-sr-soup:before {
    content: "\f4c5";
    }
        .fi-sr-spa:before {
    content: "\f4c6";
    }
        .fi-sr-space-shuttle:before {
    content: "\f4c7";
    }
        .fi-sr-spade:before {
    content: "\f4c8";
    }
        .fi-sr-sparkles:before {
    content: "\f4c9";
    }
        .fi-sr-speaker:before {
    content: "\f4ca";
    }
        .fi-sr-sphere:before {
    content: "\f4cb";
    }
        .fi-sr-spinner:before {
    content: "\f4cc";
    }
        .fi-sr-spoon:before {
    content: "\f4cd";
    }
        .fi-sr-square-code:before {
    content: "\f4ce";
    }
        .fi-sr-square-root:before {
    content: "\f4cf";
    }
        .fi-sr-square-terminal:before {
    content: "\f4d0";
    }
        .fi-sr-square:before {
    content: "\f4d1";
    }
        .fi-sr-stamp:before {
    content: "\f4d2";
    }
        .fi-sr-star-octogram:before {
    content: "\f4d3";
    }
        .fi-sr-star:before {
    content: "\f4d4";
    }
        .fi-sr-starfighter:before {
    content: "\f4d5";
    }
        .fi-sr-stars:before {
    content: "\f4d6";
    }
        .fi-sr-stats:before {
    content: "\f4d7";
    }
        .fi-sr-steak:before {
    content: "\f4d8";
    }
        .fi-sr-steering-wheel:before {
    content: "\f4d9";
    }
        .fi-sr-stethoscope:before {
    content: "\f4da";
    }
        .fi-sr-sticker:before {
    content: "\f4db";
    }
        .fi-sr-stop:before {
    content: "\f4dc";
    }
        .fi-sr-stopwatch:before {
    content: "\f4dd";
    }
        .fi-sr-store-alt:before {
    content: "\f4de";
    }
        .fi-sr-strawberry:before {
    content: "\f4df";
    }
        .fi-sr-subtitles:before {
    content: "\f4e0";
    }
        .fi-sr-subway:before {
    content: "\f4e1";
    }
        .fi-sr-suitcase-alt:before {
    content: "\f4e2";
    }
        .fi-sr-summer:before {
    content: "\f4e3";
    }
        .fi-sr-sun:before {
    content: "\f4e4";
    }
        .fi-sr-sunrise-alt:before {
    content: "\f4e5";
    }
        .fi-sr-sunrise:before {
    content: "\f4e6";
    }
        .fi-sr-sunset:before {
    content: "\f4e7";
    }
        .fi-sr-surfing:before {
    content: "\f4e8";
    }
        .fi-sr-surprise:before {
    content: "\f4e9";
    }
        .fi-sr-sushi:before {
    content: "\f4ea";
    }
        .fi-sr-swimmer:before {
    content: "\f4eb";
    }
        .fi-sr-sword:before {
    content: "\f4ec";
    }
        .fi-sr-symbol:before {
    content: "\f4ed";
    }
        .fi-sr-syringe:before {
    content: "\f4ee";
    }
        .fi-sr-t:before {
    content: "\f4ef";
    }
        .fi-sr-table-columns:before {
    content: "\f4f0";
    }
        .fi-sr-table-layout:before {
    content: "\f4f1";
    }
        .fi-sr-table-pivot:before {
    content: "\f4f2";
    }
        .fi-sr-table-rows:before {
    content: "\f4f3";
    }
        .fi-sr-table-tree:before {
    content: "\f4f4";
    }
        .fi-sr-tablet:before {
    content: "\f4f5";
    }
        .fi-sr-tachometer-alt-average:before {
    content: "\f4f6";
    }
        .fi-sr-tachometer-alt-fastest:before {
    content: "\f4f7";
    }
        .fi-sr-tachometer-alt-slow:before {
    content: "\f4f8";
    }
        .fi-sr-tachometer-alt-slowest:before {
    content: "\f4f9";
    }
        .fi-sr-tachometer-average:before {
    content: "\f4fa";
    }
        .fi-sr-tachometer-fast:before {
    content: "\f4fb";
    }
        .fi-sr-tachometer-fastest:before {
    content: "\f4fc";
    }
        .fi-sr-tachometer-slow:before {
    content: "\f4fd";
    }
        .fi-sr-tachometer-slowest:before {
    content: "\f4fe";
    }
        .fi-sr-tachometer:before {
    content: "\f4ff";
    }
        .fi-sr-taco:before {
    content: "\f500";
    }
        .fi-sr-tags:before {
    content: "\f501";
    }
        .fi-sr-tally:before {
    content: "\f502";
    }
        .fi-sr-target:before {
    content: "\f503";
    }
        .fi-sr-taxi:before {
    content: "\f504";
    }
        .fi-sr-temperature-down:before {
    content: "\f505";
    }
        .fi-sr-temperature-frigid:before {
    content: "\f506";
    }
        .fi-sr-temperature-high:before {
    content: "\f507";
    }
        .fi-sr-temperature-low:before {
    content: "\f508";
    }
        .fi-sr-temperature-up:before {
    content: "\f509";
    }
        .fi-sr-tenge:before {
    content: "\f50a";
    }
        .fi-sr-tennis:before {
    content: "\f50b";
    }
        .fi-sr-terrace:before {
    content: "\f50c";
    }
        .fi-sr-test-tube:before {
    content: "\f50d";
    }
        .fi-sr-test:before {
    content: "\f50e";
    }
        .fi-sr-text-check:before {
    content: "\f50f";
    }
        .fi-sr-text-slash:before {
    content: "\f510";
    }
        .fi-sr-text:before {
    content: "\f511";
    }
        .fi-sr-thermometer-half:before {
    content: "\f512";
    }
        .fi-sr-thumbs-down:before {
    content: "\f513";
    }
        .fi-sr-thumbs-up:before {
    content: "\f514";
    }
        .fi-sr-thumbtack:before {
    content: "\f515";
    }
        .fi-sr-thunderstorm-moon:before {
    content: "\f516";
    }
        .fi-sr-thunderstorm-sun:before {
    content: "\f517";
    }
        .fi-sr-thunderstorm:before {
    content: "\f518";
    }
        .fi-sr-ticket:before {
    content: "\f519";
    }
        .fi-sr-time-add:before {
    content: "\f51a";
    }
        .fi-sr-time-check:before {
    content: "\f51b";
    }
        .fi-sr-time-delete:before {
    content: "\f51c";
    }
        .fi-sr-time-fast:before {
    content: "\f51d";
    }
        .fi-sr-time-forward-sixty:before {
    content: "\f51e";
    }
        .fi-sr-time-forward-ten:before {
    content: "\f51f";
    }
        .fi-sr-time-forward:before {
    content: "\f520";
    }
        .fi-sr-time-half-past:before {
    content: "\f521";
    }
        .fi-sr-time-oclock:before {
    content: "\f522";
    }
        .fi-sr-time-past:before {
    content: "\f523";
    }
        .fi-sr-time-quarter-past:before {
    content: "\f524";
    }
        .fi-sr-time-quarter-to:before {
    content: "\f525";
    }
        .fi-sr-time-twenty-four:before {
    content: "\f526";
    }
        .fi-sr-tire-flat:before {
    content: "\f527";
    }
        .fi-sr-tire-pressure-warning:before {
    content: "\f528";
    }
        .fi-sr-tire-rugged:before {
    content: "\f529";
    }
        .fi-sr-tire:before {
    content: "\f52a";
    }
        .fi-sr-tired:before {
    content: "\f52b";
    }
        .fi-sr-tomato:before {
    content: "\f52c";
    }
        .fi-sr-tool-box:before {
    content: "\f52d";
    }
        .fi-sr-tool-crop:before {
    content: "\f52e";
    }
        .fi-sr-tool-marquee:before {
    content: "\f52f";
    }
        .fi-sr-tooth:before {
    content: "\f530";
    }
        .fi-sr-tornado:before {
    content: "\f531";
    }
        .fi-sr-tractor:before {
    content: "\f532";
    }
        .fi-sr-trailer:before {
    content: "\f533";
    }
        .fi-sr-train-side:before {
    content: "\f534";
    }
        .fi-sr-train:before {
    content: "\f535";
    }
        .fi-sr-tram:before {
    content: "\f536";
    }
        .fi-sr-transform:before {
    content: "\f537";
    }
        .fi-sr-trash:before {
    content: "\f538";
    }
        .fi-sr-treasure-chest:before {
    content: "\f539";
    }
        .fi-sr-treatment:before {
    content: "\f53a";
    }
        .fi-sr-tree-christmas:before {
    content: "\f53b";
    }
        .fi-sr-tree:before {
    content: "\f53c";
    }
        .fi-sr-triangle:before {
    content: "\f53d";
    }
        .fi-sr-trophy:before {
    content: "\f53e";
    }
        .fi-sr-truck-container:before {
    content: "\f53f";
    }
        .fi-sr-truck-couch:before {
    content: "\f540";
    }
        .fi-sr-truck-loading:before {
    content: "\f541";
    }
        .fi-sr-truck-monster:before {
    content: "\f542";
    }
        .fi-sr-truck-moving:before {
    content: "\f543";
    }
        .fi-sr-truck-pickup:before {
    content: "\f544";
    }
        .fi-sr-truck-plow:before {
    content: "\f545";
    }
        .fi-sr-truck-ramp:before {
    content: "\f546";
    }
        .fi-sr-truck-side:before {
    content: "\f547";
    }
        .fi-sr-tty:before {
    content: "\f548";
    }
        .fi-sr-turkey:before {
    content: "\f549";
    }
        .fi-sr-tv-music:before {
    content: "\f54a";
    }
        .fi-sr-typewriter:before {
    content: "\f54b";
    }
        .fi-sr-u:before {
    content: "\f54c";
    }
        .fi-sr-umbrella:before {
    content: "\f54d";
    }
        .fi-sr-underline:before {
    content: "\f54e";
    }
        .fi-sr-undo-alt:before {
    content: "\f54f";
    }
        .fi-sr-undo:before {
    content: "\f550";
    }
        .fi-sr-unlock:before {
    content: "\f551";
    }
        .fi-sr-upload:before {
    content: "\f552";
    }
        .fi-sr-usb-pendrive:before {
    content: "\f553";
    }
        .fi-sr-usd-circle:before {
    content: "\f554";
    }
        .fi-sr-usd-square:before {
    content: "\f555";
    }
        .fi-sr-user-add:before {
    content: "\f556";
    }
        .fi-sr-user-time:before {
    content: "\f557";
    }
        .fi-sr-user:before {
    content: "\f558";
    }
        .fi-sr-users-alt:before {
    content: "\f559";
    }
        .fi-sr-users:before {
    content: "\f55a";
    }
        .fi-sr-utensils:before {
    content: "\f55b";
    }
        .fi-sr-v:before {
    content: "\f55c";
    }
        .fi-sr-vector-alt:before {
    content: "\f55d";
    }
        .fi-sr-vector:before {
    content: "\f55e";
    }
        .fi-sr-venus-double:before {
    content: "\f55f";
    }
        .fi-sr-venus-mars:before {
    content: "\f560";
    }
        .fi-sr-venus:before {
    content: "\f561";
    }
        .fi-sr-video-arrow-down-left:before {
    content: "\f562";
    }
        .fi-sr-video-arrow-up-right:before {
    content: "\f563";
    }
        .fi-sr-video-camera-alt:before {
    content: "\f564";
    }
        .fi-sr-video-camera:before {
    content: "\f565";
    }
        .fi-sr-video-plus:before {
    content: "\f566";
    }
        .fi-sr-video-slash:before {
    content: "\f567";
    }
        .fi-sr-volcano:before {
    content: "\f568";
    }
        .fi-sr-volleyball:before {
    content: "\f569";
    }
        .fi-sr-volume:before {
    content: "\f56a";
    }
        .fi-sr-w:before {
    content: "\f56b";
    }
        .fi-sr-wagon-covered:before {
    content: "\f56c";
    }
        .fi-sr-wallet:before {
    content: "\f56d";
    }
        .fi-sr-water-bottle:before {
    content: "\f56e";
    }
        .fi-sr-water-lower:before {
    content: "\f56f";
    }
        .fi-sr-water-rise:before {
    content: "\f570";
    }
        .fi-sr-water:before {
    content: "\f571";
    }
        .fi-sr-watermelon:before {
    content: "\f572";
    }
        .fi-sr-wheat:before {
    content: "\f573";
    }
        .fi-sr-wheelchair:before {
    content: "\f574";
    }
        .fi-sr-whistle:before {
    content: "\f575";
    }
        .fi-sr-wifi-alt:before {
    content: "\f576";
    }
        .fi-sr-wind-warning:before {
    content: "\f577";
    }
        .fi-sr-wind:before {
    content: "\f578";
    }
        .fi-sr-windsock:before {
    content: "\f579";
    }
        .fi-sr-woman-head:before {
    content: "\f57a";
    }
        .fi-sr-world:before {
    content: "\f57b";
    }
        .fi-sr-wrench-simple:before {
    content: "\f57c";
    }
        .fi-sr-x:before {
    content: "\f57d";
    }
        .fi-sr-y:before {
    content: "\f57e";
    }
        .fi-sr-yen:before {
    content: "\f57f";
    }
        .fi-sr-z:before {
    content: "\f580";
    }
        .fi-sr-zoom-in:before {
    content: "\f581";
    }
        .fi-sr-zoom-out:before {
    content: "\f582";
    }

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* main */
  .main_block{
    @apply w-full flex flex-col justify-center items-center;
  }
  .footer{
    @apply w-full flex flex-col items-center justify-center bg-black-7; 
  }
  .footer_block{
    @apply flex flex-col sm:flex-row max-w-300 w-full px-6 xl:px-0 my-8 xl:my-[4.25rem] space-y-8 sm:space-y-0 items-start justify-between text-white-3;
  }
  .footer_nav{
    @apply flex flex-col space-y-5;
  }
  .footer_nav_div{
    @apply text-white hover:text-red-1 text-sm sm:text-lg leading-none;
  }
  .footer_nav_ul{
    @apply flex flex-col space-y-3;
  }
  .footer_nav_ul_li{
    @apply text-sm sm:text-base text-white-3 hover:text-red-1;
  }
  /* Блок Header */
  .header_block{
    @apply z-20 fixed flex w-full h-15 sm:h-28 justify-center items-center bg-white border-solid border-0 border-b-2 border-white-1;
  }
  .header_div{
    @apply flex flex-row space-y-2 sm:space-y-0 max-w-300 w-full justify-between sm:items-center px-6 xl:px-0;
  }
  .header_logo{
    @apply h-10 sm:h-16 lg:h-18 mr-8 lg:mr-28;
  }
  .header_block_nav{
    @apply hidden md:flex flex-row w-full justify-between items-center;
  }
  .header_ul{
    @apply flex flex-col space-y-1 md:space-y-0 md:flex-row md:space-x-8 lg:space-x-18 items-center;
  }
  .header_ul_small{
    @apply flex flex-col space-y-1 lg:space-y-0 lg:flex-row lg:space-x-5 items-center;
  }
  .header_ul_li{
    @apply text-sm sm:text-lg text-black-1 hover:text-red-1; 
  }
  /* Блок LandingSection1 */
  .lan1_img{
    @apply absolute sm:top-40 xl:top-[13.37rem] right-0 h-[12rem] w-[12rem] sm:h-[24.9rem] sm:w-[26.4rem] xl:h-[41.5rem] xl:w-[44rem] object-cover object-left;
  }
  .lan1_block{
    @apply z-10 flex flex-col max-w-300 w-full px-6 xl:px-0 mt-[10rem] lg:mt-[16rem] xl:mt-[18rem];
  }
  .lan1_h1{
    @apply max-w-156 text-22px sm:text-46px text-black-1 text-center sm:text-left font-black leading-relaxed sm:leading-snug;
  }
  .lan1_p{
    @apply max-w-156 mt-8 sm:mt-12 px-2 sm:px-0 text-sm sm:text-22px text-black-4 text-center sm:text-left leading-relaxed;
  }
  .lan1_button{
    @apply sm:max-w-72 h-14 sm:h-16 mt-10 sm:mt-18 btn-color-filled;
  }
  /* Блок LandingSection2 */
  .lan2_nav{
    @apply flex flex-col max-w-300 w-full px-6 pt-4 lg:pt-32 xl:px-0;
  }
  .lan2_h2{
    @apply max-w-156 text-22px sm:text-46px text-black-1 text-center sm:text-left font-black leading-snug;
  }
  .lan2_h2_fw{
    @apply text-22px sm:text-46px text-black-1 text-center sm:text-left font-black leading-snug;
  }
  .lan2_p{
    @apply max-w-192 mt-8 sm:mt-10 text-sm sm:text-xl text-black-4 text-center sm:text-left leading-relaxed;
  }
  .lan2_p_fw{
    @apply mt-8 sm:mt-10 text-sm sm:text-xl text-black-4 text-center sm:text-left leading-relaxed;
  }
  .lan2_ul{
    @apply grid grid-cols-1 md:grid-cols-2 gap-5 mt-10 lg:mt-20 xl:mt-25;
  }
  /* Блок LandingSection2 GridElement */
  .lan2_li{
    @apply relative w-full xl:w-[36.875rem] px-5 sm:px-8 xl:px-12 pt-5 sm:pt-9 xl:pt-14 pb-8 sm:pb-12 lg:pb-18 xl:pb-26 bg-white-4 sm:hover:-translate-y-2 sm:hover:translate-x-2 duration-300;
  }
  .lan2_li_h3{
    @apply text-lg sm:text-2xl xl:text-3xl text-black-1 font-black leading-snug uppercase;
  }
  .lan2_li_text{
    @apply mt-4 sm:mt-6 text-sm sm:text-lg xl:text-xl text-black-4 leading-6 sm:leading-8;
  }
  .lan2_li_block{
    @apply flex flex-row mt-8 xl:mt-12 justify-between;
  }
  .lan2_li_block_img{
    @apply absolute bottom-0 sm:-bottom-[2px] right-0 origin-bottom-right scale-50 lg:scale-75 xl:scale-100;
  }
  .lan2_li_price{
    @apply py-1 xl:py-3 pr-1 text-lg xl:text-22px text-black-1 font-bold price-bg;
  }
  .lan2_li_detail{
    @apply mt-4 xl:mt-8 text-sm sm:text-lg xl:text-22px text-black-1 hover:text-red-1 underline underline-offset-[4px] lg:underline-offset-[6px] decoration-1;
  }
  /* Блок LandingSection2 GridElementModal */
  .lan2_li_modal{
    @apply relative w-full xl:w-[36.875rem] px-5 sm:px-8 xl:px-12 pt-5 sm:pt-9 xl:pt-12 pb-5 sm:pb-6 xl:pb-8 bg-black-2;
  }
  .lan2_li_modal_h2{
    @apply max-w-[15rem] sm:max-w-sm text-lg sm:text-2xl xl:text-3xl text-white font-black sm:!leading-normal uppercase;
  }
  .lan2_li_modal_text{
    @apply mt-4 sm:mt-6 text-sm sm:text-lg xl:text-xl text-black-5 leading-relaxed;
  }
  .lan2_li_modal_button{
    @apply z-10 w-full sm:w-fit text-sm sm:text-22px h-14 sm:h-16 mt-8 xl:mt-12 px-4 btn-color-filled;
  }
  .lan2_li_modal_img{
    @apply absolute bottom-0 sm:-bottom-[2px] right-0 origin-bottom-right scale-50 lg:scale-75 xl:scale-100;
  }
  /* Блок LandingSection3 */
  .lan3{
   @apply flex flex-col max-w-300 w-full px-6 xl:px-0 pt-6 lg:pt-32;
  }
  .lan3_h2{
    @apply max-w-156 text-22px sm:text-46px text-black-1 text-center sm:text-left font-black leading-snug;
  }
  .lan3_h2_fw{
    @apply text-22px sm:text-46px text-black-1 text-center sm:text-left font-black leading-snug;
  }
  .lan3_p{
   @apply max-w-192 px-6 sm:px-0 mt-6 sm:mt-10 text-sm sm:text-xl text-black-4 text-center sm:text-left leading-relaxed;
  }
  .lan3_p_fw{
    @apply px-6 sm:px-0 mt-6 sm:mt-10 text-sm sm:text-xl text-black-4 text-center sm:text-left leading-relaxed;
  }
  .lan3_grid{
   @apply grid grid-cols-1 md:grid-cols-2 gap-5 mt-10 lg:mt-20 xl:mt-25;
  }
  .lan3_el{
   @apply relative w-full xl:w-[36.875rem] px-5 sm:px-8 xl:px-12 pt-5 sm:pt-9 xl:pt-16 pb-6 sm:pb-8 lg:pb-10 xl:pb-14 bg-white-4 sm:hover:-translate-y-2 sm:hover:translate-x-2 duration-300;
  }
  .lan3_el_h3{
   @apply text-lg sm:text-2xl xl:text-3xl text-black-1 font-black leading-normal uppercase;
  }
  .lan3_el_p{
   @apply mt-5 sm:mt-8 text-sm sm:text-xl text-black-4 leading-6 sm:leading-8;
  }
  .lan3_el_block{
   @apply flex flex-row mb-3 sm:mb-0 mt-8 xl:mt-12 justify-between;
  }
  .lan3_el_block2{
    @apply flex flex-col h-28 space-y-2.5 sm:space-y-5;
  }
  .lan3_el_block2_price{
   @apply w-fit py-1 xl:py-3 pr-1 text-lg xl:text-22px text-black-1 font-bold price-bg;
  }
  .lan3_el_block2_p{
    @apply w-fit py-1 xl:py-3 pr-1 text-lg xl:text-22px text-black-1 font-bold;
  }
  /* Блок LandingSection4 */
  .lan4{
   @apply relative flex flex-col w-full items-center justify-center bg-black-2;
  }
  .lan4_h2{
    @apply  py-10 px-20 sm:px-6 xl:px-0 text-lg sm:text-46px text-white text-center font-black leading-relaxed uppercase sm:normal-case;
  }
  .lan4_block1{
    @apply flex w-full py-11 xl:py-22 justify-center bg-black-3;
  }
  .lan4_block2{
    @apply px-6 xl:px-0 flex flex-row max-w-300 w-full justify-between;
  }
  .lan4_block_list{
    @apply flex flex-col space-y-5 xl:space-y-8;
  }
  .lan4_block_list_el{
    @apply  flex flex-row space-x-5 items-center;
  }
  .lan4_block_list_el_h3{
    @apply text-sm sm:text-base lg:text-lg xl:text-22px text-white-3 font-normal sm:font-bold leading-relaxed;
  }
  .lan4_block_q{
    @apply py-8 sm:py-10 px-12 sm:px-6 xl:px-0 text-sm sm:text-lg xl:text-22px text-white-3 text-center leading-relaxed;
  }
  .lan4_block_button_div{
    @apply flex w-full px-6 xl:px-0 pb-10 sm:pb-0 items-center justify-center;
  }
  .lan4_block_button{
    @apply  z-10 w-full sm:w-fit h-14 sm:h-16 px-4 btn-color-filled;
  }
  .lan4_block_accept{
    @apply z-10 hidden sm:flex px-6 xl:px-0 pt-8 pb-10 text-xs text-black-6;
  }

  .lan7{
    @apply flex flex-col w-full py-6 sm:py-12 items-center justify-center bg-black-3;
  }
  .lan7_h2{
    @apply hidden sm:flex text-lg xl:text-22px text-black-6 font-normal leading-normal;
  }
  .lan7_block{
    @apply mt-2 sm:mt-8 mb-4 flex flex-row;
  }
  .lan7_ul{
    @apply flex flex-wrap gap-4 items-center justify-center;
  }
  .lan7_li{
    @apply flex flex-col h-20 lg:h-24 laptop-xl:h-28 w-30 laptop-xl:w-34 items-center justify-center border-solid border border-black-6;
  }
  .lan7_li_block{
    @apply flex h-6 lg:h-10 laptop-xl:h-12 my-3;
  }
  .lan7_li_p{
    @apply pb-1 text-sm lg:text-base laptop-xl:text-xl text-black-6;
  }
}
/*@layer components {*/
/*  .row-center {*/
/*    @apply flex flex-row items-center justify-center;*/
/*  }*/
/*  .col-center {*/
/*    @apply flex flex-col items-center justify-center;*/
/*  }*/
/*  .col-h-screen {*/
/*    @apply w-full h-screen flex flex-col;*/
/*  }*/
/*  .activeProf {*/
/*    @apply border-b-4 border-t-0 border-r-0 border-l-0 border-solid border-green6;*/
/*  }*/
/*}*/

@layer base {
  /*body {*/
  /*  @apply bg-black-1*/
  /*}*/
}

:root {
  --font-mono: var(--font-sfpro), -apple-system, BlinkMacSystemFont, 'Open Sans Condensed Regular', 'BebasNeue Regular', 'Segoe UI', Helvetica, ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

body {
  margin: 0;
  line-height: 1;
  overflow:auto;
  /*background-color: #13131A;*/
  /*background-repeat: no-repeat;*/
  /*background-position: right top;*/
  /*background-image: url("/assets/clapps/images/section-1-bg.svg");*/
  font-family: var(--font-sfpro), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans Condensed Regular', Helvetica, serif;
}


* {
  -webkit-tap-highlight-color: transparent;
}

html {
  /*scroll-behavior: smooth!important;*/
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h2{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h3{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h4{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

p{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #ffffff00;
}

ymaps {
  border-radius: 0;
}

.nowrap {
  white-space: nowrap;
}

.btn-color-filled {
  color: #FFFFFF;
  font-size: 22px;
  text-transform: none;
  border-radius: 0;
  border-right: 4px solid #FF5330;
  background: linear-gradient(to right, #303047 50%, #FF5330 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
}

.btn-color-filled-alt {
  color: #FFFFFF;
  font-size: 18px;
  text-transform: none;
  border-radius: 0;
  border-right: 4px solid #FF5330;
  background: linear-gradient(to right, #41415D 50%, #FF5330 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
}

.btn-color-filled:hover,
.btn-color-filled-alt:hover {
  background-position: right;
}

.price-bg {
  background: linear-gradient(to right, transparent 4%, #ABF44B 4%, #ABF44B 95%) left;
}

.yellow-notch-bg {
  background: linear-gradient(to right, transparent 4%, #FFEF5B 4%, #FFEF5B 95%) left;
}

.blue-notch-bg {
  background: linear-gradient(to right, transparent 4%, #5DE3FF 4%, #5DE3FF 95%) left;
}

.swiper-pagination {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  display: flex;
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 1.25rem;
  background: #A9A9B9;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  width: 1.25rem;
  height: 1.25rem;
  /*background: #FF5330;*/
  background: #41415D;
}

.perspective{
  perspective: 1000px;
}

.flip-exit {
  transform: rotateY(0deg);
}

.flip-exit-active {
  transform: rotateY(180deg);
}

.flip-exit-done {
  transform: rotateY(180deg);
}

.flip-enter {
  transform: rotateY(180deg);
}

.flip-enter-active {
  transform: rotateY(0deg);
}

.flip-enter-done {
  transform: rotateY(0deg);
}

.flippable-card {
  position: relative;
  transform-style: preserve-3d;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(.57,.21,.69,1.25);
}

.flippable-card-front{
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.flippable-card-back{
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.cool-btn-left i,
.cool-btn-right i{
  position: absolute;
  display: block;
  inset: -2px;
}

.cool-btn-left i::before {
  content: '';
  position: absolute;
  top:0;
  left: 80%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-left i::after {
  content: '';
  position: absolute;
  bottom:0;
  left: 20%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-right i::before {
  content: '';
  position: absolute;
  top:0;
  left: 20%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-right i::after {
  content: '';
  position: absolute;
  bottom:0;
  left: 80%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-left:hover i::after,
.cool-btn-left:hover i::before,
.cool-btn-right:hover i::after,
.cool-btn-right:hover i::before {
  width: 100%;
  left: 0;
  background: #ff4833;
  height: 2px;
}

.anim-up-50 {
  animation: 0.5s anim-goUp ease-out;
}
.anim-up-75 {
  animation: 0.75s anim-goUp ease-out;
}
.anim-up-100 {
  animation: 1s anim-goUp ease-out;
}
.anim-up-125 {
  animation: 1.25s anim-goUp ease-out;
}
@keyframes anim-goUp {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.cool-btn-hover .cool-hover {
  height: 0;
  opacity: 0;
  font-size: 0;
  transition-duration: 0.5s;
}

.cool-btn-hover:hover .cool-hover {
  height: 4rem;
  opacity: 1;
  font-size: 1.25rem;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@media (max-width: 425px) {
  .btn-color-filled {
    font-size: 14px;
  }
  .swiper-alternate-list:nth-child(odd) {
    background-color: #FFFFFF;
  }
}

@media (min-width: 1024px) {
  /*body {*/
  /*  overflow:hidden;*/
  /*}*/

  ymaps {
    border-radius: 1.5rem;
  }
}

@media (min-width: 1610px) {
  .cool-btn-hover:hover .cool-hover {
    height: 5.5rem;
    opacity: 1;
    font-size: 1.5rem;
  }
}
*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
  /*body {*/
  /*  @apply bg-black-1*/
  /*}*/
.container {
  width: 100%;
}
@media (min-width: 360px) {
  .container {
    max-width: 360px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1371px) {
  .container {
    max-width: 1371px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
@media (min-width: 1610px) {
  .container {
    max-width: 1610px;
  }
}
/* main */
.main_block {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --tw-bg-opacity: 1;
  background-color: rgb(44 44 67 / var(--tw-bg-opacity));
}
.footer_block {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  max-width: 75rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.footer_block > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.footer_block {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(191 191 212 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .footer_block {
    flex-direction: row;
  }
  .footer_block > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}
@media (min-width: 1280px) {
  .footer_block {
    margin-top: 4.25rem;
    margin-bottom: 4.25rem;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.footer_nav {
  display: flex;
  flex-direction: column;
}
.footer_nav > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.footer_nav_div {
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.footer_nav_div:hover {
  --tw-text-opacity: 1;
  color: rgb(255 83 48 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .footer_nav_div {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
.footer_nav_ul {
  display: flex;
  flex-direction: column;
}
.footer_nav_ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.footer_nav_ul_li {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(191 191 212 / var(--tw-text-opacity));
}
.footer_nav_ul_li:hover {
  --tw-text-opacity: 1;
  color: rgb(255 83 48 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .footer_nav_ul_li {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
/* Блок Header */
.header_block {
  position: fixed;
  z-index: 20;
  display: flex;
  height: 3.75rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-width: 0px;
  border-bottom-width: 2px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(251 251 252 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
@media (min-width: 640px) {
  .header_block {
    height: 7rem;
  }
}
.header_div {
  display: flex;
  width: 100%;
  max-width: 75rem;
  flex-direction: row;
  justify-content: space-between;
}
.header_div > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.header_div {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media (min-width: 640px) {
  .header_div {
    align-items: center;
  }
  .header_div > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}
@media (min-width: 1280px) {
  .header_div {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.header_logo {
  margin-right: 2rem;
  height: 2.5rem;
}
@media (min-width: 640px) {
  .header_logo {
    height: 4rem;
  }
}
@media (min-width: 1024px) {
  .header_logo {
    margin-right: 7rem;
    height: 4.5rem;
  }
}
.header_block_nav {
  display: none;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .header_block_nav {
    display: flex;
  }
}
.header_ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header_ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
@media (min-width: 768px) {
  .header_ul {
    flex-direction: row;
  }
  .header_ul > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
}
@media (min-width: 1024px) {
  .header_ul > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4.5rem * var(--tw-space-x-reverse));
    margin-left: calc(4.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
}
.header_ul_small {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header_ul_small > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
@media (min-width: 1024px) {
  .header_ul_small {
    flex-direction: row;
  }
  .header_ul_small > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
}
.header_ul_li {
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
.header_ul_li:hover {
  --tw-text-opacity: 1;
  color: rgb(255 83 48 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .header_ul_li {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
/* Блок LandingSection1 */
.lan1_img {
  position: absolute;
  right: 0px;
  height: 12rem;
  width: 12rem;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left;
     object-position: left;
}
@media (min-width: 640px) {
  .lan1_img {
    top: 10rem;
    height: 24.9rem;
    width: 26.4rem;
  }
}
@media (min-width: 1280px) {
  .lan1_img {
    top: 13.37rem;
    height: 41.5rem;
    width: 44rem;
  }
}
.lan1_block {
  z-index: 10;
  margin-top: 10rem;
  display: flex;
  width: 100%;
  max-width: 75rem;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media (min-width: 1024px) {
  .lan1_block {
    margin-top: 16rem;
  }
}
@media (min-width: 1280px) {
  .lan1_block {
    margin-top: 18rem;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.lan1_h1 {
  max-width: 39rem;
  text-align: center;
  font-size: 1.375rem;
  font-weight: 900;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan1_h1 {
    text-align: left;
    font-size: 2.875rem;
    line-height: 1.375;
  }
}
.lan1_p {
  margin-top: 2rem;
  max-width: 39rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan1_p {
    margin-top: 3rem;
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    font-size: 1.375rem;
  }
}
.lan1_button {
  margin-top: 2.5rem;
  height: 3.5rem;
  color: #FFFFFF;
  font-size: 22px;
  text-transform: none;
  border-radius: 0;
  border-right: 4px solid #FF5330;
  background: linear-gradient(to right, #303047 50%, #FF5330 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
}
.lan1_button:hover {
  background-position: right;
}
@media (max-width: 425px) {
  .lan1_button {
    font-size: 14px;
  }
}
@media (min-width: 640px) {
  .lan1_button {
    margin-top: 4.5rem;
    height: 4rem;
    max-width: 18rem;
  }
}
/* Блок LandingSection2 */
.lan2_nav {
  display: flex;
  width: 100%;
  max-width: 75rem;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
}
@media (min-width: 1024px) {
  .lan2_nav {
    padding-top: 8rem;
  }
}
@media (min-width: 1280px) {
  .lan2_nav {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.lan2_h2 {
  max-width: 39rem;
  text-align: center;
  font-size: 1.375rem;
  font-weight: 900;
  line-height: 1.375;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_h2 {
    text-align: left;
    font-size: 2.875rem;
  }
}
.lan2_h2_fw {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 900;
  line-height: 1.375;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_h2_fw {
    text-align: left;
    font-size: 2.875rem;
  }
}
.lan2_p {
  margin-top: 2rem;
  max-width: 48rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_p {
    margin-top: 2.5rem;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.lan2_p_fw {
  margin-top: 2rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_p_fw {
    margin-top: 2.5rem;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.lan2_ul {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.25rem;
}
@media (min-width: 768px) {
  .lan2_ul {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .lan2_ul {
    margin-top: 5rem;
  }
}
@media (min-width: 1280px) {
  .lan2_ul {
    margin-top: 6.25rem;
  }
}
/* Блок LandingSection2 GridElement */
.lan2_li {
  position: relative;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 249 / var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 2rem;
  transition-duration: 300ms;
}
@media (min-width: 640px) {
  .lan2_li {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2.25rem;
    padding-bottom: 3rem;
  }
  .lan2_li:hover {
    --tw-translate-y: -0.5rem;
    --tw-translate-x: 0.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
@media (min-width: 1024px) {
  .lan2_li {
    padding-bottom: 4.5rem;
  }
}
@media (min-width: 1280px) {
  .lan2_li {
    width: 36.875rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3.5rem;
    padding-bottom: 6.5rem;
  }
}
.lan2_li_h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.375;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_li_h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
@media (min-width: 1280px) {
  .lan2_li_h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
.lan2_li_text {
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_li_text {
    margin-top: 1.5rem;
    font-size: 1.125rem;
    line-height: 2rem;
  }
}
@media (min-width: 1280px) {
  .lan2_li_text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.lan2_li_block {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 1280px) {
  .lan2_li_block {
    margin-top: 3rem;
  }
}
.lan2_li_block_img {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform-origin: bottom right;
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 640px) {
  .lan2_li_block_img {
    bottom: -2px;
  }
}
@media (min-width: 1024px) {
  .lan2_li_block_img {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
@media (min-width: 1280px) {
  .lan2_li_block_img {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
.lan2_li_price {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
  background: linear-gradient(to right, transparent 4%, #ABF44B 4%, #ABF44B 95%) left;
}
@media (min-width: 1280px) {
  .lan2_li_price {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.375rem;
  }
}
.lan2_li_detail {
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}
.lan2_li_detail:hover {
  --tw-text-opacity: 1;
  color: rgb(255 83 48 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_li_detail {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
@media (min-width: 1024px) {
  .lan2_li_detail {
    text-underline-offset: 6px;
  }
}
@media (min-width: 1280px) {
  .lan2_li_detail {
    margin-top: 2rem;
    font-size: 1.375rem;
  }
}
/* Блок LandingSection2 GridElementModal */
.lan2_li_modal {
  position: relative;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(65 65 93 / var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
@media (min-width: 640px) {
  .lan2_li_modal {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2.25rem;
    padding-bottom: 1.5rem;
  }
}
@media (min-width: 1280px) {
  .lan2_li_modal {
    width: 36.875rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}
.lan2_li_modal_h2 {
  max-width: 15rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 900;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_li_modal_h2 {
    max-width: 24rem;
    font-size: 1.5rem;
    line-height: 2rem;
    line-height: 1.5 !important;
  }
}
@media (min-width: 1280px) {
  .lan2_li_modal_h2 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
.lan2_li_modal_text {
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(122 122 151 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan2_li_modal_text {
    margin-top: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
@media (min-width: 1280px) {
  .lan2_li_modal_text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.lan2_li_modal_button {
  z-index: 10;
  margin-top: 2rem;
  height: 3.5rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #FFFFFF;
  font-size: 22px;
  text-transform: none;
  border-radius: 0;
  border-right: 4px solid #FF5330;
  background: linear-gradient(to right, #303047 50%, #FF5330 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
}
.lan2_li_modal_button:hover {
  background-position: right;
}
@media (max-width: 425px) {
  .lan2_li_modal_button {
    font-size: 14px;
  }
}
@media (min-width: 640px) {
  .lan2_li_modal_button {
    height: 4rem;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 1.375rem;
  }
}
@media (min-width: 1280px) {
  .lan2_li_modal_button {
    margin-top: 3rem;
  }
}
.lan2_li_modal_img {
  position: absolute;
  bottom: 0px;
  right: 0px;
  transform-origin: bottom right;
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 640px) {
  .lan2_li_modal_img {
    bottom: -2px;
  }
}
@media (min-width: 1024px) {
  .lan2_li_modal_img {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
@media (min-width: 1280px) {
  .lan2_li_modal_img {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
/* Блок LandingSection3 */
.lan3 {
  display: flex;
  width: 100%;
  max-width: 75rem;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}
@media (min-width: 1024px) {
  .lan3 {
    padding-top: 8rem;
  }
}
@media (min-width: 1280px) {
  .lan3 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.lan3_h2 {
  max-width: 39rem;
  text-align: center;
  font-size: 1.375rem;
  font-weight: 900;
  line-height: 1.375;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan3_h2 {
    text-align: left;
    font-size: 2.875rem;
  }
}
.lan3_h2_fw {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 900;
  line-height: 1.375;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan3_h2_fw {
    text-align: left;
    font-size: 2.875rem;
  }
}
.lan3_p {
  margin-top: 1.5rem;
  max-width: 48rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan3_p {
    margin-top: 2.5rem;
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.lan3_p_fw {
  margin-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan3_p_fw {
    margin-top: 2.5rem;
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.lan3_grid {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.25rem;
}
@media (min-width: 768px) {
  .lan3_grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 1024px) {
  .lan3_grid {
    margin-top: 5rem;
  }
}
@media (min-width: 1280px) {
  .lan3_grid {
    margin-top: 6.25rem;
  }
}
.lan3_el {
  position: relative;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 249 / var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  padding-bottom: 1.5rem;
  transition-duration: 300ms;
}
@media (min-width: 640px) {
  .lan3_el {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2.25rem;
    padding-bottom: 2rem;
  }
  .lan3_el:hover {
    --tw-translate-y: -0.5rem;
    --tw-translate-x: 0.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}
@media (min-width: 1024px) {
  .lan3_el {
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .lan3_el {
    width: 36.875rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 4rem;
    padding-bottom: 3.5rem;
  }
}
.lan3_el_h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan3_el_h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
@media (min-width: 1280px) {
  .lan3_el_h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}
.lan3_el_p {
  margin-top: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan3_el_p {
    margin-top: 2rem;
    font-size: 1.25rem;
    line-height: 2rem;
  }
}
.lan3_el_block {
  margin-bottom: 0.75rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (min-width: 640px) {
  .lan3_el_block {
    margin-bottom: 0px;
  }
}
@media (min-width: 1280px) {
  .lan3_el_block {
    margin-top: 3rem;
  }
}
.lan3_el_block2 {
  display: flex;
  height: 7rem;
  flex-direction: column;
}
.lan3_el_block2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}
@media (min-width: 640px) {
  .lan3_el_block2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
}
.lan3_el_block2_price {
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
  background: linear-gradient(to right, transparent 4%, #ABF44B 4%, #ABF44B 95%) left;
}
@media (min-width: 1280px) {
  .lan3_el_block2_price {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.375rem;
  }
}
.lan3_el_block2_p {
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-right: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
@media (min-width: 1280px) {
  .lan3_el_block2_p {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1.375rem;
  }
}
/* Блок LandingSection4 */
.lan4 {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --tw-bg-opacity: 1;
  background-color: rgb(65 65 93 / var(--tw-bg-opacity));
}
.lan4_h2 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan4_h2 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 2.875rem;
    text-transform: none;
  }
}
@media (min-width: 1280px) {
  .lan4_h2 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.lan4_block1 {
  display: flex;
  width: 100%;
  justify-content: center;
  --tw-bg-opacity: 1;
  background-color: rgb(48 48 71 / var(--tw-bg-opacity));
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}
@media (min-width: 1280px) {
  .lan4_block1 {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
  }
}
.lan4_block2 {
  display: flex;
  width: 100%;
  max-width: 75rem;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
@media (min-width: 1280px) {
  .lan4_block2 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.lan4_block_list {
  display: flex;
  flex-direction: column;
}
.lan4_block_list > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
@media (min-width: 1280px) {
  .lan4_block_list > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
}
.lan4_block_list_el {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lan4_block_list_el > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.lan4_block_list_el_h3 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(191 191 212 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan4_block_list_el_h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
  }
}
@media (min-width: 1024px) {
  .lan4_block_list_el_h3 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
@media (min-width: 1280px) {
  .lan4_block_list_el_h3 {
    font-size: 1.375rem;
  }
}
.lan4_block_q {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  line-height: 1.625;
  --tw-text-opacity: 1;
  color: rgb(191 191 212 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan4_block_q {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
@media (min-width: 1280px) {
  .lan4_block_q {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1.375rem;
  }
}
.lan4_block_button_div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 2.5rem;
}
@media (min-width: 640px) {
  .lan4_block_button_div {
    padding-bottom: 0px;
  }
}
@media (min-width: 1280px) {
  .lan4_block_button_div {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.lan4_block_button {
  z-index: 10;
  height: 3.5rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #FFFFFF;
  font-size: 22px;
  text-transform: none;
  border-radius: 0;
  border-right: 4px solid #FF5330;
  background: linear-gradient(to right, #303047 50%, #FF5330 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
}
.lan4_block_button:hover {
  background-position: right;
}
@media (max-width: 425px) {
  .lan4_block_button {
    font-size: 14px;
  }
}
@media (min-width: 640px) {
  .lan4_block_button {
    height: 4rem;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.lan4_block_accept {
  z-index: 10;
  display: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(136 136 158 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan4_block_accept {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .lan4_block_accept {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.lan7 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  --tw-bg-opacity: 1;
  background-color: rgb(48 48 71 / var(--tw-bg-opacity));
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 640px) {
  .lan7 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.lan7_h2 {
  display: none;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  line-height: 1.5;
  --tw-text-opacity: 1;
  color: rgb(136 136 158 / var(--tw-text-opacity));
}
@media (min-width: 640px) {
  .lan7_h2 {
    display: flex;
  }
}
@media (min-width: 1280px) {
  .lan7_h2 {
    font-size: 1.375rem;
  }
}
.lan7_block {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
}
@media (min-width: 640px) {
  .lan7_block {
    margin-top: 2rem;
  }
}
.lan7_ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.lan7_li {
  display: flex;
  height: 5rem;
  width: 7.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(136 136 158 / var(--tw-border-opacity));
}
@media (min-width: 1024px) {
  .lan7_li {
    height: 6rem;
  }
}
@media (min-width: 1371px) {
  .lan7_li {
    height: 7rem;
    width: 8.5rem;
  }
}
.lan7_li_block {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  display: flex;
  height: 1.5rem;
}
@media (min-width: 1024px) {
  .lan7_li_block {
    height: 2.5rem;
  }
}
@media (min-width: 1371px) {
  .lan7_li_block {
    height: 3rem;
  }
}
.lan7_li_p {
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(136 136 158 / var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .lan7_li_p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (min-width: 1371px) {
  .lan7_li_p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.-bottom-5 {
  bottom: -1.25rem;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-\[-3px\] {
  bottom: -3px;
}
.left-5 {
  left: 1.25rem;
}
.right-0 {
  right: 0px;
}
.right-3\.5 {
  right: 0.875rem;
}
.right-6 {
  right: 1.5rem;
}
.top-16 {
  top: 4rem;
}
.top-3\.5 {
  top: 0.875rem;
}
.z-10 {
  z-index: 10;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.row-span-1 {
  grid-row: span 1 / span 1;
}
.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.-mt-2 {
  margin-top: -0.5rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.ml-0\.5 {
  margin-left: 0.125rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.mt-0 {
  margin-top: 0px;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-1\.5 {
  margin-top: 0.375rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-15 {
  margin-top: 3.75rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-22 {
  margin-top: 5.5rem;
}
.mt-25 {
  margin-top: 6.25rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-18 {
  height: 4.5rem;
}
.h-36 {
  height: 9rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-72 {
  height: 18rem;
}
.h-8 {
  height: 2rem;
}
.h-84 {
  height: 21rem;
}
.h-85vh {
  height: 85vh;
}
.h-\[15\.25rem\] {
  height: 15.25rem;
}
.h-full {
  height: 100%;
}
.min-h-\[4\.125rem\] {
  min-height: 4.125rem;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-44 {
  width: 11rem;
}
.w-6 {
  width: 1.5rem;
}
.w-72 {
  width: 18rem;
}
.w-8 {
  width: 2rem;
}
.w-96 {
  width: 24rem;
}
.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.w-full {
  width: 100%;
}
.max-w-192 {
  max-width: 48rem;
}
.max-w-300 {
  max-width: 75rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-72 {
  max-width: 18rem;
}
.max-w-lg {
  max-width: 32rem;
}
.origin-center {
  transform-origin: center;
}
.origin-left {
  transform-origin: left;
}
.origin-right {
  transform-origin: right;
}
.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[0\.70\] {
  --tw-scale-x: 0.70;
  --tw-scale-y: 0.70;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.resize {
  resize: both;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-2\.5 {
  gap: 0.625rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-y-6 {
  row-gap: 1.5rem;
}
.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}
.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.text-nowrap {
  text-wrap: nowrap;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.border-y-0 {
  border-top-width: 0px;
  border-bottom-width: 0px;
}
.border-l-\[6px\] {
  border-left-width: 6px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-solid {
  border-style: solid;
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
.border-black-1 {
  --tw-border-opacity: 1;
  border-color: rgb(49 49 65 / var(--tw-border-opacity));
}
.border-green-2 {
  --tw-border-opacity: 1;
  border-color: rgb(138 213 40 / var(--tw-border-opacity));
}
.border-red-1 {
  --tw-border-opacity: 1;
  border-color: rgb(255 83 48 / var(--tw-border-opacity));
}
.border-white-1 {
  --tw-border-opacity: 1;
  border-color: rgb(251 251 252 / var(--tw-border-opacity));
}
.bg-black-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(65 65 93 / var(--tw-bg-opacity));
}
.bg-black-3 {
  --tw-bg-opacity: 1;
  background-color: rgb(48 48 71 / var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-white-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 251 252 / var(--tw-bg-opacity));
}
.bg-white-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}
.bg-\[url\(\'\/assets\/clapps\/images\/block-2-hover\.svg\'\)\] {
  background-image: url('/assets/clapps/images/block-2-hover.svg');
}
.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-green-3 {
  --tw-gradient-from: #C7FE7F var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(199 254 127 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-red-2 {
  --tw-gradient-from: #FF7E64 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 126 100 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-red-3 {
  --tw-gradient-to: #E97394 var(--tw-gradient-to-position);
}
.to-yellow-1 {
  --tw-gradient-to: #F3F876 var(--tw-gradient-to-position);
}
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
.bg-top {
  background-position: top;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.p-5 {
  padding: 1.25rem;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.pb-15 {
  padding-bottom: 3.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pt-0\.5 {
  padding-top: 0.125rem;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.text-center {
  text-align: center;
}
.font-bebas {
  font-family: "Bebas Neue", "Bebas Neue Bold";
}
.font-openS {
  font-family: "Open Sans Condensed", "Open Sans Condensed Regular";
}
.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.text-22px {
  font-size: 1.375rem;
}
.text-26px {
  font-size: 1.625rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-8px {
  font-size: 0.5rem;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.font-black {
  font-weight: 900;
}
.font-bold {
  font-weight: 700;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-loose {
  line-height: 2;
}
.leading-none {
  line-height: 1;
}
.leading-normal {
  line-height: 1.5;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-snug {
  line-height: 1.375;
}
.text-black-1 {
  --tw-text-opacity: 1;
  color: rgb(49 49 65 / var(--tw-text-opacity));
}
.text-black-2 {
  --tw-text-opacity: 1;
  color: rgb(65 65 93 / var(--tw-text-opacity));
}
.text-black-4 {
  --tw-text-opacity: 1;
  color: rgb(93 93 117 / var(--tw-text-opacity));
}
.text-black-6 {
  --tw-text-opacity: 1;
  color: rgb(136 136 158 / var(--tw-text-opacity));
}
.text-gray-1 {
  --tw-text-opacity: 1;
  color: rgb(86 86 110 / var(--tw-text-opacity));
}
.text-red-1 {
  --tw-text-opacity: 1;
  color: rgb(255 83 48 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-white-3 {
  --tw-text-opacity: 1;
  color: rgb(191 191 212 / var(--tw-text-opacity));
}
.underline {
  text-decoration-line: underline;
}
.decoration-1 {
  text-decoration-thickness: 1px;
}
.decoration-2 {
  text-decoration-thickness: 2px;
}
.underline-offset-4 {
  text-underline-offset: 4px;
}
.underline-offset-\[4px\] {
  text-underline-offset: 4px;
}
.outline-0 {
  outline-width: 0px;
}
.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.duration-300 {
  transition-duration: 300ms;
}
.duration-500 {
  transition-duration: 500ms;
}
/*@layer components {*/
/*  .row-center {*/
/*    @apply flex flex-row items-center justify-center;*/
/*  }*/
/*  .col-center {*/
/*    @apply flex flex-col items-center justify-center;*/
/*  }*/
/*  .col-h-screen {*/
/*    @apply w-full h-screen flex flex-col;*/
/*  }*/
/*  .activeProf {*/
/*    @apply border-b-4 border-t-0 border-r-0 border-l-0 border-solid border-green6;*/
/*  }*/
/*}*/

:root {
  --font-mono: var(--font-sfpro), -apple-system, BlinkMacSystemFont, 'Open Sans Condensed Regular', 'BebasNeue Regular', 'Segoe UI', Helvetica, ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

body {
  margin: 0;
  line-height: 1;
  overflow:auto;
  /*background-color: #13131A;*/
  /*background-repeat: no-repeat;*/
  /*background-position: right top;*/
  /*background-image: url("/assets/clapps/images/section-1-bg.svg");*/
  font-family: var(--font-sfpro), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans Condensed Regular', Helvetica, serif;
}


* {
  -webkit-tap-highlight-color: transparent;
}

html {
  /*scroll-behavior: smooth!important;*/
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h2{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h3{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h4{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

p{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #ffffff00;
}

ymaps {
  border-radius: 0;
}

.nowrap {
  white-space: nowrap;
}

.btn-color-filled {
  color: #FFFFFF;
  font-size: 22px;
  text-transform: none;
  border-radius: 0;
  border-right: 4px solid #FF5330;
  background: linear-gradient(to right, #303047 50%, #FF5330 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
}

.btn-color-filled-alt {
  color: #FFFFFF;
  font-size: 18px;
  text-transform: none;
  border-radius: 0;
  border-right: 4px solid #FF5330;
  background: linear-gradient(to right, #41415D 50%, #FF5330 50%) left;
  background-size: 200%;
  transition: .5s ease-out;
}

.btn-color-filled:hover,
.btn-color-filled-alt:hover {
  background-position: right;
}

.price-bg {
  background: linear-gradient(to right, transparent 4%, #ABF44B 4%, #ABF44B 95%) left;
}

.yellow-notch-bg {
  background: linear-gradient(to right, transparent 4%, #FFEF5B 4%, #FFEF5B 95%) left;
}

.blue-notch-bg {
  background: linear-gradient(to right, transparent 4%, #5DE3FF 4%, #5DE3FF 95%) left;
}

.swiper-pagination {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  display: flex;
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 1.25rem;
  background: #A9A9B9;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  width: 1.25rem;
  height: 1.25rem;
  /*background: #FF5330;*/
  background: #41415D;
}

.perspective{
  perspective: 1000px;
}

.flip-exit {
  transform: rotateY(0deg);
}

.flip-exit-active {
  transform: rotateY(180deg);
}

.flip-exit-done {
  transform: rotateY(180deg);
}

.flip-enter {
  transform: rotateY(180deg);
}

.flip-enter-active {
  transform: rotateY(0deg);
}

.flip-enter-done {
  transform: rotateY(0deg);
}

.flippable-card {
  position: relative;
  transform-style: preserve-3d;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(.57,.21,.69,1.25);
}

.flippable-card-front{
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.flippable-card-back{
  position: absolute;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.cool-btn-left i,
.cool-btn-right i{
  position: absolute;
  display: block;
  inset: -2px;
}

.cool-btn-left i::before {
  content: '';
  position: absolute;
  top:0;
  left: 80%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-left i::after {
  content: '';
  position: absolute;
  bottom:0;
  left: 20%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-right i::before {
  content: '';
  position: absolute;
  top:0;
  left: 20%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-right i::after {
  content: '';
  position: absolute;
  bottom:0;
  left: 80%;
  width: 10px;
  height: 0;
  background: #E7E7E7;
  transition-duration: 0.75s;
}

.cool-btn-left:hover i::after,
.cool-btn-left:hover i::before,
.cool-btn-right:hover i::after,
.cool-btn-right:hover i::before {
  width: 100%;
  left: 0;
  background: #ff4833;
  height: 2px;
}

.anim-up-50 {
  animation: 0.5s anim-goUp ease-out;
}
.anim-up-75 {
  animation: 0.75s anim-goUp ease-out;
}
.anim-up-100 {
  animation: 1s anim-goUp ease-out;
}
.anim-up-125 {
  animation: 1.25s anim-goUp ease-out;
}
@keyframes anim-goUp {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.cool-btn-hover .cool-hover {
  height: 0;
  opacity: 0;
  font-size: 0;
  transition-duration: 0.5s;
}

.cool-btn-hover:hover .cool-hover {
  height: 4rem;
  opacity: 1;
  font-size: 1.25rem;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@media (max-width: 425px) {
  .btn-color-filled {
    font-size: 14px;
  }
  .swiper-alternate-list:nth-child(odd) {
    background-color: #FFFFFF;
  }
}

@media (min-width: 1024px) {
  /*body {*/
  /*  overflow:hidden;*/
  /*}*/

  ymaps {
    border-radius: 1.5rem;
  }
}

@media (min-width: 1610px) {
  .cool-btn-hover:hover .cool-hover {
    height: 5.5rem;
    opacity: 1;
    font-size: 1.5rem;
  }
}

.hover\:bg-red-1:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 83 48 / var(--tw-bg-opacity));
}

.hover\:text-red-1:hover {
  --tw-text-opacity: 1;
  color: rgb(255 83 48 / var(--tw-text-opacity));
}

.hover\:brightness-90:hover {
  --tw-brightness: brightness(.9);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 640px) {
  .sm\:bottom-6 {
    bottom: 1.5rem;
  }
  .sm\:left-0 {
    left: 0px;
  }
  .sm\:top-24 {
    top: 6rem;
  }
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .sm\:mb-0 {
    margin-bottom: 0px;
  }
  .sm\:mb-12 {
    margin-bottom: 3rem;
  }
  .sm\:mb-32 {
    margin-bottom: 8rem;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem;
  }
  .sm\:mt-10 {
    margin-top: 2.5rem;
  }
  .sm\:mt-12 {
    margin-top: 3rem;
  }
  .sm\:mt-14 {
    margin-top: 3.5rem;
  }
  .sm\:mt-16 {
    margin-top: 4rem;
  }
  .sm\:mt-18 {
    margin-top: 4.5rem;
  }
  .sm\:mt-20 {
    margin-top: 5rem;
  }
  .sm\:mt-24 {
    margin-top: 6rem;
  }
  .sm\:mt-28 {
    margin-top: 7rem;
  }
  .sm\:mt-32 {
    margin-top: 8rem;
  }
  .sm\:mt-36 {
    margin-top: 9rem;
  }
  .sm\:mt-8 {
    margin-top: 2rem;
  }
  .sm\:mt-9 {
    margin-top: 2.25rem;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:h-16 {
    height: 4rem;
  }
  .sm\:min-h-fit {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  .sm\:max-w-72 {
    max-width: 18rem;
  }
  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:flex-col {
    flex-direction: column;
  }
  .sm\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .sm\:border-l-\[10px\] {
    border-left-width: 10px;
  }
  .sm\:p-12 {
    padding: 3rem;
  }
  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .sm\:pb-0 {
    padding-bottom: 0px;
  }
  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .sm\:pb-20 {
    padding-bottom: 5rem;
  }
  .sm\:pl-0 {
    padding-left: 0px;
  }
  .sm\:pl-6 {
    padding-left: 1.5rem;
  }
  .sm\:pr-0 {
    padding-right: 0px;
  }
  .sm\:pt-0 {
    padding-top: 0px;
  }
  .sm\:pt-10 {
    padding-top: 2.5rem;
  }
  .sm\:pt-6 {
    padding-top: 1.5rem;
  }
  .sm\:pt-8 {
    padding-top: 2rem;
  }
  .sm\:text-22px {
    font-size: 1.375rem;
  }
  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sm\:text-46px {
    font-size: 2.875rem;
  }
  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .sm\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .sm\:leading-9 {
    line-height: 2.25rem;
  }
  .sm\:leading-relaxed {
    line-height: 1.625;
  }
  .sm\:leading-snug {
    line-height: 1.375;
  }
  .sm\:text-black-1 {
    --tw-text-opacity: 1;
    color: rgb(49 49 65 / var(--tw-text-opacity));
  }
  .sm\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:hover\:scale-\[1\.025\]:hover {
    --tw-scale-x: 1.025;
    --tw-scale-y: 1.025;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:hover\:text-red-1:hover {
    --tw-text-opacity: 1;
    color: rgb(255 83 48 / var(--tw-text-opacity));
  }
}

@media (min-width: 768px) {
  .md\:mt-32 {
    margin-top: 8rem;
  }
  .md\:hidden {
    display: none;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:mb-24 {
    margin-bottom: 6rem;
  }
  .lg\:mt-0 {
    margin-top: 0px;
  }
  .lg\:mt-24 {
    margin-top: 6rem;
  }
  .lg\:mt-40 {
    margin-top: 10rem;
  }
  .lg\:mt-8 {
    margin-top: 2rem;
  }
  .lg\:h-120 {
    height: 30rem;
  }
  .lg\:h-48 {
    height: 12rem;
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .lg\:pb-20 {
    padding-bottom: 5rem;
  }
  .lg\:pt-20 {
    padding-top: 5rem;
  }
  .lg\:pt-28 {
    padding-top: 7rem;
  }
  .lg\:pt-32 {
    padding-top: 8rem;
  }
  .lg\:underline-offset-\[6px\] {
    text-underline-offset: 6px;
  }
  .laptop\:mt-0 {
    margin-top: 0px;
  }
  .laptop\:flex {
    display: flex;
  }
  .laptop\:hidden {
    display: none;
  }
  .laptop\:h-10 {
    height: 2.5rem;
  }
  .laptop\:h-72 {
    height: 18rem;
  }
  .laptop\:w-12 {
    width: 3rem;
  }
  .laptop\:w-44 {
    width: 11rem;
  }
  .laptop\:flex-row {
    flex-direction: row;
  }
  .laptop\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .laptop\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .laptop\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .laptop\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .laptop\:bg-44 {
    background-size: 11rem;
  }
  .laptop\:bg-center {
    background-position: center;
  }
  .laptop\:text-22px {
    font-size: 1.375rem;
  }
  .laptop\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .laptop\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .laptop\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .laptop\:leading-10 {
    line-height: 2.5rem;
  }
  .laptop\:hover\:bg-68:hover {
    background-size: 17rem;
  }
}

@media (min-width: 1280px) {
  .xl\:mb-40 {
    margin-bottom: 10rem;
  }
  .xl\:mt-12 {
    margin-top: 3rem;
  }
  .xl\:mt-7 {
    margin-top: 1.75rem;
  }
  .xl\:mt-8 {
    margin-top: 2rem;
  }
  .xl\:mt-\[10\.75rem\] {
    margin-top: 10.75rem;
  }
  .xl\:mt-\[12\.5rem\] {
    margin-top: 12.5rem;
  }
  .xl\:mt-\[4\.5rem\] {
    margin-top: 4.5rem;
  }
  .xl\:h-10 {
    height: 2.5rem;
  }
  .xl\:h-52 {
    height: 13rem;
  }
  .xl\:h-\[33\.5rem\] {
    height: 33.5rem;
  }
  .xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xl\:pb-0 {
    padding-bottom: 0px;
  }
  .xl\:pt-12 {
    padding-top: 3rem;
  }
  .xl\:text-22px {
    font-size: 1.375rem;
  }
  .xl\:text-26px {
    font-size: 1.625rem;
  }
  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1371px) {
  .laptop-xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .laptop-xl\:pl-0 {
    padding-left: 0px;
  }
}

@media (min-width: 1610px) {
  .desktop\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .desktop\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .desktop\:mt-1 {
    margin-top: 0.25rem;
  }
  .desktop\:mt-4 {
    margin-top: 1rem;
  }
  .desktop\:mt-5 {
    margin-top: 1.25rem;
  }
  .desktop\:h-110 {
    height: 27.5rem;
  }
  .desktop\:h-14 {
    height: 3.5rem;
  }
  .desktop\:w-116 {
    width: 29rem;
  }
  .desktop\:w-68 {
    width: 17rem;
  }
  .desktop\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .desktop\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .desktop\:bg-68 {
    background-size: 17rem;
  }
  .desktop\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .desktop\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .desktop\:pl-8 {
    padding-left: 2rem;
  }
  .desktop\:pr-8 {
    padding-right: 2rem;
  }
  .desktop\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .desktop\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .desktop\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .desktop\:leading-6 {
    line-height: 1.5rem;
  }
  .desktop\:hover\:bg-80:hover {
    background-size: 20rem;
  }
}
@font-face {
font-family: "uicons-bold-rounded";
src: url(/_next/static/media/uicons-bold-rounded.4615f691.eot#iefix) format("embedded-opentype"),
url(/_next/static/media/uicons-bold-rounded.025a6cea.woff2) format("woff2"),
url(/_next/static/media/uicons-bold-rounded.cfe0ef58.woff) format("woff");
}

    i[class^="fi-br-"]:before, i[class*=" fi-br-"]:before, span[class^="fi-br-"]:before, span[class*="fi-br-"]:before {
font-family: uicons-bold-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-br-a:before {
    content: "\f101";
    }
        .fi-br-ad:before {
    content: "\f102";
    }
        .fi-br-add-document:before {
    content: "\f103";
    }
        .fi-br-add-folder:before {
    content: "\f104";
    }
        .fi-br-add:before {
    content: "\f105";
    }
        .fi-br-address-book:before {
    content: "\f106";
    }
        .fi-br-air-conditioner:before {
    content: "\f107";
    }
        .fi-br-air-freshener:before {
    content: "\f108";
    }
        .fi-br-alarm-clock:before {
    content: "\f109";
    }
        .fi-br-alarm-exclamation:before {
    content: "\f10a";
    }
        .fi-br-alarm-plus:before {
    content: "\f10b";
    }
        .fi-br-alarm-snooze:before {
    content: "\f10c";
    }
        .fi-br-align-justify:before {
    content: "\f10d";
    }
        .fi-br-align-left:before {
    content: "\f10e";
    }
        .fi-br-ambulance:before {
    content: "\f10f";
    }
        .fi-br-angle-circle-down:before {
    content: "\f110";
    }
        .fi-br-angle-circle-left:before {
    content: "\f111";
    }
        .fi-br-angle-circle-right:before {
    content: "\f112";
    }
        .fi-br-angle-circle-up:before {
    content: "\f113";
    }
        .fi-br-angle-double-left:before {
    content: "\f114";
    }
        .fi-br-angle-double-right:before {
    content: "\f115";
    }
        .fi-br-angle-double-small-down:before {
    content: "\f116";
    }
        .fi-br-angle-double-small-left:before {
    content: "\f117";
    }
        .fi-br-angle-double-small-right:before {
    content: "\f118";
    }
        .fi-br-angle-double-small-up:before {
    content: "\f119";
    }
        .fi-br-angle-down:before {
    content: "\f11a";
    }
        .fi-br-angle-left:before {
    content: "\f11b";
    }
        .fi-br-angle-right:before {
    content: "\f11c";
    }
        .fi-br-angle-small-down:before {
    content: "\f11d";
    }
        .fi-br-angle-small-left:before {
    content: "\f11e";
    }
        .fi-br-angle-small-right:before {
    content: "\f11f";
    }
        .fi-br-angle-small-up:before {
    content: "\f120";
    }
        .fi-br-angle-square-down:before {
    content: "\f121";
    }
        .fi-br-angle-square-left:before {
    content: "\f122";
    }
        .fi-br-angle-square-right:before {
    content: "\f123";
    }
        .fi-br-angle-square-up:before {
    content: "\f124";
    }
        .fi-br-angle-up:before {
    content: "\f125";
    }
        .fi-br-angry:before {
    content: "\f126";
    }
        .fi-br-apple-whole:before {
    content: "\f127";
    }
        .fi-br-apps-add:before {
    content: "\f128";
    }
        .fi-br-apps-delete:before {
    content: "\f129";
    }
        .fi-br-apps-sort:before {
    content: "\f12a";
    }
        .fi-br-apps:before {
    content: "\f12b";
    }
        .fi-br-archive:before {
    content: "\f12c";
    }
        .fi-br-arrow-alt-circle-down:before {
    content: "\f12d";
    }
        .fi-br-arrow-alt-circle-left:before {
    content: "\f12e";
    }
        .fi-br-arrow-alt-circle-right:before {
    content: "\f12f";
    }
        .fi-br-arrow-alt-circle-up:before {
    content: "\f130";
    }
        .fi-br-arrow-alt-down:before {
    content: "\f131";
    }
        .fi-br-arrow-alt-from-bottom:before {
    content: "\f132";
    }
        .fi-br-arrow-alt-from-left:before {
    content: "\f133";
    }
        .fi-br-arrow-alt-from-right:before {
    content: "\f134";
    }
        .fi-br-arrow-alt-from-top:before {
    content: "\f135";
    }
        .fi-br-arrow-alt-left:before {
    content: "\f136";
    }
        .fi-br-arrow-alt-right:before {
    content: "\f137";
    }
        .fi-br-arrow-alt-square-down:before {
    content: "\f138";
    }
        .fi-br-arrow-alt-square-left:before {
    content: "\f139";
    }
        .fi-br-arrow-alt-square-right:before {
    content: "\f13a";
    }
        .fi-br-arrow-alt-square-up:before {
    content: "\f13b";
    }
        .fi-br-arrow-alt-to-bottom:before {
    content: "\f13c";
    }
        .fi-br-arrow-alt-to-left:before {
    content: "\f13d";
    }
        .fi-br-arrow-alt-to-right:before {
    content: "\f13e";
    }
        .fi-br-arrow-alt-to-top:before {
    content: "\f13f";
    }
        .fi-br-arrow-alt-up:before {
    content: "\f140";
    }
        .fi-br-arrow-circle-down:before {
    content: "\f141";
    }
        .fi-br-arrow-circle-left:before {
    content: "\f142";
    }
        .fi-br-arrow-circle-right:before {
    content: "\f143";
    }
        .fi-br-arrow-circle-up:before {
    content: "\f144";
    }
        .fi-br-arrow-down:before {
    content: "\f145";
    }
        .fi-br-arrow-from-bottom:before {
    content: "\f146";
    }
        .fi-br-arrow-from-left:before {
    content: "\f147";
    }
        .fi-br-arrow-from-right:before {
    content: "\f148";
    }
        .fi-br-arrow-from-top:before {
    content: "\f149";
    }
        .fi-br-arrow-left:before {
    content: "\f14a";
    }
        .fi-br-arrow-right:before {
    content: "\f14b";
    }
        .fi-br-arrow-small-down:before {
    content: "\f14c";
    }
        .fi-br-arrow-small-left:before {
    content: "\f14d";
    }
        .fi-br-arrow-small-right:before {
    content: "\f14e";
    }
        .fi-br-arrow-small-up:before {
    content: "\f14f";
    }
        .fi-br-arrow-square-down:before {
    content: "\f150";
    }
        .fi-br-arrow-square-left:before {
    content: "\f151";
    }
        .fi-br-arrow-square-right:before {
    content: "\f152";
    }
        .fi-br-arrow-square-up:before {
    content: "\f153";
    }
        .fi-br-arrow-to-bottom:before {
    content: "\f154";
    }
        .fi-br-arrow-to-left:before {
    content: "\f155";
    }
        .fi-br-arrow-to-right:before {
    content: "\f156";
    }
        .fi-br-arrow-to-top:before {
    content: "\f157";
    }
        .fi-br-arrow-trend-down:before {
    content: "\f158";
    }
        .fi-br-arrow-trend-up:before {
    content: "\f159";
    }
        .fi-br-arrow-up-from-square:before {
    content: "\f15a";
    }
        .fi-br-arrow-up-left-from-circle:before {
    content: "\f15b";
    }
        .fi-br-arrow-up-left:before {
    content: "\f15c";
    }
        .fi-br-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f15d";
    }
        .fi-br-arrow-up-right-from-square:before {
    content: "\f15e";
    }
        .fi-br-arrow-up-right:before {
    content: "\f15f";
    }
        .fi-br-arrow-up-square-triangle:before {
    content: "\f160";
    }
        .fi-br-arrow-up-to-dotted-line:before {
    content: "\f161";
    }
        .fi-br-arrow-up:before {
    content: "\f162";
    }
        .fi-br-arrows-alt-h:before {
    content: "\f163";
    }
        .fi-br-arrows-alt-v:before {
    content: "\f164";
    }
        .fi-br-arrows-alt:before {
    content: "\f165";
    }
        .fi-br-arrows-cross:before {
    content: "\f166";
    }
        .fi-br-arrows-from-dotted-line:before {
    content: "\f167";
    }
        .fi-br-arrows-from-line:before {
    content: "\f168";
    }
        .fi-br-arrows-h-copy:before {
    content: "\f169";
    }
        .fi-br-arrows-h:before {
    content: "\f16a";
    }
        .fi-br-arrows-repeat-1:before {
    content: "\f16b";
    }
        .fi-br-arrows-repeat:before {
    content: "\f16c";
    }
        .fi-br-arrows-to-dotted-line:before {
    content: "\f16d";
    }
        .fi-br-arrows-to-line:before {
    content: "\f16e";
    }
        .fi-br-arrows:before {
    content: "\f16f";
    }
        .fi-br-assept-document:before {
    content: "\f170";
    }
        .fi-br-assistive-listening-systems:before {
    content: "\f171";
    }
        .fi-br-asterik:before {
    content: "\f172";
    }
        .fi-br-astonished-face:before {
    content: "\f173";
    }
        .fi-br-at:before {
    content: "\f174";
    }
        .fi-br-aubergine:before {
    content: "\f175";
    }
        .fi-br-avocado:before {
    content: "\f176";
    }
        .fi-br-b:before {
    content: "\f177";
    }
        .fi-br-baby-carriage:before {
    content: "\f178";
    }
        .fi-br-backpack:before {
    content: "\f179";
    }
        .fi-br-bacon:before {
    content: "\f17a";
    }
        .fi-br-badge-check:before {
    content: "\f17b";
    }
        .fi-br-badge-dollar:before {
    content: "\f17c";
    }
        .fi-br-badge-percent:before {
    content: "\f17d";
    }
        .fi-br-badge-sheriff:before {
    content: "\f17e";
    }
        .fi-br-badge:before {
    content: "\f17f";
    }
        .fi-br-bags-shopping:before {
    content: "\f180";
    }
        .fi-br-bahai:before {
    content: "\f181";
    }
        .fi-br-balance-scale-left:before {
    content: "\f182";
    }
        .fi-br-balance-scale-right:before {
    content: "\f183";
    }
        .fi-br-balloons:before {
    content: "\f184";
    }
        .fi-br-ballot:before {
    content: "\f185";
    }
        .fi-br-ban:before {
    content: "\f186";
    }
        .fi-br-band-aid:before {
    content: "\f187";
    }
        .fi-br-bank:before {
    content: "\f188";
    }
        .fi-br-barber-shop:before {
    content: "\f189";
    }
        .fi-br-barcode-read:before {
    content: "\f18a";
    }
        .fi-br-barcode-scan:before {
    content: "\f18b";
    }
        .fi-br-barcode:before {
    content: "\f18c";
    }
        .fi-br-bars-progress:before {
    content: "\f18d";
    }
        .fi-br-baseball-alt:before {
    content: "\f18e";
    }
        .fi-br-baseball:before {
    content: "\f18f";
    }
        .fi-br-basketball-hoop:before {
    content: "\f190";
    }
        .fi-br-basketball:before {
    content: "\f191";
    }
        .fi-br-bed-alt:before {
    content: "\f192";
    }
        .fi-br-bed:before {
    content: "\f193";
    }
        .fi-br-beer:before {
    content: "\f194";
    }
        .fi-br-bell-ring:before {
    content: "\f195";
    }
        .fi-br-bell-school:before {
    content: "\f196";
    }
        .fi-br-bell:before {
    content: "\f197";
    }
        .fi-br-bike:before {
    content: "\f198";
    }
        .fi-br-biking-mountain:before {
    content: "\f199";
    }
        .fi-br-biking:before {
    content: "\f19a";
    }
        .fi-br-billiard:before {
    content: "\f19b";
    }
        .fi-br-blinds-open:before {
    content: "\f19c";
    }
        .fi-br-blinds-raised:before {
    content: "\f19d";
    }
        .fi-br-blinds:before {
    content: "\f19e";
    }
        .fi-br-bold:before {
    content: "\f19f";
    }
        .fi-br-bolt:before {
    content: "\f1a0";
    }
        .fi-br-book-alt:before {
    content: "\f1a1";
    }
        .fi-br-book-arrow-right:before {
    content: "\f1a2";
    }
        .fi-br-book-arrow-up:before {
    content: "\f1a3";
    }
        .fi-br-book-bookmark:before {
    content: "\f1a4";
    }
        .fi-br-book-open-cover:before {
    content: "\f1a5";
    }
        .fi-br-book-open-reader:before {
    content: "\f1a6";
    }
        .fi-br-book:before {
    content: "\f1a7";
    }
        .fi-br-bookmark:before {
    content: "\f1a8";
    }
        .fi-br-books:before {
    content: "\f1a9";
    }
        .fi-br-bottle:before {
    content: "\f1aa";
    }
        .fi-br-bow-arrow:before {
    content: "\f1ab";
    }
        .fi-br-bowling-ball:before {
    content: "\f1ac";
    }
        .fi-br-bowling-pins:before {
    content: "\f1ad";
    }
        .fi-br-bowling:before {
    content: "\f1ae";
    }
        .fi-br-box-alt:before {
    content: "\f1af";
    }
        .fi-br-box-ballot:before {
    content: "\f1b0";
    }
        .fi-br-box-check:before {
    content: "\f1b1";
    }
        .fi-br-box-tissue:before {
    content: "\f1b2";
    }
        .fi-br-box:before {
    content: "\f1b3";
    }
        .fi-br-boxes:before {
    content: "\f1b4";
    }
        .fi-br-boxing-glove:before {
    content: "\f1b5";
    }
        .fi-br-braille:before {
    content: "\f1b6";
    }
        .fi-br-brain-circuit:before {
    content: "\f1b7";
    }
        .fi-br-bread-slice:before {
    content: "\f1b8";
    }
        .fi-br-bread:before {
    content: "\f1b9";
    }
        .fi-br-briefcase:before {
    content: "\f1ba";
    }
        .fi-br-broccoli:before {
    content: "\f1bb";
    }
        .fi-br-broom:before {
    content: "\f1bc";
    }
        .fi-br-browser:before {
    content: "\f1bd";
    }
        .fi-br-brush:before {
    content: "\f1be";
    }
        .fi-br-bug:before {
    content: "\f1bf";
    }
        .fi-br-building:before {
    content: "\f1c0";
    }
        .fi-br-bulb:before {
    content: "\f1c1";
    }
        .fi-br-bullseye-pointer:before {
    content: "\f1c2";
    }
        .fi-br-bullseye:before {
    content: "\f1c3";
    }
        .fi-br-burrito:before {
    content: "\f1c4";
    }
        .fi-br-bus-alt:before {
    content: "\f1c5";
    }
        .fi-br-bus:before {
    content: "\f1c6";
    }
        .fi-br-business-time:before {
    content: "\f1c7";
    }
        .fi-br-butterfly:before {
    content: "\f1c8";
    }
        .fi-br-c:before {
    content: "\f1c9";
    }
        .fi-br-cake-birthday:before {
    content: "\f1ca";
    }
        .fi-br-cake-wedding:before {
    content: "\f1cb";
    }
        .fi-br-calculator:before {
    content: "\f1cc";
    }
        .fi-br-calendar-check:before {
    content: "\f1cd";
    }
        .fi-br-calendar-clock:before {
    content: "\f1ce";
    }
        .fi-br-calendar-exclamation:before {
    content: "\f1cf";
    }
        .fi-br-calendar-lines-pen:before {
    content: "\f1d0";
    }
        .fi-br-calendar-lines:before {
    content: "\f1d1";
    }
        .fi-br-calendar-minus:before {
    content: "\f1d2";
    }
        .fi-br-calendar-pen:before {
    content: "\f1d3";
    }
        .fi-br-calendar-plus:before {
    content: "\f1d4";
    }
        .fi-br-calendar:before {
    content: "\f1d5";
    }
        .fi-br-calendars:before {
    content: "\f1d6";
    }
        .fi-br-call-history:before {
    content: "\f1d7";
    }
        .fi-br-call-incoming:before {
    content: "\f1d8";
    }
        .fi-br-call-missed:before {
    content: "\f1d9";
    }
        .fi-br-call-outgoing:before {
    content: "\f1da";
    }
        .fi-br-camcorder:before {
    content: "\f1db";
    }
        .fi-br-camera:before {
    content: "\f1dc";
    }
        .fi-br-camping:before {
    content: "\f1dd";
    }
        .fi-br-candy-alt:before {
    content: "\f1de";
    }
        .fi-br-candy:before {
    content: "\f1df";
    }
        .fi-br-canned-food:before {
    content: "\f1e0";
    }
        .fi-br-car-alt:before {
    content: "\f1e1";
    }
        .fi-br-car-battery:before {
    content: "\f1e2";
    }
        .fi-br-car-bolt:before {
    content: "\f1e3";
    }
        .fi-br-car-building:before {
    content: "\f1e4";
    }
        .fi-br-car-bump:before {
    content: "\f1e5";
    }
        .fi-br-car-bus:before {
    content: "\f1e6";
    }
        .fi-br-car-circle-bolt:before {
    content: "\f1e7";
    }
        .fi-br-car-crash:before {
    content: "\f1e8";
    }
        .fi-br-car-garage:before {
    content: "\f1e9";
    }
        .fi-br-car-mechanic:before {
    content: "\f1ea";
    }
        .fi-br-car-side-bolt:before {
    content: "\f1eb";
    }
        .fi-br-car-side:before {
    content: "\f1ec";
    }
        .fi-br-car-tilt:before {
    content: "\f1ed";
    }
        .fi-br-car-wash:before {
    content: "\f1ee";
    }
        .fi-br-car:before {
    content: "\f1ef";
    }
        .fi-br-caravan-alt:before {
    content: "\f1f0";
    }
        .fi-br-caravan:before {
    content: "\f1f1";
    }
        .fi-br-care:before {
    content: "\f1f2";
    }
        .fi-br-caret-circle-down:before {
    content: "\f1f3";
    }
        .fi-br-caret-circle-right:before {
    content: "\f1f4";
    }
        .fi-br-caret-circle-up:before {
    content: "\f1f5";
    }
        .fi-br-caret-down:before {
    content: "\f1f6";
    }
        .fi-br-caret-left:before {
    content: "\f1f7";
    }
        .fi-br-caret-quare-up:before {
    content: "\f1f8";
    }
        .fi-br-caret-right:before {
    content: "\f1f9";
    }
        .fi-br-caret-square-down:before {
    content: "\f1fa";
    }
        .fi-br-caret-square-left_1:before {
    content: "\f1fb";
    }
        .fi-br-caret-square-left:before {
    content: "\f1fc";
    }
        .fi-br-caret-square-right:before {
    content: "\f1fd";
    }
        .fi-br-caret-up:before {
    content: "\f1fe";
    }
        .fi-br-carrot:before {
    content: "\f1ff";
    }
        .fi-br-cars:before {
    content: "\f200";
    }
        .fi-br-cash-register:before {
    content: "\f201";
    }
        .fi-br-chair-office:before {
    content: "\f202";
    }
        .fi-br-chair:before {
    content: "\f203";
    }
        .fi-br-charging-station:before {
    content: "\f204";
    }
        .fi-br-chart-area:before {
    content: "\f205";
    }
        .fi-br-chart-connected:before {
    content: "\f206";
    }
        .fi-br-chart-histogram:before {
    content: "\f207";
    }
        .fi-br-chart-line-up:before {
    content: "\f208";
    }
        .fi-br-chart-network:before {
    content: "\f209";
    }
        .fi-br-chart-pie-alt:before {
    content: "\f20a";
    }
        .fi-br-chart-pie:before {
    content: "\f20b";
    }
        .fi-br-chart-pyramid:before {
    content: "\f20c";
    }
        .fi-br-chart-scatter:before {
    content: "\f20d";
    }
        .fi-br-chart-set-theory:before {
    content: "\f20e";
    }
        .fi-br-chart-tree:before {
    content: "\f20f";
    }
        .fi-br-chat-arrow-down:before {
    content: "\f210";
    }
        .fi-br-chat-arrow-grow:before {
    content: "\f211";
    }
        .fi-br-check:before {
    content: "\f212";
    }
        .fi-br-checkbox:before {
    content: "\f213";
    }
        .fi-br-cheese:before {
    content: "\f214";
    }
        .fi-br-cherry:before {
    content: "\f215";
    }
        .fi-br-chess-bishop:before {
    content: "\f216";
    }
        .fi-br-chess-board:before {
    content: "\f217";
    }
        .fi-br-chess-clock-alt:before {
    content: "\f218";
    }
        .fi-br-chess-clock:before {
    content: "\f219";
    }
        .fi-br-chess-king-alt:before {
    content: "\f21a";
    }
        .fi-br-chess-king:before {
    content: "\f21b";
    }
        .fi-br-chess-knight-alt:before {
    content: "\f21c";
    }
        .fi-br-chess-knight:before {
    content: "\f21d";
    }
        .fi-br-chess-pawn-alt:before {
    content: "\f21e";
    }
        .fi-br-chess-pawn:before {
    content: "\f21f";
    }
        .fi-br-chess-piece:before {
    content: "\f220";
    }
        .fi-br-chess-queen-alt:before {
    content: "\f221";
    }
        .fi-br-chess-queen:before {
    content: "\f222";
    }
        .fi-br-chess-rook-alt:before {
    content: "\f223";
    }
        .fi-br-chess-rook:before {
    content: "\f224";
    }
        .fi-br-chess:before {
    content: "\f225";
    }
        .fi-br-chevron-double-down:before {
    content: "\f226";
    }
        .fi-br-chevron-double-up:before {
    content: "\f227";
    }
        .fi-br-child-head:before {
    content: "\f228";
    }
        .fi-br-chocolate:before {
    content: "\f229";
    }
        .fi-br-circle-envelope:before {
    content: "\f22a";
    }
        .fi-br-circle-phone-flip:before {
    content: "\f22b";
    }
        .fi-br-circle-phone-hangup:before {
    content: "\f22c";
    }
        .fi-br-circle-phone:before {
    content: "\f22d";
    }
        .fi-br-circle-small:before {
    content: "\f22e";
    }
        .fi-br-circle:before {
    content: "\f22f";
    }
        .fi-br-clip:before {
    content: "\f230";
    }
        .fi-br-clipboard-list-check:before {
    content: "\f231";
    }
        .fi-br-clipboard-list:before {
    content: "\f232";
    }
        .fi-br-clock-eight-thirty:before {
    content: "\f233";
    }
        .fi-br-clock-eleven-thirty:before {
    content: "\f234";
    }
        .fi-br-clock-eleven:before {
    content: "\f235";
    }
        .fi-br-clock-five-thirty:before {
    content: "\f236";
    }
        .fi-br-clock-five:before {
    content: "\f237";
    }
        .fi-br-clock-four-thirty:before {
    content: "\f238";
    }
        .fi-br-clock-nine-thirty:before {
    content: "\f239";
    }
        .fi-br-clock-nine:before {
    content: "\f23a";
    }
        .fi-br-clock-one-thirty:before {
    content: "\f23b";
    }
        .fi-br-clock-one:before {
    content: "\f23c";
    }
        .fi-br-clock-seven-thirty:before {
    content: "\f23d";
    }
        .fi-br-clock-seven:before {
    content: "\f23e";
    }
        .fi-br-clock-six-thirty:before {
    content: "\f23f";
    }
        .fi-br-clock-six:before {
    content: "\f240";
    }
        .fi-br-clock-ten-thirty:before {
    content: "\f241";
    }
        .fi-br-clock-ten:before {
    content: "\f242";
    }
        .fi-br-clock-three-thirty:before {
    content: "\f243";
    }
        .fi-br-clock-three:before {
    content: "\f244";
    }
        .fi-br-clock-twelve-thirty:before {
    content: "\f245";
    }
        .fi-br-clock-twelve:before {
    content: "\f246";
    }
        .fi-br-clock-two-thirty:before {
    content: "\f247";
    }
        .fi-br-clock-two:before {
    content: "\f248";
    }
        .fi-br-clock:before {
    content: "\f249";
    }
        .fi-br-cloud-check:before {
    content: "\f24a";
    }
        .fi-br-cloud-disabled:before {
    content: "\f24b";
    }
        .fi-br-cloud-download-alt:before {
    content: "\f24c";
    }
        .fi-br-cloud-download:before {
    content: "\f24d";
    }
        .fi-br-cloud-drizzle:before {
    content: "\f24e";
    }
        .fi-br-cloud-hail-mixed:before {
    content: "\f24f";
    }
        .fi-br-cloud-hail:before {
    content: "\f250";
    }
        .fi-br-cloud-moon-rain:before {
    content: "\f251";
    }
        .fi-br-cloud-moon:before {
    content: "\f252";
    }
        .fi-br-cloud-rain:before {
    content: "\f253";
    }
        .fi-br-cloud-rainbow:before {
    content: "\f254";
    }
        .fi-br-cloud-share:before {
    content: "\f255";
    }
        .fi-br-cloud-showers-heavy:before {
    content: "\f256";
    }
        .fi-br-cloud-showers:before {
    content: "\f257";
    }
        .fi-br-cloud-sleet:before {
    content: "\f258";
    }
        .fi-br-cloud-snow:before {
    content: "\f259";
    }
        .fi-br-cloud-sun-rain:before {
    content: "\f25a";
    }
        .fi-br-cloud-sun:before {
    content: "\f25b";
    }
        .fi-br-cloud-upload-alt:before {
    content: "\f25c";
    }
        .fi-br-cloud-upload:before {
    content: "\f25d";
    }
        .fi-br-cloud:before {
    content: "\f25e";
    }
        .fi-br-clouds-moon:before {
    content: "\f25f";
    }
        .fi-br-clouds-sun:before {
    content: "\f260";
    }
        .fi-br-clouds:before {
    content: "\f261";
    }
        .fi-br-club:before {
    content: "\f262";
    }
        .fi-br-cocktail-alt:before {
    content: "\f263";
    }
        .fi-br-cocktail:before {
    content: "\f264";
    }
        .fi-br-code-branch:before {
    content: "\f265";
    }
        .fi-br-code-commit:before {
    content: "\f266";
    }
        .fi-br-code-compare:before {
    content: "\f267";
    }
        .fi-br-code-fork:before {
    content: "\f268";
    }
        .fi-br-code-merge:before {
    content: "\f269";
    }
        .fi-br-code-pull-request-closed:before {
    content: "\f26a";
    }
        .fi-br-code-pull-request-draft:before {
    content: "\f26b";
    }
        .fi-br-code-pull-request:before {
    content: "\f26c";
    }
        .fi-br-code-simple:before {
    content: "\f26d";
    }
        .fi-br-coffee-pot:before {
    content: "\f26e";
    }
        .fi-br-coffee:before {
    content: "\f26f";
    }
        .fi-br-coin:before {
    content: "\f270";
    }
        .fi-br-coins:before {
    content: "\f271";
    }
        .fi-br-comet:before {
    content: "\f272";
    }
        .fi-br-comment-alt-middle-top:before {
    content: "\f273";
    }
        .fi-br-comment-alt-middle:before {
    content: "\f274";
    }
        .fi-br-comment-alt:before {
    content: "\f275";
    }
        .fi-br-comment-arrow-down:before {
    content: "\f276";
    }
        .fi-br-comment-arrow-up-right:before {
    content: "\f277";
    }
        .fi-br-comment-arrow-up:before {
    content: "\f278";
    }
        .fi-br-comment-check:before {
    content: "\f279";
    }
        .fi-br-comment-code:before {
    content: "\f27a";
    }
        .fi-br-comment-dollar:before {
    content: "\f27b";
    }
        .fi-br-comment-exclamation:before {
    content: "\f27c";
    }
        .fi-br-comment-heart:before {
    content: "\f27d";
    }
        .fi-br-comment-image:before {
    content: "\f27e";
    }
        .fi-br-comment-info:before {
    content: "\f27f";
    }
        .fi-br-comment-pen:before {
    content: "\f280";
    }
        .fi-br-comment-question:before {
    content: "\f281";
    }
        .fi-br-comment-quote:before {
    content: "\f282";
    }
        .fi-br-comment-slash:before {
    content: "\f283";
    }
        .fi-br-comment-sms:before {
    content: "\f284";
    }
        .fi-br-comment-text:before {
    content: "\f285";
    }
        .fi-br-comment-user:before {
    content: "\f286";
    }
        .fi-br-comment-xmark:before {
    content: "\f287";
    }
        .fi-br-comment:before {
    content: "\f288";
    }
        .fi-br-comments-dollar:before {
    content: "\f289";
    }
        .fi-br-comments-question-check:before {
    content: "\f28a";
    }
        .fi-br-comments-question:before {
    content: "\f28b";
    }
        .fi-br-comments:before {
    content: "\f28c";
    }
        .fi-br-compress-alt:before {
    content: "\f28d";
    }
        .fi-br-compress:before {
    content: "\f28e";
    }
        .fi-br-computer:before {
    content: "\f28f";
    }
        .fi-br-confetti:before {
    content: "\f290";
    }
        .fi-br-cookie:before {
    content: "\f291";
    }
        .fi-br-copy-alt:before {
    content: "\f292";
    }
        .fi-br-copy:before {
    content: "\f293";
    }
        .fi-br-copyright:before {
    content: "\f294";
    }
        .fi-br-corn:before {
    content: "\f295";
    }
        .fi-br-couple:before {
    content: "\f296";
    }
        .fi-br-cow:before {
    content: "\f297";
    }
        .fi-br-cream:before {
    content: "\f298";
    }
        .fi-br-credit-card:before {
    content: "\f299";
    }
        .fi-br-cricket:before {
    content: "\f29a";
    }
        .fi-br-croissant:before {
    content: "\f29b";
    }
        .fi-br-cross-circle:before {
    content: "\f29c";
    }
        .fi-br-cross-small:before {
    content: "\f29d";
    }
        .fi-br-cross:before {
    content: "\f29e";
    }
        .fi-br-crown:before {
    content: "\f29f";
    }
        .fi-br-crystal-ball:before {
    content: "\f2a0";
    }
        .fi-br-cube:before {
    content: "\f2a1";
    }
        .fi-br-cupcake:before {
    content: "\f2a2";
    }
        .fi-br-curling:before {
    content: "\f2a3";
    }
        .fi-br-cursor-finger:before {
    content: "\f2a4";
    }
        .fi-br-cursor-plus:before {
    content: "\f2a5";
    }
        .fi-br-cursor-text-alt:before {
    content: "\f2a6";
    }
        .fi-br-cursor-text:before {
    content: "\f2a7";
    }
        .fi-br-cursor:before {
    content: "\f2a8";
    }
        .fi-br-d:before {
    content: "\f2a9";
    }
        .fi-br-dart:before {
    content: "\f2aa";
    }
        .fi-br-dashboard:before {
    content: "\f2ab";
    }
        .fi-br-data-transfer:before {
    content: "\f2ac";
    }
        .fi-br-database:before {
    content: "\f2ad";
    }
        .fi-br-delete-document:before {
    content: "\f2ae";
    }
        .fi-br-delete-user:before {
    content: "\f2af";
    }
        .fi-br-delete:before {
    content: "\f2b0";
    }
        .fi-br-democrat:before {
    content: "\f2b1";
    }
        .fi-br-dewpoint:before {
    content: "\f2b2";
    }
        .fi-br-diagram-project:before {
    content: "\f2b3";
    }
        .fi-br-diamond:before {
    content: "\f2b4";
    }
        .fi-br-dice-alt:before {
    content: "\f2b5";
    }
        .fi-br-dice-d10:before {
    content: "\f2b6";
    }
        .fi-br-dice-d12:before {
    content: "\f2b7";
    }
        .fi-br-dice-d20:before {
    content: "\f2b8";
    }
        .fi-br-dice-d4:before {
    content: "\f2b9";
    }
        .fi-br-dice-d6:before {
    content: "\f2ba";
    }
        .fi-br-dice-d8:before {
    content: "\f2bb";
    }
        .fi-br-dice-four:before {
    content: "\f2bc";
    }
        .fi-br-dice-one:before {
    content: "\f2bd";
    }
        .fi-br-dice-six:before {
    content: "\f2be";
    }
        .fi-br-dice-three:before {
    content: "\f2bf";
    }
        .fi-br-dice-two:before {
    content: "\f2c0";
    }
        .fi-br-dice:before {
    content: "\f2c1";
    }
        .fi-br-diploma:before {
    content: "\f2c2";
    }
        .fi-br-disco-ball:before {
    content: "\f2c3";
    }
        .fi-br-disk:before {
    content: "\f2c4";
    }
        .fi-br-display-code:before {
    content: "\f2c5";
    }
        .fi-br-dizzy:before {
    content: "\f2c6";
    }
        .fi-br-doctor:before {
    content: "\f2c7";
    }
        .fi-br-document-signed:before {
    content: "\f2c8";
    }
        .fi-br-document:before {
    content: "\f2c9";
    }
        .fi-br-dollar:before {
    content: "\f2ca";
    }
        .fi-br-donate:before {
    content: "\f2cb";
    }
        .fi-br-down-left-and-up-right-to-center:before {
    content: "\f2cc";
    }
        .fi-br-download:before {
    content: "\f2cd";
    }
        .fi-br-drafting-compass:before {
    content: "\f2ce";
    }
        .fi-br-dreidel:before {
    content: "\f2cf";
    }
        .fi-br-drink-alt:before {
    content: "\f2d0";
    }
        .fi-br-drumstick:before {
    content: "\f2d1";
    }
        .fi-br-duplicate:before {
    content: "\f2d2";
    }
        .fi-br-e-learning:before {
    content: "\f2d3";
    }
        .fi-br-e:before {
    content: "\f2d4";
    }
        .fi-br-earnings:before {
    content: "\f2d5";
    }
        .fi-br-eclipse-alt:before {
    content: "\f2d6";
    }
        .fi-br-eclipse:before {
    content: "\f2d7";
    }
        .fi-br-edit-alt:before {
    content: "\f2d8";
    }
        .fi-br-edit:before {
    content: "\f2d9";
    }
        .fi-br-egg-fried:before {
    content: "\f2da";
    }
        .fi-br-egg:before {
    content: "\f2db";
    }
        .fi-br-engine-warning:before {
    content: "\f2dc";
    }
        .fi-br-enter:before {
    content: "\f2dd";
    }
        .fi-br-envelope-ban:before {
    content: "\f2de";
    }
        .fi-br-envelope-bulk:before {
    content: "\f2df";
    }
        .fi-br-envelope-download:before {
    content: "\f2e0";
    }
        .fi-br-envelope-marker:before {
    content: "\f2e1";
    }
        .fi-br-envelope-open-dollar:before {
    content: "\f2e2";
    }
        .fi-br-envelope-open-text:before {
    content: "\f2e3";
    }
        .fi-br-envelope-open:before {
    content: "\f2e4";
    }
        .fi-br-envelope-plus:before {
    content: "\f2e5";
    }
        .fi-br-envelope:before {
    content: "\f2e6";
    }
        .fi-br-equality:before {
    content: "\f2e7";
    }
        .fi-br-euro:before {
    content: "\f2e8";
    }
        .fi-br-exchange-alt:before {
    content: "\f2e9";
    }
        .fi-br-exchange:before {
    content: "\f2ea";
    }
        .fi-br-exclamation:before {
    content: "\f2eb";
    }
        .fi-br-exit:before {
    content: "\f2ec";
    }
        .fi-br-expand-arrows-alt:before {
    content: "\f2ed";
    }
        .fi-br-expand-arrows:before {
    content: "\f2ee";
    }
        .fi-br-expand:before {
    content: "\f2ef";
    }
        .fi-br-eye-crossed:before {
    content: "\f2f0";
    }
        .fi-br-eye-dropper:before {
    content: "\f2f1";
    }
        .fi-br-eye:before {
    content: "\f2f2";
    }
        .fi-br-f:before {
    content: "\f2f3";
    }
        .fi-br-feather:before {
    content: "\f2f4";
    }
        .fi-br-female-couple:before {
    content: "\f2f5";
    }
        .fi-br-female:before {
    content: "\f2f6";
    }
        .fi-br-ferris-wheel:before {
    content: "\f2f7";
    }
        .fi-br-field-hockey:before {
    content: "\f2f8";
    }
        .fi-br-fighter-jet:before {
    content: "\f2f9";
    }
        .fi-br-file-ai:before {
    content: "\f2fa";
    }
        .fi-br-file-chart-line:before {
    content: "\f2fb";
    }
        .fi-br-file-chart-pie:before {
    content: "\f2fc";
    }
        .fi-br-file-code:before {
    content: "\f2fd";
    }
        .fi-br-file-eps:before {
    content: "\f2fe";
    }
        .fi-br-file-invoice-dollar:before {
    content: "\f2ff";
    }
        .fi-br-file-invoice:before {
    content: "\f300";
    }
        .fi-br-file-psd:before {
    content: "\f301";
    }
        .fi-br-file-spreadsheet:before {
    content: "\f302";
    }
        .fi-br-file-video:before {
    content: "\f303";
    }
        .fi-br-file:before {
    content: "\f304";
    }
        .fi-br-fill:before {
    content: "\f305";
    }
        .fi-br-film-slash:before {
    content: "\f306";
    }
        .fi-br-film:before {
    content: "\f307";
    }
        .fi-br-filter-slash:before {
    content: "\f308";
    }
        .fi-br-filter:before {
    content: "\f309";
    }
        .fi-br-filters:before {
    content: "\f30a";
    }
        .fi-br-fingerprint:before {
    content: "\f30b";
    }
        .fi-br-fish:before {
    content: "\f30c";
    }
        .fi-br-flag-alt:before {
    content: "\f30d";
    }
        .fi-br-flag-usa:before {
    content: "\f30e";
    }
        .fi-br-flag:before {
    content: "\f30f";
    }
        .fi-br-flame:before {
    content: "\f310";
    }
        .fi-br-flip-horizontal:before {
    content: "\f311";
    }
        .fi-br-flower-bouquet:before {
    content: "\f312";
    }
        .fi-br-flower-tulip:before {
    content: "\f313";
    }
        .fi-br-flower:before {
    content: "\f314";
    }
        .fi-br-flushed-face:before {
    content: "\f315";
    }
        .fi-br-flushed:before {
    content: "\f316";
    }
        .fi-br-fog:before {
    content: "\f317";
    }
        .fi-br-folder-download:before {
    content: "\f318";
    }
        .fi-br-folder-minus:before {
    content: "\f319";
    }
        .fi-br-folder-times:before {
    content: "\f31a";
    }
        .fi-br-folder-tree:before {
    content: "\f31b";
    }
        .fi-br-folder-upload:before {
    content: "\f31c";
    }
        .fi-br-folder:before {
    content: "\f31d";
    }
        .fi-br-folders:before {
    content: "\f31e";
    }
        .fi-br-following:before {
    content: "\f31f";
    }
        .fi-br-football:before {
    content: "\f320";
    }
        .fi-br-fork:before {
    content: "\f321";
    }
        .fi-br-form:before {
    content: "\f322";
    }
        .fi-br-forward:before {
    content: "\f323";
    }
        .fi-br-fox:before {
    content: "\f324";
    }
        .fi-br-french-fries:before {
    content: "\f325";
    }
        .fi-br-ftp:before {
    content: "\f326";
    }
        .fi-br-funnel-dollar:before {
    content: "\f327";
    }
        .fi-br-g:before {
    content: "\f328";
    }
        .fi-br-gallery:before {
    content: "\f329";
    }
        .fi-br-game-board-alt:before {
    content: "\f32a";
    }
        .fi-br-gamepad:before {
    content: "\f32b";
    }
        .fi-br-garage-car:before {
    content: "\f32c";
    }
        .fi-br-garage-open:before {
    content: "\f32d";
    }
        .fi-br-garage:before {
    content: "\f32e";
    }
        .fi-br-garlic:before {
    content: "\f32f";
    }
        .fi-br-gas-pump-alt:before {
    content: "\f330";
    }
        .fi-br-gas-pump-slash:before {
    content: "\f331";
    }
        .fi-br-gas-pump:before {
    content: "\f332";
    }
        .fi-br-gem:before {
    content: "\f333";
    }
        .fi-br-gif:before {
    content: "\f334";
    }
        .fi-br-gift-card:before {
    content: "\f335";
    }
        .fi-br-gift:before {
    content: "\f336";
    }
        .fi-br-gifts:before {
    content: "\f337";
    }
        .fi-br-gingerbread-man:before {
    content: "\f338";
    }
        .fi-br-glass-cheers:before {
    content: "\f339";
    }
        .fi-br-glass:before {
    content: "\f33a";
    }
        .fi-br-glasses:before {
    content: "\f33b";
    }
        .fi-br-globe-alt:before {
    content: "\f33c";
    }
        .fi-br-globe:before {
    content: "\f33d";
    }
        .fi-br-golf-ball:before {
    content: "\f33e";
    }
        .fi-br-golf-club:before {
    content: "\f33f";
    }
        .fi-br-golf:before {
    content: "\f340";
    }
        .fi-br-graduation-cap:before {
    content: "\f341";
    }
        .fi-br-grape:before {
    content: "\f342";
    }
        .fi-br-graphic-tablet:before {
    content: "\f343";
    }
        .fi-br-grid-alt:before {
    content: "\f344";
    }
        .fi-br-grid:before {
    content: "\f345";
    }
        .fi-br-grill:before {
    content: "\f346";
    }
        .fi-br-grimace:before {
    content: "\f347";
    }
        .fi-br-grin-alt:before {
    content: "\f348";
    }
        .fi-br-grin-beam-sweat:before {
    content: "\f349";
    }
        .fi-br-grin-beam:before {
    content: "\f34a";
    }
        .fi-br-grin-hearts:before {
    content: "\f34b";
    }
        .fi-br-grin-squint-tears:before {
    content: "\f34c";
    }
        .fi-br-grin-squint:before {
    content: "\f34d";
    }
        .fi-br-grin-stars:before {
    content: "\f34e";
    }
        .fi-br-grin-tears:before {
    content: "\f34f";
    }
        .fi-br-grin-tongue-squint:before {
    content: "\f350";
    }
        .fi-br-grin-tongue-wink:before {
    content: "\f351";
    }
        .fi-br-grin-tongue:before {
    content: "\f352";
    }
        .fi-br-grin-wink:before {
    content: "\f353";
    }
        .fi-br-grin:before {
    content: "\f354";
    }
        .fi-br-guitar:before {
    content: "\f355";
    }
        .fi-br-gym:before {
    content: "\f356";
    }
        .fi-br-h:before {
    content: "\f357";
    }
        .fi-br-hamburger-soda:before {
    content: "\f358";
    }
        .fi-br-hamburger:before {
    content: "\f359";
    }
        .fi-br-hand-holding-box:before {
    content: "\f35a";
    }
        .fi-br-hand-holding-seeding:before {
    content: "\f35b";
    }
        .fi-br-hand:before {
    content: "\f35c";
    }
        .fi-br-handshake:before {
    content: "\f35d";
    }
        .fi-br-happy:before {
    content: "\f35e";
    }
        .fi-br-hastag:before {
    content: "\f35f";
    }
        .fi-br-hat-birthday:before {
    content: "\f360";
    }
        .fi-br-hat-chef:before {
    content: "\f361";
    }
        .fi-br-head-side-thinking:before {
    content: "\f362";
    }
        .fi-br-headphones:before {
    content: "\f363";
    }
        .fi-br-headset:before {
    content: "\f364";
    }
        .fi-br-heart-arrow:before {
    content: "\f365";
    }
        .fi-br-heart:before {
    content: "\f366";
    }
        .fi-br-heat:before {
    content: "\f367";
    }
        .fi-br-helicopter-side:before {
    content: "\f368";
    }
        .fi-br-highlighter:before {
    content: "\f369";
    }
        .fi-br-hiking:before {
    content: "\f36a";
    }
        .fi-br-hockey-mask:before {
    content: "\f36b";
    }
        .fi-br-hockey-puck:before {
    content: "\f36c";
    }
        .fi-br-hockey-sticks:before {
    content: "\f36d";
    }
        .fi-br-home-location-alt:before {
    content: "\f36e";
    }
        .fi-br-home-location:before {
    content: "\f36f";
    }
        .fi-br-home:before {
    content: "\f370";
    }
        .fi-br-hotdog:before {
    content: "\f371";
    }
        .fi-br-hourglass-end:before {
    content: "\f372";
    }
        .fi-br-hourglass:before {
    content: "\f373";
    }
        .fi-br-house-flood:before {
    content: "\f374";
    }
        .fi-br-hryvnia:before {
    content: "\f375";
    }
        .fi-br-humidity:before {
    content: "\f376";
    }
        .fi-br-hurricane:before {
    content: "\f377";
    }
        .fi-br-i:before {
    content: "\f378";
    }
        .fi-br-ice-cream:before {
    content: "\f379";
    }
        .fi-br-ice-skate:before {
    content: "\f37a";
    }
        .fi-br-id-badge:before {
    content: "\f37b";
    }
        .fi-br-inbox-in:before {
    content: "\f37c";
    }
        .fi-br-inbox-out:before {
    content: "\f37d";
    }
        .fi-br-inbox:before {
    content: "\f37e";
    }
        .fi-br-incognito:before {
    content: "\f37f";
    }
        .fi-br-indent:before {
    content: "\f380";
    }
        .fi-br-infinity:before {
    content: "\f381";
    }
        .fi-br-info:before {
    content: "\f382";
    }
        .fi-br-interactive:before {
    content: "\f383";
    }
        .fi-br-interlining:before {
    content: "\f384";
    }
        .fi-br-interrogation:before {
    content: "\f385";
    }
        .fi-br-italic:before {
    content: "\f386";
    }
        .fi-br-j:before {
    content: "\f387";
    }
        .fi-br-jam:before {
    content: "\f388";
    }
        .fi-br-jpg:before {
    content: "\f389";
    }
        .fi-br-k:before {
    content: "\f38a";
    }
        .fi-br-kerning:before {
    content: "\f38b";
    }
        .fi-br-key:before {
    content: "\f38c";
    }
        .fi-br-keyboard:before {
    content: "\f38d";
    }
        .fi-br-keynote:before {
    content: "\f38e";
    }
        .fi-br-kiss-beam:before {
    content: "\f38f";
    }
        .fi-br-kiss-wink-heart:before {
    content: "\f390";
    }
        .fi-br-kiss:before {
    content: "\f391";
    }
        .fi-br-kite:before {
    content: "\f392";
    }
        .fi-br-knife:before {
    content: "\f393";
    }
        .fi-br-l:before {
    content: "\f394";
    }
        .fi-br-label:before {
    content: "\f395";
    }
        .fi-br-laptop-code:before {
    content: "\f396";
    }
        .fi-br-laptop:before {
    content: "\f397";
    }
        .fi-br-lasso:before {
    content: "\f398";
    }
        .fi-br-laugh-beam:before {
    content: "\f399";
    }
        .fi-br-laugh-squint:before {
    content: "\f39a";
    }
        .fi-br-laugh-wink:before {
    content: "\f39b";
    }
        .fi-br-laugh:before {
    content: "\f39c";
    }
        .fi-br-layer-minus:before {
    content: "\f39d";
    }
        .fi-br-layer-plus:before {
    content: "\f39e";
    }
        .fi-br-layers:before {
    content: "\f39f";
    }
        .fi-br-layout-fluid:before {
    content: "\f3a0";
    }
        .fi-br-leaf:before {
    content: "\f3a1";
    }
        .fi-br-lemon:before {
    content: "\f3a2";
    }
        .fi-br-letter-case:before {
    content: "\f3a3";
    }
        .fi-br-lettuce:before {
    content: "\f3a4";
    }
        .fi-br-level-down-alt:before {
    content: "\f3a5";
    }
        .fi-br-level-down:before {
    content: "\f3a6";
    }
        .fi-br-level-up-alt:before {
    content: "\f3a7";
    }
        .fi-br-level-up:before {
    content: "\f3a8";
    }
        .fi-br-life-ring:before {
    content: "\f3a9";
    }
        .fi-br-lightbulb-dollar:before {
    content: "\f3aa";
    }
        .fi-br-line-width:before {
    content: "\f3ab";
    }
        .fi-br-link-alt:before {
    content: "\f3ac";
    }
        .fi-br-link-horizontal-slash:before {
    content: "\f3ad";
    }
        .fi-br-link-horizontal:before {
    content: "\f3ae";
    }
        .fi-br-link-slash-alt:before {
    content: "\f3af";
    }
        .fi-br-link-slash:before {
    content: "\f3b0";
    }
        .fi-br-link:before {
    content: "\f3b1";
    }
        .fi-br-lipstick:before {
    content: "\f3b2";
    }
        .fi-br-lira-sign:before {
    content: "\f3b3";
    }
        .fi-br-list-check:before {
    content: "\f3b4";
    }
        .fi-br-list:before {
    content: "\f3b5";
    }
        .fi-br-loading:before {
    content: "\f3b6";
    }
        .fi-br-location-alt:before {
    content: "\f3b7";
    }
        .fi-br-lock-alt:before {
    content: "\f3b8";
    }
        .fi-br-lock:before {
    content: "\f3b9";
    }
        .fi-br-luchador:before {
    content: "\f3ba";
    }
        .fi-br-luggage-rolling:before {
    content: "\f3bb";
    }
        .fi-br-m:before {
    content: "\f3bc";
    }
        .fi-br-magic-wand:before {
    content: "\f3bd";
    }
        .fi-br-mailbox:before {
    content: "\f3be";
    }
        .fi-br-makeup-brush:before {
    content: "\f3bf";
    }
        .fi-br-male-couple:before {
    content: "\f3c0";
    }
        .fi-br-male:before {
    content: "\f3c1";
    }
        .fi-br-man-head:before {
    content: "\f3c2";
    }
        .fi-br-map-marker-cross:before {
    content: "\f3c3";
    }
        .fi-br-map-marker-home:before {
    content: "\f3c4";
    }
        .fi-br-map-marker-minus:before {
    content: "\f3c5";
    }
        .fi-br-map-marker-plus:before {
    content: "\f3c6";
    }
        .fi-br-map-marker:before {
    content: "\f3c7";
    }
        .fi-br-map:before {
    content: "\f3c8";
    }
        .fi-br-marker-time:before {
    content: "\f3c9";
    }
        .fi-br-marker:before {
    content: "\f3ca";
    }
        .fi-br-mask-carnival:before {
    content: "\f3cb";
    }
        .fi-br-medicine:before {
    content: "\f3cc";
    }
        .fi-br-megaphone:before {
    content: "\f3cd";
    }
        .fi-br-meh-blank:before {
    content: "\f3ce";
    }
        .fi-br-meh-rolling-eyes:before {
    content: "\f3cf";
    }
        .fi-br-melon:before {
    content: "\f3d0";
    }
        .fi-br-menu-burger:before {
    content: "\f3d1";
    }
        .fi-br-menu-dots-vertical:before {
    content: "\f3d2";
    }
        .fi-br-menu-dots:before {
    content: "\f3d3";
    }
        .fi-br-message-code:before {
    content: "\f3d4";
    }
        .fi-br-meteor:before {
    content: "\f3d5";
    }
        .fi-br-microphone-alt:before {
    content: "\f3d6";
    }
        .fi-br-microphone:before {
    content: "\f3d7";
    }
        .fi-br-mind-share:before {
    content: "\f3d8";
    }
        .fi-br-minus-small:before {
    content: "\f3d9";
    }
        .fi-br-minus:before {
    content: "\f3da";
    }
        .fi-br-mobile-button:before {
    content: "\f3db";
    }
        .fi-br-mobile-notch:before {
    content: "\f3dc";
    }
        .fi-br-mobile:before {
    content: "\f3dd";
    }
        .fi-br-mode-landscape:before {
    content: "\f3de";
    }
        .fi-br-mode-portrait:before {
    content: "\f3df";
    }
        .fi-br-money-bill-wave-alt:before {
    content: "\f3e0";
    }
        .fi-br-money-bill-wave:before {
    content: "\f3e1";
    }
        .fi-br-money-check-edit-alt:before {
    content: "\f3e2";
    }
        .fi-br-money-check-edit:before {
    content: "\f3e3";
    }
        .fi-br-money-check:before {
    content: "\f3e4";
    }
        .fi-br-money:before {
    content: "\f3e5";
    }
        .fi-br-moon-stars:before {
    content: "\f3e6";
    }
        .fi-br-moon:before {
    content: "\f3e7";
    }
        .fi-br-motorcycle:before {
    content: "\f3e8";
    }
        .fi-br-mountains:before {
    content: "\f3e9";
    }
        .fi-br-mouse:before {
    content: "\f3ea";
    }
        .fi-br-mug-alt:before {
    content: "\f3eb";
    }
        .fi-br-mug-hot-alt:before {
    content: "\f3ec";
    }
        .fi-br-mug-hot:before {
    content: "\f3ed";
    }
        .fi-br-mug-tea:before {
    content: "\f3ee";
    }
        .fi-br-mug:before {
    content: "\f3ef";
    }
        .fi-br-mushroom:before {
    content: "\f3f0";
    }
        .fi-br-music-alt:before {
    content: "\f3f1";
    }
        .fi-br-music-file:before {
    content: "\f3f2";
    }
        .fi-br-music:before {
    content: "\f3f3";
    }
        .fi-br-n:before {
    content: "\f3f4";
    }
        .fi-br-navigation:before {
    content: "\f3f5";
    }
        .fi-br-network-cloud:before {
    content: "\f3f6";
    }
        .fi-br-network:before {
    content: "\f3f7";
    }
        .fi-br-noodles:before {
    content: "\f3f8";
    }
        .fi-br-notebook:before {
    content: "\f3f9";
    }
        .fi-br-o:before {
    content: "\f3fa";
    }
        .fi-br-oil-can:before {
    content: "\f3fb";
    }
        .fi-br-oil-temp:before {
    content: "\f3fc";
    }
        .fi-br-olive-oil:before {
    content: "\f3fd";
    }
        .fi-br-olives:before {
    content: "\f3fe";
    }
        .fi-br-onion:before {
    content: "\f3ff";
    }
        .fi-br-opacity:before {
    content: "\f400";
    }
        .fi-br-overline:before {
    content: "\f401";
    }
        .fi-br-p:before {
    content: "\f402";
    }
        .fi-br-package:before {
    content: "\f403";
    }
        .fi-br-page-break:before {
    content: "\f404";
    }
        .fi-br-paint-brush:before {
    content: "\f405";
    }
        .fi-br-paint-roller:before {
    content: "\f406";
    }
        .fi-br-palette:before {
    content: "\f407";
    }
        .fi-br-pan:before {
    content: "\f408";
    }
        .fi-br-paper-plane:before {
    content: "\f409";
    }
        .fi-br-password:before {
    content: "\f40a";
    }
        .fi-br-pause:before {
    content: "\f40b";
    }
        .fi-br-paw:before {
    content: "\f40c";
    }
        .fi-br-peach:before {
    content: "\f40d";
    }
        .fi-br-pencil-ruler:before {
    content: "\f40e";
    }
        .fi-br-pencil:before {
    content: "\f40f";
    }
        .fi-br-pennant:before {
    content: "\f410";
    }
        .fi-br-people-poll:before {
    content: "\f411";
    }
        .fi-br-pepper-hot:before {
    content: "\f412";
    }
        .fi-br-pepper:before {
    content: "\f413";
    }
        .fi-br-percentage:before {
    content: "\f414";
    }
        .fi-br-pharmacy:before {
    content: "\f415";
    }
        .fi-br-phone-call:before {
    content: "\f416";
    }
        .fi-br-phone-cross:before {
    content: "\f417";
    }
        .fi-br-phone-office:before {
    content: "\f418";
    }
        .fi-br-phone-pause:before {
    content: "\f419";
    }
        .fi-br-phone-slash:before {
    content: "\f41a";
    }
        .fi-br-photo-film-music:before {
    content: "\f41b";
    }
        .fi-br-photo-video:before {
    content: "\f41c";
    }
        .fi-br-physics:before {
    content: "\f41d";
    }
        .fi-br-picnic:before {
    content: "\f41e";
    }
        .fi-br-picture:before {
    content: "\f41f";
    }
        .fi-br-pie:before {
    content: "\f420";
    }
        .fi-br-piggy-bank:before {
    content: "\f421";
    }
        .fi-br-pineapple:before {
    content: "\f422";
    }
        .fi-br-ping-pong:before {
    content: "\f423";
    }
        .fi-br-pizza-slice:before {
    content: "\f424";
    }
        .fi-br-plane-alt:before {
    content: "\f425";
    }
        .fi-br-plane:before {
    content: "\f426";
    }
        .fi-br-plate:before {
    content: "\f427";
    }
        .fi-br-play-alt:before {
    content: "\f428";
    }
        .fi-br-play-pause:before {
    content: "\f429";
    }
        .fi-br-play:before {
    content: "\f42a";
    }
        .fi-br-playing-cards:before {
    content: "\f42b";
    }
        .fi-br-plus-small:before {
    content: "\f42c";
    }
        .fi-br-plus:before {
    content: "\f42d";
    }
        .fi-br-podium-star:before {
    content: "\f42e";
    }
        .fi-br-podium:before {
    content: "\f42f";
    }
        .fi-br-poker-chip:before {
    content: "\f430";
    }
        .fi-br-poo:before {
    content: "\f431";
    }
        .fi-br-popcorn:before {
    content: "\f432";
    }
        .fi-br-portrait:before {
    content: "\f433";
    }
        .fi-br-pot:before {
    content: "\f434";
    }
        .fi-br-pound:before {
    content: "\f435";
    }
        .fi-br-power:before {
    content: "\f436";
    }
        .fi-br-presentation:before {
    content: "\f437";
    }
        .fi-br-print:before {
    content: "\f438";
    }
        .fi-br-protractor:before {
    content: "\f439";
    }
        .fi-br-pulse:before {
    content: "\f43a";
    }
        .fi-br-pumpkin:before {
    content: "\f43b";
    }
        .fi-br-puzzle-piece:before {
    content: "\f43c";
    }
        .fi-br-pyramid:before {
    content: "\f43d";
    }
        .fi-br-q:before {
    content: "\f43e";
    }
        .fi-br-qrcode:before {
    content: "\f43f";
    }
        .fi-br-question-square:before {
    content: "\f440";
    }
        .fi-br-question:before {
    content: "\f441";
    }
        .fi-br-quote-right:before {
    content: "\f442";
    }
        .fi-br-r:before {
    content: "\f443";
    }
        .fi-br-racquet:before {
    content: "\f444";
    }
        .fi-br-radish:before {
    content: "\f445";
    }
        .fi-br-rainbow:before {
    content: "\f446";
    }
        .fi-br-raindrops:before {
    content: "\f447";
    }
        .fi-br-rec:before {
    content: "\f448";
    }
        .fi-br-receipt:before {
    content: "\f449";
    }
        .fi-br-record-vinyl:before {
    content: "\f44a";
    }
        .fi-br-rectabgle-vertical:before {
    content: "\f44b";
    }
        .fi-br-rectangle-barcode:before {
    content: "\f44c";
    }
        .fi-br-rectangle-code:before {
    content: "\f44d";
    }
        .fi-br-rectangle-horizontal:before {
    content: "\f44e";
    }
        .fi-br-rectangle-panoramic:before {
    content: "\f44f";
    }
        .fi-br-rectangle-xmark:before {
    content: "\f450";
    }
        .fi-br-recycle:before {
    content: "\f451";
    }
        .fi-br-redo-alt:before {
    content: "\f452";
    }
        .fi-br-redo:before {
    content: "\f453";
    }
        .fi-br-reflect:before {
    content: "\f454";
    }
        .fi-br-refresh:before {
    content: "\f455";
    }
        .fi-br-registered:before {
    content: "\f456";
    }
        .fi-br-remove-user:before {
    content: "\f457";
    }
        .fi-br-reply-all:before {
    content: "\f458";
    }
        .fi-br-republican:before {
    content: "\f459";
    }
        .fi-br-resize:before {
    content: "\f45a";
    }
        .fi-br-resources:before {
    content: "\f45b";
    }
        .fi-br-restaurant:before {
    content: "\f45c";
    }
        .fi-br-rewind:before {
    content: "\f45d";
    }
        .fi-br-rhombus:before {
    content: "\f45e";
    }
        .fi-br-rings-wedding:before {
    content: "\f45f";
    }
        .fi-br-road:before {
    content: "\f460";
    }
        .fi-br-rocket-lunch:before {
    content: "\f461";
    }
        .fi-br-rocket:before {
    content: "\f462";
    }
        .fi-br-room-service:before {
    content: "\f463";
    }
        .fi-br-rotate-left:before {
    content: "\f464";
    }
        .fi-br-rotate-right:before {
    content: "\f465";
    }
        .fi-br-ruble-sign:before {
    content: "\f466";
    }
        .fi-br-rugby-helmet:before {
    content: "\f467";
    }
        .fi-br-rugby:before {
    content: "\f468";
    }
        .fi-br-ruler-combined:before {
    content: "\f469";
    }
        .fi-br-ruler-horizontal:before {
    content: "\f46a";
    }
        .fi-br-ruler-triangle:before {
    content: "\f46b";
    }
        .fi-br-ruler-vertical:before {
    content: "\f46c";
    }
        .fi-br-running:before {
    content: "\f46d";
    }
        .fi-br-rupee-sign:before {
    content: "\f46e";
    }
        .fi-br-rv:before {
    content: "\f46f";
    }
        .fi-br-s:before {
    content: "\f470";
    }
        .fi-br-sack-dollar:before {
    content: "\f471";
    }
        .fi-br-sack:before {
    content: "\f472";
    }
        .fi-br-sad-cry:before {
    content: "\f473";
    }
        .fi-br-sad-tear:before {
    content: "\f474";
    }
        .fi-br-sad:before {
    content: "\f475";
    }
        .fi-br-salad:before {
    content: "\f476";
    }
        .fi-br-salt-pepper:before {
    content: "\f477";
    }
        .fi-br-sandwich:before {
    content: "\f478";
    }
        .fi-br-sauce:before {
    content: "\f479";
    }
        .fi-br-sausage:before {
    content: "\f47a";
    }
        .fi-br-scale:before {
    content: "\f47b";
    }
        .fi-br-school-bus:before {
    content: "\f47c";
    }
        .fi-br-school:before {
    content: "\f47d";
    }
        .fi-br-scissors:before {
    content: "\f47e";
    }
        .fi-br-screen:before {
    content: "\f47f";
    }
        .fi-br-search-alt:before {
    content: "\f480";
    }
        .fi-br-search-dollar:before {
    content: "\f481";
    }
        .fi-br-search-heart:before {
    content: "\f482";
    }
        .fi-br-search-location:before {
    content: "\f483";
    }
        .fi-br-search:before {
    content: "\f484";
    }
        .fi-br-sensor-alert:before {
    content: "\f485";
    }
        .fi-br-sensor-fire:before {
    content: "\f486";
    }
        .fi-br-sensor-on:before {
    content: "\f487";
    }
        .fi-br-sensor-smoke:before {
    content: "\f488";
    }
        .fi-br-sensor:before {
    content: "\f489";
    }
        .fi-br-settings-sliders:before {
    content: "\f48a";
    }
        .fi-br-settings:before {
    content: "\f48b";
    }
        .fi-br-share:before {
    content: "\f48c";
    }
        .fi-br-shekel-sign:before {
    content: "\f48d";
    }
        .fi-br-shield-check:before {
    content: "\f48e";
    }
        .fi-br-shield-exclamation:before {
    content: "\f48f";
    }
        .fi-br-shield-interrogation:before {
    content: "\f490";
    }
        .fi-br-shield-plus:before {
    content: "\f491";
    }
        .fi-br-shield:before {
    content: "\f492";
    }
        .fi-br-ship-side:before {
    content: "\f493";
    }
        .fi-br-ship:before {
    content: "\f494";
    }
        .fi-br-shop:before {
    content: "\f495";
    }
        .fi-br-shopping-bag-add:before {
    content: "\f496";
    }
        .fi-br-shopping-bag:before {
    content: "\f497";
    }
        .fi-br-shopping-basket:before {
    content: "\f498";
    }
        .fi-br-shopping-cart-add:before {
    content: "\f499";
    }
        .fi-br-shopping-cart-check:before {
    content: "\f49a";
    }
        .fi-br-shopping-cart:before {
    content: "\f49b";
    }
        .fi-br-shrimp:before {
    content: "\f49c";
    }
        .fi-br-shuffle:before {
    content: "\f49d";
    }
        .fi-br-shuttle-van:before {
    content: "\f49e";
    }
        .fi-br-shuttlecock:before {
    content: "\f49f";
    }
        .fi-br-sidebar-flip:before {
    content: "\f4a0";
    }
        .fi-br-sidebar:before {
    content: "\f4a1";
    }
        .fi-br-sign-in-alt:before {
    content: "\f4a2";
    }
        .fi-br-sign-out-alt:before {
    content: "\f4a3";
    }
        .fi-br-signal-alt-1:before {
    content: "\f4a4";
    }
        .fi-br-signal-alt-2:before {
    content: "\f4a5";
    }
        .fi-br-signal-alt:before {
    content: "\f4a6";
    }
        .fi-br-skateboard:before {
    content: "\f4a7";
    }
        .fi-br-skating:before {
    content: "\f4a8";
    }
        .fi-br-skewer:before {
    content: "\f4a9";
    }
        .fi-br-ski-jump:before {
    content: "\f4aa";
    }
        .fi-br-ski-lift:before {
    content: "\f4ab";
    }
        .fi-br-skiing-nordic:before {
    content: "\f4ac";
    }
        .fi-br-skiing:before {
    content: "\f4ad";
    }
        .fi-br-sledding:before {
    content: "\f4ae";
    }
        .fi-br-sleigh:before {
    content: "\f4af";
    }
        .fi-br-smartphone:before {
    content: "\f4b0";
    }
        .fi-br-smile-beam:before {
    content: "\f4b1";
    }
        .fi-br-smog:before {
    content: "\f4b2";
    }
        .fi-br-smoke:before {
    content: "\f4b3";
    }
        .fi-br-snow-blowing:before {
    content: "\f4b4";
    }
        .fi-br-snowboarding:before {
    content: "\f4b5";
    }
        .fi-br-snowflake:before {
    content: "\f4b6";
    }
        .fi-br-snowflakes:before {
    content: "\f4b7";
    }
        .fi-br-snowmobile:before {
    content: "\f4b8";
    }
        .fi-br-snowplow:before {
    content: "\f4b9";
    }
        .fi-br-soap:before {
    content: "\f4ba";
    }
        .fi-br-social-network:before {
    content: "\f4bb";
    }
        .fi-br-sort-alpha-down-alt:before {
    content: "\f4bc";
    }
        .fi-br-sort-alpha-down:before {
    content: "\f4bd";
    }
        .fi-br-sort-alpha-up-alt:before {
    content: "\f4be";
    }
        .fi-br-sort-alpha-up:before {
    content: "\f4bf";
    }
        .fi-br-sort-alt:before {
    content: "\f4c0";
    }
        .fi-br-sort-amount-down-alt:before {
    content: "\f4c1";
    }
        .fi-br-sort-amount-down:before {
    content: "\f4c2";
    }
        .fi-br-sort-amount-up-alt:before {
    content: "\f4c3";
    }
        .fi-br-sort-amount-up:before {
    content: "\f4c4";
    }
        .fi-br-sort-down:before {
    content: "\f4c5";
    }
        .fi-br-sort-numeric-down-alt:before {
    content: "\f4c6";
    }
        .fi-br-sort-numeric-down:before {
    content: "\f4c7";
    }
        .fi-br-sort:before {
    content: "\f4c8";
    }
        .fi-br-soup:before {
    content: "\f4c9";
    }
        .fi-br-spa:before {
    content: "\f4ca";
    }
        .fi-br-space-shuttle:before {
    content: "\f4cb";
    }
        .fi-br-spade:before {
    content: "\f4cc";
    }
        .fi-br-sparkles:before {
    content: "\f4cd";
    }
        .fi-br-speaker:before {
    content: "\f4ce";
    }
        .fi-br-sphere:before {
    content: "\f4cf";
    }
        .fi-br-spinner:before {
    content: "\f4d0";
    }
        .fi-br-spoon:before {
    content: "\f4d1";
    }
        .fi-br-square-code:before {
    content: "\f4d2";
    }
        .fi-br-square-root:before {
    content: "\f4d3";
    }
        .fi-br-square-terminal:before {
    content: "\f4d4";
    }
        .fi-br-square:before {
    content: "\f4d5";
    }
        .fi-br-stamp:before {
    content: "\f4d6";
    }
        .fi-br-star-octogram:before {
    content: "\f4d7";
    }
        .fi-br-star:before {
    content: "\f4d8";
    }
        .fi-br-starfighter:before {
    content: "\f4d9";
    }
        .fi-br-stars:before {
    content: "\f4da";
    }
        .fi-br-stats:before {
    content: "\f4db";
    }
        .fi-br-steak:before {
    content: "\f4dc";
    }
        .fi-br-steering-wheel:before {
    content: "\f4dd";
    }
        .fi-br-stethoscope:before {
    content: "\f4de";
    }
        .fi-br-sticker:before {
    content: "\f4df";
    }
        .fi-br-stop:before {
    content: "\f4e0";
    }
        .fi-br-stopwatch:before {
    content: "\f4e1";
    }
        .fi-br-store-alt:before {
    content: "\f4e2";
    }
        .fi-br-strawberry:before {
    content: "\f4e3";
    }
        .fi-br-subtitles:before {
    content: "\f4e4";
    }
        .fi-br-subway:before {
    content: "\f4e5";
    }
        .fi-br-suitcase-alt:before {
    content: "\f4e6";
    }
        .fi-br-summer:before {
    content: "\f4e7";
    }
        .fi-br-sun:before {
    content: "\f4e8";
    }
        .fi-br-sunrise-alt:before {
    content: "\f4e9";
    }
        .fi-br-sunrise:before {
    content: "\f4ea";
    }
        .fi-br-sunset:before {
    content: "\f4eb";
    }
        .fi-br-surfing:before {
    content: "\f4ec";
    }
        .fi-br-surprise:before {
    content: "\f4ed";
    }
        .fi-br-sushi:before {
    content: "\f4ee";
    }
        .fi-br-swimmer:before {
    content: "\f4ef";
    }
        .fi-br-sword:before {
    content: "\f4f0";
    }
        .fi-br-symbol:before {
    content: "\f4f1";
    }
        .fi-br-syringe:before {
    content: "\f4f2";
    }
        .fi-br-t:before {
    content: "\f4f3";
    }
        .fi-br-table-columns:before {
    content: "\f4f4";
    }
        .fi-br-table-layout:before {
    content: "\f4f5";
    }
        .fi-br-table-pivot:before {
    content: "\f4f6";
    }
        .fi-br-table-rows:before {
    content: "\f4f7";
    }
        .fi-br-table-tree:before {
    content: "\f4f8";
    }
        .fi-br-tablet:before {
    content: "\f4f9";
    }
        .fi-br-tachometer-alt-average:before {
    content: "\f4fa";
    }
        .fi-br-tachometer-alt-fastest:before {
    content: "\f4fb";
    }
        .fi-br-tachometer-alt-slow:before {
    content: "\f4fc";
    }
        .fi-br-tachometer-alt-slowest:before {
    content: "\f4fd";
    }
        .fi-br-tachometer-average:before {
    content: "\f4fe";
    }
        .fi-br-tachometer-fast:before {
    content: "\f4ff";
    }
        .fi-br-tachometer-fastest:before {
    content: "\f500";
    }
        .fi-br-tachometer-slow:before {
    content: "\f501";
    }
        .fi-br-tachometer-slowest:before {
    content: "\f502";
    }
        .fi-br-tachometer:before {
    content: "\f503";
    }
        .fi-br-taco:before {
    content: "\f504";
    }
        .fi-br-tags:before {
    content: "\f505";
    }
        .fi-br-tally:before {
    content: "\f506";
    }
        .fi-br-target:before {
    content: "\f507";
    }
        .fi-br-taxi:before {
    content: "\f508";
    }
        .fi-br-temperature-down:before {
    content: "\f509";
    }
        .fi-br-temperature-frigid:before {
    content: "\f50a";
    }
        .fi-br-temperature-high:before {
    content: "\f50b";
    }
        .fi-br-temperature-low:before {
    content: "\f50c";
    }
        .fi-br-temperature-up:before {
    content: "\f50d";
    }
        .fi-br-tenge:before {
    content: "\f50e";
    }
        .fi-br-tennis:before {
    content: "\f50f";
    }
        .fi-br-terrace:before {
    content: "\f510";
    }
        .fi-br-test-tube:before {
    content: "\f511";
    }
        .fi-br-test:before {
    content: "\f512";
    }
        .fi-br-text-check:before {
    content: "\f513";
    }
        .fi-br-text-slash:before {
    content: "\f514";
    }
        .fi-br-text:before {
    content: "\f515";
    }
        .fi-br-thermometer-half:before {
    content: "\f516";
    }
        .fi-br-thumbtack:before {
    content: "\f517";
    }
        .fi-br-thunderstorm-moon:before {
    content: "\f518";
    }
        .fi-br-thunderstorm-sun:before {
    content: "\f519";
    }
        .fi-br-thunderstorm:before {
    content: "\f51a";
    }
        .fi-br-ticket:before {
    content: "\f51b";
    }
        .fi-br-time-add:before {
    content: "\f51c";
    }
        .fi-br-time-check:before {
    content: "\f51d";
    }
        .fi-br-time-delete:before {
    content: "\f51e";
    }
        .fi-br-time-fast:before {
    content: "\f51f";
    }
        .fi-br-time-forward-sixty:before {
    content: "\f520";
    }
        .fi-br-time-forward-ten:before {
    content: "\f521";
    }
        .fi-br-time-forward:before {
    content: "\f522";
    }
        .fi-br-time-half-past:before {
    content: "\f523";
    }
        .fi-br-time-oclock:before {
    content: "\f524";
    }
        .fi-br-time-past:before {
    content: "\f525";
    }
        .fi-br-time-quarter-past:before {
    content: "\f526";
    }
        .fi-br-time-quarter-to:before {
    content: "\f527";
    }
        .fi-br-time-twenty-four:before {
    content: "\f528";
    }
        .fi-br-tire-flat:before {
    content: "\f529";
    }
        .fi-br-tire-pressure-warning:before {
    content: "\f52a";
    }
        .fi-br-tire-rugged:before {
    content: "\f52b";
    }
        .fi-br-tire:before {
    content: "\f52c";
    }
        .fi-br-tired:before {
    content: "\f52d";
    }
        .fi-br-tomato:before {
    content: "\f52e";
    }
        .fi-br-tool-box:before {
    content: "\f52f";
    }
        .fi-br-tool-crop:before {
    content: "\f530";
    }
        .fi-br-tool-marquee:before {
    content: "\f531";
    }
        .fi-br-tooth:before {
    content: "\f532";
    }
        .fi-br-tornado:before {
    content: "\f533";
    }
        .fi-br-tractor:before {
    content: "\f534";
    }
        .fi-br-trailer:before {
    content: "\f535";
    }
        .fi-br-train-side:before {
    content: "\f536";
    }
        .fi-br-train:before {
    content: "\f537";
    }
        .fi-br-tram:before {
    content: "\f538";
    }
        .fi-br-transform:before {
    content: "\f539";
    }
        .fi-br-trash:before {
    content: "\f53a";
    }
        .fi-br-treasure-chest:before {
    content: "\f53b";
    }
        .fi-br-treatment:before {
    content: "\f53c";
    }
        .fi-br-tree-christmas:before {
    content: "\f53d";
    }
        .fi-br-tree:before {
    content: "\f53e";
    }
        .fi-br-triangle:before {
    content: "\f53f";
    }
        .fi-br-trophy:before {
    content: "\f540";
    }
        .fi-br-truck-container:before {
    content: "\f541";
    }
        .fi-br-truck-couch:before {
    content: "\f542";
    }
        .fi-br-truck-loading:before {
    content: "\f543";
    }
        .fi-br-truck-monster:before {
    content: "\f544";
    }
        .fi-br-truck-moving:before {
    content: "\f545";
    }
        .fi-br-truck-pickup:before {
    content: "\f546";
    }
        .fi-br-truck-plow:before {
    content: "\f547";
    }
        .fi-br-truck-ramp:before {
    content: "\f548";
    }
        .fi-br-truck-side:before {
    content: "\f549";
    }
        .fi-br-tty:before {
    content: "\f54a";
    }
        .fi-br-turkey:before {
    content: "\f54b";
    }
        .fi-br-tv-music:before {
    content: "\f54c";
    }
        .fi-br-typewriter:before {
    content: "\f54d";
    }
        .fi-br-u:before {
    content: "\f54e";
    }
        .fi-br-umbrella:before {
    content: "\f54f";
    }
        .fi-br-underline:before {
    content: "\f550";
    }
        .fi-br-undo-alt:before {
    content: "\f551";
    }
        .fi-br-undo:before {
    content: "\f552";
    }
        .fi-br-unlock:before {
    content: "\f553";
    }
        .fi-br-upload:before {
    content: "\f554";
    }
        .fi-br-usb-pendrive:before {
    content: "\f555";
    }
        .fi-br-usd-circle:before {
    content: "\f556";
    }
        .fi-br-usd-square:before {
    content: "\f557";
    }
        .fi-br-user-add:before {
    content: "\f558";
    }
        .fi-br-user-time:before {
    content: "\f559";
    }
        .fi-br-user:before {
    content: "\f55a";
    }
        .fi-br-users-alt:before {
    content: "\f55b";
    }
        .fi-br-users:before {
    content: "\f55c";
    }
        .fi-br-utensils:before {
    content: "\f55d";
    }
        .fi-br-v:before {
    content: "\f55e";
    }
        .fi-br-vector-alt:before {
    content: "\f55f";
    }
        .fi-br-vector:before {
    content: "\f560";
    }
        .fi-br-video-arrow-down-left:before {
    content: "\f561";
    }
        .fi-br-video-arrow-up-right:before {
    content: "\f562";
    }
        .fi-br-video-camera-alt:before {
    content: "\f563";
    }
        .fi-br-video-camera:before {
    content: "\f564";
    }
        .fi-br-video-plus:before {
    content: "\f565";
    }
        .fi-br-video-slash:before {
    content: "\f566";
    }
        .fi-br-volcano:before {
    content: "\f567";
    }
        .fi-br-volleyball:before {
    content: "\f568";
    }
        .fi-br-volume:before {
    content: "\f569";
    }
        .fi-br-w:before {
    content: "\f56a";
    }
        .fi-br-wagon-covered:before {
    content: "\f56b";
    }
        .fi-br-wallet:before {
    content: "\f56c";
    }
        .fi-br-water-bottle:before {
    content: "\f56d";
    }
        .fi-br-water-lower:before {
    content: "\f56e";
    }
        .fi-br-water-rise:before {
    content: "\f56f";
    }
        .fi-br-water:before {
    content: "\f570";
    }
        .fi-br-watermelon:before {
    content: "\f571";
    }
        .fi-br-wheat:before {
    content: "\f572";
    }
        .fi-br-wheelchair:before {
    content: "\f573";
    }
        .fi-br-whistle:before {
    content: "\f574";
    }
        .fi-br-wifi-alt:before {
    content: "\f575";
    }
        .fi-br-wind-warning:before {
    content: "\f576";
    }
        .fi-br-wind:before {
    content: "\f577";
    }
        .fi-br-windsock:before {
    content: "\f578";
    }
        .fi-br-wink:before {
    content: "\f579";
    }
        .fi-br-woman-head:before {
    content: "\f57a";
    }
        .fi-br-world:before {
    content: "\f57b";
    }
        .fi-br-wrench-simple:before {
    content: "\f57c";
    }
        .fi-br-x:before {
    content: "\f57d";
    }
        .fi-br-y:before {
    content: "\f57e";
    }
        .fi-br-yen:before {
    content: "\f57f";
    }
        .fi-br-z:before {
    content: "\f580";
    }
        .fi-br-zoom-in:before {
    content: "\f581";
    }
        .fi-br-zoom-out:before {
    content: "\f582";
    }

@font-face {
font-family: "uicons-regular-rounded";
src: url(/_next/static/media/uicons-regular-rounded.2af25bf3.eot#iefix) format("embedded-opentype"),
url(/_next/static/media/uicons-regular-rounded.6b3a8216.woff2) format("woff2"),
url(/_next/static/media/uicons-regular-rounded.dd3588e3.woff) format("woff");
}

    i[class^="fi-rr-"]:before, i[class*=" fi-rr-"]:before, span[class^="fi-rr-"]:before, span[class*="fi-rr-"]:before {
font-family: uicons-regular-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-rr-a:before {
    content: "\f101";
    }
        .fi-rr-ad:before {
    content: "\f102";
    }
        .fi-rr-add-document:before {
    content: "\f103";
    }
        .fi-rr-add-folder:before {
    content: "\f104";
    }
        .fi-rr-add:before {
    content: "\f105";
    }
        .fi-rr-address-book:before {
    content: "\f106";
    }
        .fi-rr-air-conditioner:before {
    content: "\f107";
    }
        .fi-rr-air-freshener:before {
    content: "\f108";
    }
        .fi-rr-alarm-clock:before {
    content: "\f109";
    }
        .fi-rr-alarm-exclamation:before {
    content: "\f10a";
    }
        .fi-rr-alarm-plus:before {
    content: "\f10b";
    }
        .fi-rr-alarm-snooze:before {
    content: "\f10c";
    }
        .fi-rr-align-justify:before {
    content: "\f10d";
    }
        .fi-rr-align-left:before {
    content: "\f10e";
    }
        .fi-rr-ambulance:before {
    content: "\f10f";
    }
        .fi-rr-angle-circle-down:before {
    content: "\f110";
    }
        .fi-rr-angle-circle-left:before {
    content: "\f111";
    }
        .fi-rr-angle-circle-right:before {
    content: "\f112";
    }
        .fi-rr-angle-circle-up:before {
    content: "\f113";
    }
        .fi-rr-angle-double-left:before {
    content: "\f114";
    }
        .fi-rr-angle-double-right:before {
    content: "\f115";
    }
        .fi-rr-angle-double-small-down:before {
    content: "\f116";
    }
        .fi-rr-angle-double-small-left:before {
    content: "\f117";
    }
        .fi-rr-angle-double-small-right:before {
    content: "\f118";
    }
        .fi-rr-angle-double-small-up:before {
    content: "\f119";
    }
        .fi-rr-angle-down:before {
    content: "\f11a";
    }
        .fi-rr-angle-left:before {
    content: "\f11b";
    }
        .fi-rr-angle-right:before {
    content: "\f11c";
    }
        .fi-rr-angle-small-down:before {
    content: "\f11d";
    }
        .fi-rr-angle-small-left:before {
    content: "\f11e";
    }
        .fi-rr-angle-small-right:before {
    content: "\f11f";
    }
        .fi-rr-angle-small-up:before {
    content: "\f120";
    }
        .fi-rr-angle-square-down:before {
    content: "\f121";
    }
        .fi-rr-angle-square-left:before {
    content: "\f122";
    }
        .fi-rr-angle-square-right:before {
    content: "\f123";
    }
        .fi-rr-angle-square-up:before {
    content: "\f124";
    }
        .fi-rr-angle-up:before {
    content: "\f125";
    }
        .fi-rr-angry:before {
    content: "\f126";
    }
        .fi-rr-apple-whole:before {
    content: "\f127";
    }
        .fi-rr-apps-add:before {
    content: "\f128";
    }
        .fi-rr-apps-delete:before {
    content: "\f129";
    }
        .fi-rr-apps-sort:before {
    content: "\f12a";
    }
        .fi-rr-apps:before {
    content: "\f12b";
    }
        .fi-rr-archive:before {
    content: "\f12c";
    }
        .fi-rr-arrow-alt-circle-down:before {
    content: "\f12d";
    }
        .fi-rr-arrow-alt-circle-left:before {
    content: "\f12e";
    }
        .fi-rr-arrow-alt-circle-right:before {
    content: "\f12f";
    }
        .fi-rr-arrow-alt-circle-up:before {
    content: "\f130";
    }
        .fi-rr-arrow-alt-down:before {
    content: "\f131";
    }
        .fi-rr-arrow-alt-from-bottom:before {
    content: "\f132";
    }
        .fi-rr-arrow-alt-from-left:before {
    content: "\f133";
    }
        .fi-rr-arrow-alt-from-right:before {
    content: "\f134";
    }
        .fi-rr-arrow-alt-from-top:before {
    content: "\f135";
    }
        .fi-rr-arrow-alt-left:before {
    content: "\f136";
    }
        .fi-rr-arrow-alt-right:before {
    content: "\f137";
    }
        .fi-rr-arrow-alt-square-down:before {
    content: "\f138";
    }
        .fi-rr-arrow-alt-square-left:before {
    content: "\f139";
    }
        .fi-rr-arrow-alt-square-right:before {
    content: "\f13a";
    }
        .fi-rr-arrow-alt-square-up:before {
    content: "\f13b";
    }
        .fi-rr-arrow-alt-to-bottom:before {
    content: "\f13c";
    }
        .fi-rr-arrow-alt-to-left:before {
    content: "\f13d";
    }
        .fi-rr-arrow-alt-to-right:before {
    content: "\f13e";
    }
        .fi-rr-arrow-alt-to-top:before {
    content: "\f13f";
    }
        .fi-rr-arrow-alt-up:before {
    content: "\f140";
    }
        .fi-rr-arrow-circle-down:before {
    content: "\f141";
    }
        .fi-rr-arrow-circle-left:before {
    content: "\f142";
    }
        .fi-rr-arrow-circle-right:before {
    content: "\f143";
    }
        .fi-rr-arrow-circle-up:before {
    content: "\f144";
    }
        .fi-rr-arrow-down:before {
    content: "\f145";
    }
        .fi-rr-arrow-from-bottom:before {
    content: "\f146";
    }
        .fi-rr-arrow-from-left:before {
    content: "\f147";
    }
        .fi-rr-arrow-from-right:before {
    content: "\f148";
    }
        .fi-rr-arrow-from-top:before {
    content: "\f149";
    }
        .fi-rr-arrow-left:before {
    content: "\f14a";
    }
        .fi-rr-arrow-right:before {
    content: "\f14b";
    }
        .fi-rr-arrow-small-down:before {
    content: "\f14c";
    }
        .fi-rr-arrow-small-left:before {
    content: "\f14d";
    }
        .fi-rr-arrow-small-right:before {
    content: "\f14e";
    }
        .fi-rr-arrow-small-up:before {
    content: "\f14f";
    }
        .fi-rr-arrow-square-down:before {
    content: "\f150";
    }
        .fi-rr-arrow-square-left:before {
    content: "\f151";
    }
        .fi-rr-arrow-square-right:before {
    content: "\f152";
    }
        .fi-rr-arrow-square-up:before {
    content: "\f153";
    }
        .fi-rr-arrow-to-bottom:before {
    content: "\f154";
    }
        .fi-rr-arrow-to-left:before {
    content: "\f155";
    }
        .fi-rr-arrow-to-right:before {
    content: "\f156";
    }
        .fi-rr-arrow-to-top:before {
    content: "\f157";
    }
        .fi-rr-arrow-trend-down:before {
    content: "\f158";
    }
        .fi-rr-arrow-trend-up:before {
    content: "\f159";
    }
        .fi-rr-arrow-up-from-square:before {
    content: "\f15a";
    }
        .fi-rr-arrow-up-left-from-circle:before {
    content: "\f15b";
    }
        .fi-rr-arrow-up-left:before {
    content: "\f15c";
    }
        .fi-rr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f15d";
    }
        .fi-rr-arrow-up-right-from-square:before {
    content: "\f15e";
    }
        .fi-rr-arrow-up-right:before {
    content: "\f15f";
    }
        .fi-rr-arrow-up-square-triangle:before {
    content: "\f160";
    }
        .fi-rr-arrow-up-to-dotted-line:before {
    content: "\f161";
    }
        .fi-rr-arrow-up:before {
    content: "\f162";
    }
        .fi-rr-arrows-alt-h:before {
    content: "\f163";
    }
        .fi-rr-arrows-alt-v:before {
    content: "\f164";
    }
        .fi-rr-arrows-alt:before {
    content: "\f165";
    }
        .fi-rr-arrows-cross:before {
    content: "\f166";
    }
        .fi-rr-arrows-from-dotted-line:before {
    content: "\f167";
    }
        .fi-rr-arrows-from-line:before {
    content: "\f168";
    }
        .fi-rr-arrows-h-copy:before {
    content: "\f169";
    }
        .fi-rr-arrows-h:before {
    content: "\f16a";
    }
        .fi-rr-arrows-repeat-1:before {
    content: "\f16b";
    }
        .fi-rr-arrows-repeat:before {
    content: "\f16c";
    }
        .fi-rr-arrows-to-dotted-line:before {
    content: "\f16d";
    }
        .fi-rr-arrows-to-line:before {
    content: "\f16e";
    }
        .fi-rr-arrows:before {
    content: "\f16f";
    }
        .fi-rr-assept-document:before {
    content: "\f170";
    }
        .fi-rr-assistive-listening-systems:before {
    content: "\f171";
    }
        .fi-rr-asterik:before {
    content: "\f172";
    }
        .fi-rr-at:before {
    content: "\f173";
    }
        .fi-rr-aubergine:before {
    content: "\f174";
    }
        .fi-rr-avocado:before {
    content: "\f175";
    }
        .fi-rr-b:before {
    content: "\f176";
    }
        .fi-rr-baby-carriage:before {
    content: "\f177";
    }
        .fi-rr-backpack:before {
    content: "\f178";
    }
        .fi-rr-bacon:before {
    content: "\f179";
    }
        .fi-rr-badge-check:before {
    content: "\f17a";
    }
        .fi-rr-badge-dollar:before {
    content: "\f17b";
    }
        .fi-rr-badge-percent:before {
    content: "\f17c";
    }
        .fi-rr-badge-sheriff:before {
    content: "\f17d";
    }
        .fi-rr-badge:before {
    content: "\f17e";
    }
        .fi-rr-bags-shopping:before {
    content: "\f17f";
    }
        .fi-rr-bahai:before {
    content: "\f180";
    }
        .fi-rr-balance-scale-left:before {
    content: "\f181";
    }
        .fi-rr-balance-scale-right:before {
    content: "\f182";
    }
        .fi-rr-ball:before {
    content: "\f183";
    }
        .fi-rr-balloons:before {
    content: "\f184";
    }
        .fi-rr-ballot:before {
    content: "\f185";
    }
        .fi-rr-ban:before {
    content: "\f186";
    }
        .fi-rr-band-aid:before {
    content: "\f187";
    }
        .fi-rr-bank:before {
    content: "\f188";
    }
        .fi-rr-barber-shop:before {
    content: "\f189";
    }
        .fi-rr-barcode-read:before {
    content: "\f18a";
    }
        .fi-rr-barcode-scan:before {
    content: "\f18b";
    }
        .fi-rr-barcode:before {
    content: "\f18c";
    }
        .fi-rr-bars-progress:before {
    content: "\f18d";
    }
        .fi-rr-baseball-alt:before {
    content: "\f18e";
    }
        .fi-rr-baseball:before {
    content: "\f18f";
    }
        .fi-rr-basket:before {
    content: "\f190";
    }
        .fi-rr-basketball:before {
    content: "\f191";
    }
        .fi-rr-bed-alt:before {
    content: "\f192";
    }
        .fi-rr-bed:before {
    content: "\f193";
    }
        .fi-rr-beer:before {
    content: "\f194";
    }
        .fi-rr-bell-ring:before {
    content: "\f195";
    }
        .fi-rr-bell-school:before {
    content: "\f196";
    }
        .fi-rr-bell:before {
    content: "\f197";
    }
        .fi-rr-bike:before {
    content: "\f198";
    }
        .fi-rr-biking-mountain:before {
    content: "\f199";
    }
        .fi-rr-biking:before {
    content: "\f19a";
    }
        .fi-rr-billiard:before {
    content: "\f19b";
    }
        .fi-rr-blinds-open:before {
    content: "\f19c";
    }
        .fi-rr-blinds-raised:before {
    content: "\f19d";
    }
        .fi-rr-blinds:before {
    content: "\f19e";
    }
        .fi-rr-bold:before {
    content: "\f19f";
    }
        .fi-rr-bolt:before {
    content: "\f1a0";
    }
        .fi-rr-book-alt:before {
    content: "\f1a1";
    }
        .fi-rr-book-arrow-right:before {
    content: "\f1a2";
    }
        .fi-rr-book-arrow-up:before {
    content: "\f1a3";
    }
        .fi-rr-book-bookmark:before {
    content: "\f1a4";
    }
        .fi-rr-book-open-cover:before {
    content: "\f1a5";
    }
        .fi-rr-book-open-reader:before {
    content: "\f1a6";
    }
        .fi-rr-book:before {
    content: "\f1a7";
    }
        .fi-rr-bookmark:before {
    content: "\f1a8";
    }
        .fi-rr-books:before {
    content: "\f1a9";
    }
        .fi-rr-bottle:before {
    content: "\f1aa";
    }
        .fi-rr-bow-arrow:before {
    content: "\f1ab";
    }
        .fi-rr-bowling-ball:before {
    content: "\f1ac";
    }
        .fi-rr-bowling-pins:before {
    content: "\f1ad";
    }
        .fi-rr-bowling:before {
    content: "\f1ae";
    }
        .fi-rr-box-alt:before {
    content: "\f1af";
    }
        .fi-rr-box-ballot:before {
    content: "\f1b0";
    }
        .fi-rr-box-check:before {
    content: "\f1b1";
    }
        .fi-rr-box-tissue:before {
    content: "\f1b2";
    }
        .fi-rr-box:before {
    content: "\f1b3";
    }
        .fi-rr-boxes:before {
    content: "\f1b4";
    }
        .fi-rr-boxing-glove:before {
    content: "\f1b5";
    }
        .fi-rr-braille:before {
    content: "\f1b6";
    }
        .fi-rr-brain-circuit:before {
    content: "\f1b7";
    }
        .fi-rr-bread-slice:before {
    content: "\f1b8";
    }
        .fi-rr-bread:before {
    content: "\f1b9";
    }
        .fi-rr-briefcase:before {
    content: "\f1ba";
    }
        .fi-rr-broccoli:before {
    content: "\f1bb";
    }
        .fi-rr-broom:before {
    content: "\f1bc";
    }
        .fi-rr-browser:before {
    content: "\f1bd";
    }
        .fi-rr-brush:before {
    content: "\f1be";
    }
        .fi-rr-bug:before {
    content: "\f1bf";
    }
        .fi-rr-building:before {
    content: "\f1c0";
    }
        .fi-rr-bulb:before {
    content: "\f1c1";
    }
        .fi-rr-bullseye-pointer:before {
    content: "\f1c2";
    }
        .fi-rr-bullseye:before {
    content: "\f1c3";
    }
        .fi-rr-burrito:before {
    content: "\f1c4";
    }
        .fi-rr-bus-alt:before {
    content: "\f1c5";
    }
        .fi-rr-bus:before {
    content: "\f1c6";
    }
        .fi-rr-business-time:before {
    content: "\f1c7";
    }
        .fi-rr-butterfly:before {
    content: "\f1c8";
    }
        .fi-rr-c:before {
    content: "\f1c9";
    }
        .fi-rr-cake-birthday:before {
    content: "\f1ca";
    }
        .fi-rr-cake-wedding:before {
    content: "\f1cb";
    }
        .fi-rr-calculator:before {
    content: "\f1cc";
    }
        .fi-rr-calendar-check:before {
    content: "\f1cd";
    }
        .fi-rr-calendar-clock:before {
    content: "\f1ce";
    }
        .fi-rr-calendar-exclamation:before {
    content: "\f1cf";
    }
        .fi-rr-calendar-lines-pen:before {
    content: "\f1d0";
    }
        .fi-rr-calendar-lines:before {
    content: "\f1d1";
    }
        .fi-rr-calendar-minus:before {
    content: "\f1d2";
    }
        .fi-rr-calendar-pen:before {
    content: "\f1d3";
    }
        .fi-rr-calendar-plus:before {
    content: "\f1d4";
    }
        .fi-rr-calendar:before {
    content: "\f1d5";
    }
        .fi-rr-calendars:before {
    content: "\f1d6";
    }
        .fi-rr-call-history:before {
    content: "\f1d7";
    }
        .fi-rr-call-incoming:before {
    content: "\f1d8";
    }
        .fi-rr-call-missed:before {
    content: "\f1d9";
    }
        .fi-rr-call-outgoing:before {
    content: "\f1da";
    }
        .fi-rr-camcorder:before {
    content: "\f1db";
    }
        .fi-rr-camera:before {
    content: "\f1dc";
    }
        .fi-rr-camping:before {
    content: "\f1dd";
    }
        .fi-rr-candy-alt:before {
    content: "\f1de";
    }
        .fi-rr-candy:before {
    content: "\f1df";
    }
        .fi-rr-canned-food:before {
    content: "\f1e0";
    }
        .fi-rr-car-alt:before {
    content: "\f1e1";
    }
        .fi-rr-car-battery:before {
    content: "\f1e2";
    }
        .fi-rr-car-bolt:before {
    content: "\f1e3";
    }
        .fi-rr-car-building:before {
    content: "\f1e4";
    }
        .fi-rr-car-bump:before {
    content: "\f1e5";
    }
        .fi-rr-car-bus:before {
    content: "\f1e6";
    }
        .fi-rr-car-circle-bolt:before {
    content: "\f1e7";
    }
        .fi-rr-car-crash:before {
    content: "\f1e8";
    }
        .fi-rr-car-garage:before {
    content: "\f1e9";
    }
        .fi-rr-car-mechanic:before {
    content: "\f1ea";
    }
        .fi-rr-car-side-bolt:before {
    content: "\f1eb";
    }
        .fi-rr-car-side:before {
    content: "\f1ec";
    }
        .fi-rr-car-tilt:before {
    content: "\f1ed";
    }
        .fi-rr-car-wash:before {
    content: "\f1ee";
    }
        .fi-rr-car:before {
    content: "\f1ef";
    }
        .fi-rr-caravan-alt:before {
    content: "\f1f0";
    }
        .fi-rr-caravan:before {
    content: "\f1f1";
    }
        .fi-rr-caret-circle-down:before {
    content: "\f1f2";
    }
        .fi-rr-caret-circle-right:before {
    content: "\f1f3";
    }
        .fi-rr-caret-circle-up:before {
    content: "\f1f4";
    }
        .fi-rr-caret-down:before {
    content: "\f1f5";
    }
        .fi-rr-caret-left:before {
    content: "\f1f6";
    }
        .fi-rr-caret-quare-up:before {
    content: "\f1f7";
    }
        .fi-rr-caret-right:before {
    content: "\f1f8";
    }
        .fi-rr-caret-square-down:before {
    content: "\f1f9";
    }
        .fi-rr-caret-square-left_1:before {
    content: "\f1fa";
    }
        .fi-rr-caret-square-left:before {
    content: "\f1fb";
    }
        .fi-rr-caret-square-right:before {
    content: "\f1fc";
    }
        .fi-rr-caret-up:before {
    content: "\f1fd";
    }
        .fi-rr-carrot:before {
    content: "\f1fe";
    }
        .fi-rr-cars:before {
    content: "\f1ff";
    }
        .fi-rr-cash-register:before {
    content: "\f200";
    }
        .fi-rr-chair-office:before {
    content: "\f201";
    }
        .fi-rr-chair:before {
    content: "\f202";
    }
        .fi-rr-charging-station:before {
    content: "\f203";
    }
        .fi-rr-chart-area:before {
    content: "\f204";
    }
        .fi-rr-chart-connected:before {
    content: "\f205";
    }
        .fi-rr-chart-histogram:before {
    content: "\f206";
    }
        .fi-rr-chart-line-up:before {
    content: "\f207";
    }
        .fi-rr-chart-network:before {
    content: "\f208";
    }
        .fi-rr-chart-pie-alt:before {
    content: "\f209";
    }
        .fi-rr-chart-pie:before {
    content: "\f20a";
    }
        .fi-rr-chart-pyramid:before {
    content: "\f20b";
    }
        .fi-rr-chart-scatter:before {
    content: "\f20c";
    }
        .fi-rr-chart-set-theory:before {
    content: "\f20d";
    }
        .fi-rr-chart-tree:before {
    content: "\f20e";
    }
        .fi-rr-chat-arrow-down:before {
    content: "\f20f";
    }
        .fi-rr-chat-arrow-grow:before {
    content: "\f210";
    }
        .fi-rr-check:before {
    content: "\f211";
    }
        .fi-rr-checkbox:before {
    content: "\f212";
    }
        .fi-rr-cheese:before {
    content: "\f213";
    }
        .fi-rr-cherry:before {
    content: "\f214";
    }
        .fi-rr-chess-bishop:before {
    content: "\f215";
    }
        .fi-rr-chess-board:before {
    content: "\f216";
    }
        .fi-rr-chess-clock-alt:before {
    content: "\f217";
    }
        .fi-rr-chess-clock:before {
    content: "\f218";
    }
        .fi-rr-chess-king-alt:before {
    content: "\f219";
    }
        .fi-rr-chess-king:before {
    content: "\f21a";
    }
        .fi-rr-chess-knight-alt:before {
    content: "\f21b";
    }
        .fi-rr-chess-knight:before {
    content: "\f21c";
    }
        .fi-rr-chess-pawn-alt:before {
    content: "\f21d";
    }
        .fi-rr-chess-piece:before {
    content: "\f21e";
    }
        .fi-rr-chess-queen-alt:before {
    content: "\f21f";
    }
        .fi-rr-chess-queen:before {
    content: "\f220";
    }
        .fi-rr-chess-rook-alt:before {
    content: "\f221";
    }
        .fi-rr-chess-rook:before {
    content: "\f222";
    }
        .fi-rr-chess:before {
    content: "\f223";
    }
        .fi-rr-chevron-double-down:before {
    content: "\f224";
    }
        .fi-rr-chevron-double-up:before {
    content: "\f225";
    }
        .fi-rr-child-head:before {
    content: "\f226";
    }
        .fi-rr-chocolate:before {
    content: "\f227";
    }
        .fi-rr-circle-envelope:before {
    content: "\f228";
    }
        .fi-rr-circle-phone-flip:before {
    content: "\f229";
    }
        .fi-rr-circle-phone-hangup:before {
    content: "\f22a";
    }
        .fi-rr-circle-phone:before {
    content: "\f22b";
    }
        .fi-rr-circle-small:before {
    content: "\f22c";
    }
        .fi-rr-circle:before {
    content: "\f22d";
    }
        .fi-rr-clip:before {
    content: "\f22e";
    }
        .fi-rr-clipboard-list-check:before {
    content: "\f22f";
    }
        .fi-rr-clipboard-list:before {
    content: "\f230";
    }
        .fi-rr-clock-eight-thirty:before {
    content: "\f231";
    }
        .fi-rr-clock-eleven-thirty:before {
    content: "\f232";
    }
        .fi-rr-clock-eleven:before {
    content: "\f233";
    }
        .fi-rr-clock-five-thirty:before {
    content: "\f234";
    }
        .fi-rr-clock-five:before {
    content: "\f235";
    }
        .fi-rr-clock-four-thirty:before {
    content: "\f236";
    }
        .fi-rr-clock-nine-thirty:before {
    content: "\f237";
    }
        .fi-rr-clock-nine:before {
    content: "\f238";
    }
        .fi-rr-clock-one-thirty:before {
    content: "\f239";
    }
        .fi-rr-clock-one:before {
    content: "\f23a";
    }
        .fi-rr-clock-seven-thirty:before {
    content: "\f23b";
    }
        .fi-rr-clock-seven:before {
    content: "\f23c";
    }
        .fi-rr-clock-six-thirty:before {
    content: "\f23d";
    }
        .fi-rr-clock-six:before {
    content: "\f23e";
    }
        .fi-rr-clock-ten-thirty:before {
    content: "\f23f";
    }
        .fi-rr-clock-ten:before {
    content: "\f240";
    }
        .fi-rr-clock-three-thirty:before {
    content: "\f241";
    }
        .fi-rr-clock-three:before {
    content: "\f242";
    }
        .fi-rr-clock-twelve-thirty:before {
    content: "\f243";
    }
        .fi-rr-clock-twelve:before {
    content: "\f244";
    }
        .fi-rr-clock-two-thirty:before {
    content: "\f245";
    }
        .fi-rr-clock-two:before {
    content: "\f246";
    }
        .fi-rr-clock:before {
    content: "\f247";
    }
        .fi-rr-cloud-check:before {
    content: "\f248";
    }
        .fi-rr-cloud-disabled:before {
    content: "\f249";
    }
        .fi-rr-cloud-download-alt:before {
    content: "\f24a";
    }
        .fi-rr-cloud-download:before {
    content: "\f24b";
    }
        .fi-rr-cloud-drizzle:before {
    content: "\f24c";
    }
        .fi-rr-cloud-hail-mixed:before {
    content: "\f24d";
    }
        .fi-rr-cloud-hail:before {
    content: "\f24e";
    }
        .fi-rr-cloud-moon-rain:before {
    content: "\f24f";
    }
        .fi-rr-cloud-moon:before {
    content: "\f250";
    }
        .fi-rr-cloud-rain:before {
    content: "\f251";
    }
        .fi-rr-cloud-rainbow:before {
    content: "\f252";
    }
        .fi-rr-cloud-share:before {
    content: "\f253";
    }
        .fi-rr-cloud-showers-heavy:before {
    content: "\f254";
    }
        .fi-rr-cloud-showers:before {
    content: "\f255";
    }
        .fi-rr-cloud-sleet:before {
    content: "\f256";
    }
        .fi-rr-cloud-snow:before {
    content: "\f257";
    }
        .fi-rr-cloud-sun-rain:before {
    content: "\f258";
    }
        .fi-rr-cloud-sun:before {
    content: "\f259";
    }
        .fi-rr-cloud-upload-alt:before {
    content: "\f25a";
    }
        .fi-rr-cloud-upload:before {
    content: "\f25b";
    }
        .fi-rr-cloud:before {
    content: "\f25c";
    }
        .fi-rr-clouds-moon:before {
    content: "\f25d";
    }
        .fi-rr-clouds-sun:before {
    content: "\f25e";
    }
        .fi-rr-clouds:before {
    content: "\f25f";
    }
        .fi-rr-club:before {
    content: "\f260";
    }
        .fi-rr-cocktail-alt:before {
    content: "\f261";
    }
        .fi-rr-cocktail:before {
    content: "\f262";
    }
        .fi-rr-code-branch:before {
    content: "\f263";
    }
        .fi-rr-code-commit:before {
    content: "\f264";
    }
        .fi-rr-code-compare:before {
    content: "\f265";
    }
        .fi-rr-code-fork:before {
    content: "\f266";
    }
        .fi-rr-code-merge:before {
    content: "\f267";
    }
        .fi-rr-code-pull-request-closed:before {
    content: "\f268";
    }
        .fi-rr-code-pull-request-draft:before {
    content: "\f269";
    }
        .fi-rr-code-pull-request:before {
    content: "\f26a";
    }
        .fi-rr-code-simple:before {
    content: "\f26b";
    }
        .fi-rr-coffee-pot:before {
    content: "\f26c";
    }
        .fi-rr-coffee:before {
    content: "\f26d";
    }
        .fi-rr-coin:before {
    content: "\f26e";
    }
        .fi-rr-coins:before {
    content: "\f26f";
    }
        .fi-rr-comet:before {
    content: "\f270";
    }
        .fi-rr-comment-alt-middle-top:before {
    content: "\f271";
    }
        .fi-rr-comment-alt-middle:before {
    content: "\f272";
    }
        .fi-rr-comment-alt:before {
    content: "\f273";
    }
        .fi-rr-comment-arrow-down:before {
    content: "\f274";
    }
        .fi-rr-comment-arrow-up-right:before {
    content: "\f275";
    }
        .fi-rr-comment-arrow-up:before {
    content: "\f276";
    }
        .fi-rr-comment-check:before {
    content: "\f277";
    }
        .fi-rr-comment-code:before {
    content: "\f278";
    }
        .fi-rr-comment-dollar:before {
    content: "\f279";
    }
        .fi-rr-comment-exclamation:before {
    content: "\f27a";
    }
        .fi-rr-comment-heart:before {
    content: "\f27b";
    }
        .fi-rr-comment-image:before {
    content: "\f27c";
    }
        .fi-rr-comment-info:before {
    content: "\f27d";
    }
        .fi-rr-comment-pen:before {
    content: "\f27e";
    }
        .fi-rr-comment-question:before {
    content: "\f27f";
    }
        .fi-rr-comment-quote:before {
    content: "\f280";
    }
        .fi-rr-comment-slash:before {
    content: "\f281";
    }
        .fi-rr-comment-sms:before {
    content: "\f282";
    }
        .fi-rr-comment-text:before {
    content: "\f283";
    }
        .fi-rr-comment-user:before {
    content: "\f284";
    }
        .fi-rr-comment-xmark:before {
    content: "\f285";
    }
        .fi-rr-comment:before {
    content: "\f286";
    }
        .fi-rr-comments-dollar:before {
    content: "\f287";
    }
        .fi-rr-comments-question-check:before {
    content: "\f288";
    }
        .fi-rr-comments-question:before {
    content: "\f289";
    }
        .fi-rr-comments:before {
    content: "\f28a";
    }
        .fi-rr-compress-alt:before {
    content: "\f28b";
    }
        .fi-rr-compress:before {
    content: "\f28c";
    }
        .fi-rr-computer:before {
    content: "\f28d";
    }
        .fi-rr-confetti:before {
    content: "\f28e";
    }
        .fi-rr-cookie:before {
    content: "\f28f";
    }
        .fi-rr-copy-alt:before {
    content: "\f290";
    }
        .fi-rr-copy:before {
    content: "\f291";
    }
        .fi-rr-copyright:before {
    content: "\f292";
    }
        .fi-rr-corn:before {
    content: "\f293";
    }
        .fi-rr-cow:before {
    content: "\f294";
    }
        .fi-rr-cream:before {
    content: "\f295";
    }
        .fi-rr-credit-card:before {
    content: "\f296";
    }
        .fi-rr-cricket:before {
    content: "\f297";
    }
        .fi-rr-croissant:before {
    content: "\f298";
    }
        .fi-rr-cross-circle:before {
    content: "\f299";
    }
        .fi-rr-cross-small:before {
    content: "\f29a";
    }
        .fi-rr-cross:before {
    content: "\f29b";
    }
        .fi-rr-crown:before {
    content: "\f29c";
    }
        .fi-rr-crystal-ball:before {
    content: "\f29d";
    }
        .fi-rr-cube:before {
    content: "\f29e";
    }
        .fi-rr-cupcake:before {
    content: "\f29f";
    }
        .fi-rr-curling:before {
    content: "\f2a0";
    }
        .fi-rr-cursor-finger:before {
    content: "\f2a1";
    }
        .fi-rr-cursor-plus:before {
    content: "\f2a2";
    }
        .fi-rr-cursor-text-alt:before {
    content: "\f2a3";
    }
        .fi-rr-cursor-text:before {
    content: "\f2a4";
    }
        .fi-rr-cursor:before {
    content: "\f2a5";
    }
        .fi-rr-d:before {
    content: "\f2a6";
    }
        .fi-rr-dart:before {
    content: "\f2a7";
    }
        .fi-rr-dashboard:before {
    content: "\f2a8";
    }
        .fi-rr-data-transfer:before {
    content: "\f2a9";
    }
        .fi-rr-database:before {
    content: "\f2aa";
    }
        .fi-rr-delete-document:before {
    content: "\f2ab";
    }
        .fi-rr-delete-user:before {
    content: "\f2ac";
    }
        .fi-rr-delete:before {
    content: "\f2ad";
    }
        .fi-rr-democrat:before {
    content: "\f2ae";
    }
        .fi-rr-dewpoint:before {
    content: "\f2af";
    }
        .fi-rr-diagram-project:before {
    content: "\f2b0";
    }
        .fi-rr-diamond:before {
    content: "\f2b1";
    }
        .fi-rr-dice-alt:before {
    content: "\f2b2";
    }
        .fi-rr-dice-d10:before {
    content: "\f2b3";
    }
        .fi-rr-dice-d12:before {
    content: "\f2b4";
    }
        .fi-rr-dice-d20:before {
    content: "\f2b5";
    }
        .fi-rr-dice-d4:before {
    content: "\f2b6";
    }
        .fi-rr-dice-d6:before {
    content: "\f2b7";
    }
        .fi-rr-dice-d8:before {
    content: "\f2b8";
    }
        .fi-rr-dice-four:before {
    content: "\f2b9";
    }
        .fi-rr-dice-one:before {
    content: "\f2ba";
    }
        .fi-rr-dice-six:before {
    content: "\f2bb";
    }
        .fi-rr-dice-three:before {
    content: "\f2bc";
    }
        .fi-rr-dice-two:before {
    content: "\f2bd";
    }
        .fi-rr-dice:before {
    content: "\f2be";
    }
        .fi-rr-diploma:before {
    content: "\f2bf";
    }
        .fi-rr-disco-ball:before {
    content: "\f2c0";
    }
        .fi-rr-disk:before {
    content: "\f2c1";
    }
        .fi-rr-display-code:before {
    content: "\f2c2";
    }
        .fi-rr-dizzy:before {
    content: "\f2c3";
    }
        .fi-rr-doctor:before {
    content: "\f2c4";
    }
        .fi-rr-document-signed:before {
    content: "\f2c5";
    }
        .fi-rr-document:before {
    content: "\f2c6";
    }
        .fi-rr-dollar:before {
    content: "\f2c7";
    }
        .fi-rr-donate:before {
    content: "\f2c8";
    }
        .fi-rr-down-left-and-up-right-to-center:before {
    content: "\f2c9";
    }
        .fi-rr-download:before {
    content: "\f2ca";
    }
        .fi-rr-drafting-compass:before {
    content: "\f2cb";
    }
        .fi-rr-dreidel:before {
    content: "\f2cc";
    }
        .fi-rr-drink-alt:before {
    content: "\f2cd";
    }
        .fi-rr-drumstick:before {
    content: "\f2ce";
    }
        .fi-rr-duplicate:before {
    content: "\f2cf";
    }
        .fi-rr-e-learning:before {
    content: "\f2d0";
    }
        .fi-rr-e:before {
    content: "\f2d1";
    }
        .fi-rr-earnings:before {
    content: "\f2d2";
    }
        .fi-rr-eclipse-alt:before {
    content: "\f2d3";
    }
        .fi-rr-eclipse:before {
    content: "\f2d4";
    }
        .fi-rr-edit-alt:before {
    content: "\f2d5";
    }
        .fi-rr-edit:before {
    content: "\f2d6";
    }
        .fi-rr-egg-fried:before {
    content: "\f2d7";
    }
        .fi-rr-egg:before {
    content: "\f2d8";
    }
        .fi-rr-engine-warning:before {
    content: "\f2d9";
    }
        .fi-rr-enter:before {
    content: "\f2da";
    }
        .fi-rr-envelope-ban:before {
    content: "\f2db";
    }
        .fi-rr-envelope-bulk:before {
    content: "\f2dc";
    }
        .fi-rr-envelope-download:before {
    content: "\f2dd";
    }
        .fi-rr-envelope-marker:before {
    content: "\f2de";
    }
        .fi-rr-envelope-open-dollar:before {
    content: "\f2df";
    }
        .fi-rr-envelope-open-text:before {
    content: "\f2e0";
    }
        .fi-rr-envelope-open:before {
    content: "\f2e1";
    }
        .fi-rr-envelope-plus:before {
    content: "\f2e2";
    }
        .fi-rr-envelope:before {
    content: "\f2e3";
    }
        .fi-rr-equality:before {
    content: "\f2e4";
    }
        .fi-rr-euro:before {
    content: "\f2e5";
    }
        .fi-rr-exchange-alt:before {
    content: "\f2e6";
    }
        .fi-rr-exchange:before {
    content: "\f2e7";
    }
        .fi-rr-exclamation:before {
    content: "\f2e8";
    }
        .fi-rr-exit:before {
    content: "\f2e9";
    }
        .fi-rr-expand-arrows-alt:before {
    content: "\f2ea";
    }
        .fi-rr-expand-arrows:before {
    content: "\f2eb";
    }
        .fi-rr-expand:before {
    content: "\f2ec";
    }
        .fi-rr-eye-crossed:before {
    content: "\f2ed";
    }
        .fi-rr-eye-dropper:before {
    content: "\f2ee";
    }
        .fi-rr-eye:before {
    content: "\f2ef";
    }
        .fi-rr-f:before {
    content: "\f2f0";
    }
        .fi-rr-feather:before {
    content: "\f2f1";
    }
        .fi-rr-ferris-wheel:before {
    content: "\f2f2";
    }
        .fi-rr-fighter-jet:before {
    content: "\f2f3";
    }
        .fi-rr-file-ai:before {
    content: "\f2f4";
    }
        .fi-rr-file-chart-line:before {
    content: "\f2f5";
    }
        .fi-rr-file-chart-pie:before {
    content: "\f2f6";
    }
        .fi-rr-file-code:before {
    content: "\f2f7";
    }
        .fi-rr-file-eps:before {
    content: "\f2f8";
    }
        .fi-rr-file-invoice-dollar:before {
    content: "\f2f9";
    }
        .fi-rr-file-invoice:before {
    content: "\f2fa";
    }
        .fi-rr-file-psd:before {
    content: "\f2fb";
    }
        .fi-rr-file-spreadsheet:before {
    content: "\f2fc";
    }
        .fi-rr-file-video:before {
    content: "\f2fd";
    }
        .fi-rr-file:before {
    content: "\f2fe";
    }
        .fi-rr-fill:before {
    content: "\f2ff";
    }
        .fi-rr-film-slash:before {
    content: "\f300";
    }
        .fi-rr-film:before {
    content: "\f301";
    }
        .fi-rr-filter-slash:before {
    content: "\f302";
    }
        .fi-rr-filter:before {
    content: "\f303";
    }
        .fi-rr-filters:before {
    content: "\f304";
    }
        .fi-rr-fingerprint:before {
    content: "\f305";
    }
        .fi-rr-fish:before {
    content: "\f306";
    }
        .fi-rr-flag-alt:before {
    content: "\f307";
    }
        .fi-rr-flag-usa:before {
    content: "\f308";
    }
        .fi-rr-flag:before {
    content: "\f309";
    }
        .fi-rr-flame:before {
    content: "\f30a";
    }
        .fi-rr-flip-horizontal:before {
    content: "\f30b";
    }
        .fi-rr-flower-bouquet:before {
    content: "\f30c";
    }
        .fi-rr-flower-tulip:before {
    content: "\f30d";
    }
        .fi-rr-flower:before {
    content: "\f30e";
    }
        .fi-rr-flushed:before {
    content: "\f30f";
    }
        .fi-rr-fog:before {
    content: "\f310";
    }
        .fi-rr-folder-download:before {
    content: "\f311";
    }
        .fi-rr-folder-minus:before {
    content: "\f312";
    }
        .fi-rr-folder-times:before {
    content: "\f313";
    }
        .fi-rr-folder-tree:before {
    content: "\f314";
    }
        .fi-rr-folder-upload:before {
    content: "\f315";
    }
        .fi-rr-folder:before {
    content: "\f316";
    }
        .fi-rr-folders:before {
    content: "\f317";
    }
        .fi-rr-following:before {
    content: "\f318";
    }
        .fi-rr-football:before {
    content: "\f319";
    }
        .fi-rr-fork:before {
    content: "\f31a";
    }
        .fi-rr-form:before {
    content: "\f31b";
    }
        .fi-rr-forward:before {
    content: "\f31c";
    }
        .fi-rr-fox:before {
    content: "\f31d";
    }
        .fi-rr-french-fries:before {
    content: "\f31e";
    }
        .fi-rr-frown:before {
    content: "\f31f";
    }
        .fi-rr-ftp:before {
    content: "\f320";
    }
        .fi-rr-funnel-dollar:before {
    content: "\f321";
    }
        .fi-rr-g:before {
    content: "\f322";
    }
        .fi-rr-gallery:before {
    content: "\f323";
    }
        .fi-rr-game-board-alt:before {
    content: "\f324";
    }
        .fi-rr-gamepad:before {
    content: "\f325";
    }
        .fi-rr-garage-car:before {
    content: "\f326";
    }
        .fi-rr-garage-open:before {
    content: "\f327";
    }
        .fi-rr-garage:before {
    content: "\f328";
    }
        .fi-rr-garlic:before {
    content: "\f329";
    }
        .fi-rr-gas-pump-alt:before {
    content: "\f32a";
    }
        .fi-rr-gas-pump-slash:before {
    content: "\f32b";
    }
        .fi-rr-gas-pump:before {
    content: "\f32c";
    }
        .fi-rr-gem:before {
    content: "\f32d";
    }
        .fi-rr-gif:before {
    content: "\f32e";
    }
        .fi-rr-gift-card:before {
    content: "\f32f";
    }
        .fi-rr-gift:before {
    content: "\f330";
    }
        .fi-rr-gifts:before {
    content: "\f331";
    }
        .fi-rr-gingerbread-man:before {
    content: "\f332";
    }
        .fi-rr-glass-cheers:before {
    content: "\f333";
    }
        .fi-rr-glass:before {
    content: "\f334";
    }
        .fi-rr-glasses:before {
    content: "\f335";
    }
        .fi-rr-globe-alt:before {
    content: "\f336";
    }
        .fi-rr-globe:before {
    content: "\f337";
    }
        .fi-rr-golf-ball:before {
    content: "\f338";
    }
        .fi-rr-golf:before {
    content: "\f339";
    }
        .fi-rr-graduation-cap:before {
    content: "\f33a";
    }
        .fi-rr-grape:before {
    content: "\f33b";
    }
        .fi-rr-graphic-tablet:before {
    content: "\f33c";
    }
        .fi-rr-grid-alt:before {
    content: "\f33d";
    }
        .fi-rr-grid:before {
    content: "\f33e";
    }
        .fi-rr-grill:before {
    content: "\f33f";
    }
        .fi-rr-grimace:before {
    content: "\f340";
    }
        .fi-rr-grin-alt:before {
    content: "\f341";
    }
        .fi-rr-grin-beam-sweat:before {
    content: "\f342";
    }
        .fi-rr-grin-beam:before {
    content: "\f343";
    }
        .fi-rr-grin-hearts:before {
    content: "\f344";
    }
        .fi-rr-grin-squint-tears:before {
    content: "\f345";
    }
        .fi-rr-grin-squint:before {
    content: "\f346";
    }
        .fi-rr-grin-stars:before {
    content: "\f347";
    }
        .fi-rr-grin-tears:before {
    content: "\f348";
    }
        .fi-rr-grin-tongue-squint:before {
    content: "\f349";
    }
        .fi-rr-grin-tongue-wink:before {
    content: "\f34a";
    }
        .fi-rr-grin-tongue:before {
    content: "\f34b";
    }
        .fi-rr-grin-wink:before {
    content: "\f34c";
    }
        .fi-rr-grin:before {
    content: "\f34d";
    }
        .fi-rr-guitar:before {
    content: "\f34e";
    }
        .fi-rr-gym:before {
    content: "\f34f";
    }
        .fi-rr-h:before {
    content: "\f350";
    }
        .fi-rr-hamburger-soda:before {
    content: "\f351";
    }
        .fi-rr-hamburger:before {
    content: "\f352";
    }
        .fi-rr-hand-holding-box:before {
    content: "\f353";
    }
        .fi-rr-hand-holding-heart:before {
    content: "\f354";
    }
        .fi-rr-hand-holding-seeding:before {
    content: "\f355";
    }
        .fi-rr-hand:before {
    content: "\f356";
    }
        .fi-rr-handshake:before {
    content: "\f357";
    }
        .fi-rr-hastag:before {
    content: "\f358";
    }
        .fi-rr-hat-birthday:before {
    content: "\f359";
    }
        .fi-rr-hat-chef:before {
    content: "\f35a";
    }
        .fi-rr-head-side-thinking:before {
    content: "\f35b";
    }
        .fi-rr-headphones:before {
    content: "\f35c";
    }
        .fi-rr-headset:before {
    content: "\f35d";
    }
        .fi-rr-heart-arrow:before {
    content: "\f35e";
    }
        .fi-rr-heart:before {
    content: "\f35f";
    }
        .fi-rr-heat:before {
    content: "\f360";
    }
        .fi-rr-helicopter-side:before {
    content: "\f361";
    }
        .fi-rr-highlighter:before {
    content: "\f362";
    }
        .fi-rr-hiking:before {
    content: "\f363";
    }
        .fi-rr-hockey-puck:before {
    content: "\f364";
    }
        .fi-rr-hockey-sticks:before {
    content: "\f365";
    }
        .fi-rr-home-location-alt:before {
    content: "\f366";
    }
        .fi-rr-home-location:before {
    content: "\f367";
    }
        .fi-rr-home:before {
    content: "\f368";
    }
        .fi-rr-hotdog:before {
    content: "\f369";
    }
        .fi-rr-hourglass-end:before {
    content: "\f36a";
    }
        .fi-rr-hourglass:before {
    content: "\f36b";
    }
        .fi-rr-house-flood:before {
    content: "\f36c";
    }
        .fi-rr-hryvnia:before {
    content: "\f36d";
    }
        .fi-rr-humidity:before {
    content: "\f36e";
    }
        .fi-rr-hurricane:before {
    content: "\f36f";
    }
        .fi-rr-i:before {
    content: "\f370";
    }
        .fi-rr-ice-cream:before {
    content: "\f371";
    }
        .fi-rr-ice-skate:before {
    content: "\f372";
    }
        .fi-rr-id-badge:before {
    content: "\f373";
    }
        .fi-rr-inbox-in:before {
    content: "\f374";
    }
        .fi-rr-inbox-out:before {
    content: "\f375";
    }
        .fi-rr-inbox:before {
    content: "\f376";
    }
        .fi-rr-incognito:before {
    content: "\f377";
    }
        .fi-rr-indent:before {
    content: "\f378";
    }
        .fi-rr-infinity:before {
    content: "\f379";
    }
        .fi-rr-info:before {
    content: "\f37a";
    }
        .fi-rr-interactive:before {
    content: "\f37b";
    }
        .fi-rr-interlining:before {
    content: "\f37c";
    }
        .fi-rr-interrogation:before {
    content: "\f37d";
    }
        .fi-rr-italic:before {
    content: "\f37e";
    }
        .fi-rr-j:before {
    content: "\f37f";
    }
        .fi-rr-jam:before {
    content: "\f380";
    }
        .fi-rr-jpg:before {
    content: "\f381";
    }
        .fi-rr-k:before {
    content: "\f382";
    }
        .fi-rr-kerning:before {
    content: "\f383";
    }
        .fi-rr-key:before {
    content: "\f384";
    }
        .fi-rr-keyboard:before {
    content: "\f385";
    }
        .fi-rr-keynote:before {
    content: "\f386";
    }
        .fi-rr-kiss-beam:before {
    content: "\f387";
    }
        .fi-rr-kiss-wink-heart:before {
    content: "\f388";
    }
        .fi-rr-kiss:before {
    content: "\f389";
    }
        .fi-rr-kite:before {
    content: "\f38a";
    }
        .fi-rr-knife:before {
    content: "\f38b";
    }
        .fi-rr-l:before {
    content: "\f38c";
    }
        .fi-rr-label:before {
    content: "\f38d";
    }
        .fi-rr-laptop-code:before {
    content: "\f38e";
    }
        .fi-rr-laptop:before {
    content: "\f38f";
    }
        .fi-rr-lasso:before {
    content: "\f390";
    }
        .fi-rr-laugh-beam:before {
    content: "\f391";
    }
        .fi-rr-laugh-squint:before {
    content: "\f392";
    }
        .fi-rr-laugh-wink:before {
    content: "\f393";
    }
        .fi-rr-laugh:before {
    content: "\f394";
    }
        .fi-rr-layer-minus:before {
    content: "\f395";
    }
        .fi-rr-layer-plus:before {
    content: "\f396";
    }
        .fi-rr-layers:before {
    content: "\f397";
    }
        .fi-rr-layout-fluid:before {
    content: "\f398";
    }
        .fi-rr-leaf:before {
    content: "\f399";
    }
        .fi-rr-lemon:before {
    content: "\f39a";
    }
        .fi-rr-letter-case:before {
    content: "\f39b";
    }
        .fi-rr-lettuce:before {
    content: "\f39c";
    }
        .fi-rr-level-down-alt:before {
    content: "\f39d";
    }
        .fi-rr-level-down:before {
    content: "\f39e";
    }
        .fi-rr-level-up-alt:before {
    content: "\f39f";
    }
        .fi-rr-level-up:before {
    content: "\f3a0";
    }
        .fi-rr-life-ring:before {
    content: "\f3a1";
    }
        .fi-rr-lightbulb-dollar:before {
    content: "\f3a2";
    }
        .fi-rr-line-width:before {
    content: "\f3a3";
    }
        .fi-rr-link-alt:before {
    content: "\f3a4";
    }
        .fi-rr-link-horizontal-slash:before {
    content: "\f3a5";
    }
        .fi-rr-link-horizontal:before {
    content: "\f3a6";
    }
        .fi-rr-link-slash-alt:before {
    content: "\f3a7";
    }
        .fi-rr-link-slash:before {
    content: "\f3a8";
    }
        .fi-rr-link:before {
    content: "\f3a9";
    }
        .fi-rr-lipstick:before {
    content: "\f3aa";
    }
        .fi-rr-lira-sign:before {
    content: "\f3ab";
    }
        .fi-rr-list-check:before {
    content: "\f3ac";
    }
        .fi-rr-list:before {
    content: "\f3ad";
    }
        .fi-rr-loading:before {
    content: "\f3ae";
    }
        .fi-rr-location-alt:before {
    content: "\f3af";
    }
        .fi-rr-lock-alt:before {
    content: "\f3b0";
    }
        .fi-rr-lock:before {
    content: "\f3b1";
    }
        .fi-rr-luchador:before {
    content: "\f3b2";
    }
        .fi-rr-luggage-rolling:before {
    content: "\f3b3";
    }
        .fi-rr-m:before {
    content: "\f3b4";
    }
        .fi-rr-magic-wand:before {
    content: "\f3b5";
    }
        .fi-rr-mailbox:before {
    content: "\f3b6";
    }
        .fi-rr-makeup-brush:before {
    content: "\f3b7";
    }
        .fi-rr-man-head:before {
    content: "\f3b8";
    }
        .fi-rr-map-marker-cross:before {
    content: "\f3b9";
    }
        .fi-rr-map-marker-home:before {
    content: "\f3ba";
    }
        .fi-rr-map-marker-minus:before {
    content: "\f3bb";
    }
        .fi-rr-map-marker-plus:before {
    content: "\f3bc";
    }
        .fi-rr-map-marker:before {
    content: "\f3bd";
    }
        .fi-rr-map:before {
    content: "\f3be";
    }
        .fi-rr-marker-time:before {
    content: "\f3bf";
    }
        .fi-rr-marker:before {
    content: "\f3c0";
    }
        .fi-rr-mars-double:before {
    content: "\f3c1";
    }
        .fi-rr-mars:before {
    content: "\f3c2";
    }
        .fi-rr-mask-carnival:before {
    content: "\f3c3";
    }
        .fi-rr-mask:before {
    content: "\f3c4";
    }
        .fi-rr-medicine:before {
    content: "\f3c5";
    }
        .fi-rr-megaphone:before {
    content: "\f3c6";
    }
        .fi-rr-meh-blank:before {
    content: "\f3c7";
    }
        .fi-rr-meh-rolling-eyes:before {
    content: "\f3c8";
    }
        .fi-rr-meh:before {
    content: "\f3c9";
    }
        .fi-rr-melon:before {
    content: "\f3ca";
    }
        .fi-rr-menu-burger:before {
    content: "\f3cb";
    }
        .fi-rr-menu-dots-vertical:before {
    content: "\f3cc";
    }
        .fi-rr-menu-dots:before {
    content: "\f3cd";
    }
        .fi-rr-message-code:before {
    content: "\f3ce";
    }
        .fi-rr-meteor:before {
    content: "\f3cf";
    }
        .fi-rr-microphone-alt:before {
    content: "\f3d0";
    }
        .fi-rr-microphone:before {
    content: "\f3d1";
    }
        .fi-rr-mind-share:before {
    content: "\f3d2";
    }
        .fi-rr-minus-small:before {
    content: "\f3d3";
    }
        .fi-rr-minus:before {
    content: "\f3d4";
    }
        .fi-rr-mobile-button:before {
    content: "\f3d5";
    }
        .fi-rr-mobile-notch:before {
    content: "\f3d6";
    }
        .fi-rr-mobile:before {
    content: "\f3d7";
    }
        .fi-rr-mode-landscape:before {
    content: "\f3d8";
    }
        .fi-rr-mode-portrait:before {
    content: "\f3d9";
    }
        .fi-rr-money-bill-wave-alt:before {
    content: "\f3da";
    }
        .fi-rr-money-bill-wave:before {
    content: "\f3db";
    }
        .fi-rr-money-check-edit-alt:before {
    content: "\f3dc";
    }
        .fi-rr-money-check-edit:before {
    content: "\f3dd";
    }
        .fi-rr-money-check:before {
    content: "\f3de";
    }
        .fi-rr-money:before {
    content: "\f3df";
    }
        .fi-rr-moon-stars:before {
    content: "\f3e0";
    }
        .fi-rr-moon:before {
    content: "\f3e1";
    }
        .fi-rr-motorcycle:before {
    content: "\f3e2";
    }
        .fi-rr-mountains:before {
    content: "\f3e3";
    }
        .fi-rr-mouse:before {
    content: "\f3e4";
    }
        .fi-rr-mug-alt:before {
    content: "\f3e5";
    }
        .fi-rr-mug-hot-alt:before {
    content: "\f3e6";
    }
        .fi-rr-mug-hot:before {
    content: "\f3e7";
    }
        .fi-rr-mug-tea:before {
    content: "\f3e8";
    }
        .fi-rr-mug:before {
    content: "\f3e9";
    }
        .fi-rr-mushroom:before {
    content: "\f3ea";
    }
        .fi-rr-music-alt:before {
    content: "\f3eb";
    }
        .fi-rr-music-file:before {
    content: "\f3ec";
    }
        .fi-rr-music:before {
    content: "\f3ed";
    }
        .fi-rr-n:before {
    content: "\f3ee";
    }
        .fi-rr-navigation:before {
    content: "\f3ef";
    }
        .fi-rr-network-cloud:before {
    content: "\f3f0";
    }
        .fi-rr-network:before {
    content: "\f3f1";
    }
        .fi-rr-noodles:before {
    content: "\f3f2";
    }
        .fi-rr-notebook:before {
    content: "\f3f3";
    }
        .fi-rr-o:before {
    content: "\f3f4";
    }
        .fi-rr-oil-can:before {
    content: "\f3f5";
    }
        .fi-rr-oil-temp:before {
    content: "\f3f6";
    }
        .fi-rr-olive-oil:before {
    content: "\f3f7";
    }
        .fi-rr-olives:before {
    content: "\f3f8";
    }
        .fi-rr-onion:before {
    content: "\f3f9";
    }
        .fi-rr-opacity:before {
    content: "\f3fa";
    }
        .fi-rr-overline:before {
    content: "\f3fb";
    }
        .fi-rr-p:before {
    content: "\f3fc";
    }
        .fi-rr-package:before {
    content: "\f3fd";
    }
        .fi-rr-page-break:before {
    content: "\f3fe";
    }
        .fi-rr-paint-brush:before {
    content: "\f3ff";
    }
        .fi-rr-paint-roller:before {
    content: "\f400";
    }
        .fi-rr-palette:before {
    content: "\f401";
    }
        .fi-rr-pan:before {
    content: "\f402";
    }
        .fi-rr-paper-plane:before {
    content: "\f403";
    }
        .fi-rr-password:before {
    content: "\f404";
    }
        .fi-rr-pause:before {
    content: "\f405";
    }
        .fi-rr-paw:before {
    content: "\f406";
    }
        .fi-rr-peach:before {
    content: "\f407";
    }
        .fi-rr-pencil-ruler:before {
    content: "\f408";
    }
        .fi-rr-pencil:before {
    content: "\f409";
    }
        .fi-rr-pennant:before {
    content: "\f40a";
    }
        .fi-rr-people-poll:before {
    content: "\f40b";
    }
        .fi-rr-pepper-hot:before {
    content: "\f40c";
    }
        .fi-rr-pepper:before {
    content: "\f40d";
    }
        .fi-rr-percentage:before {
    content: "\f40e";
    }
        .fi-rr-pharmacy:before {
    content: "\f40f";
    }
        .fi-rr-phone-call:before {
    content: "\f410";
    }
        .fi-rr-phone-cross:before {
    content: "\f411";
    }
        .fi-rr-phone-office:before {
    content: "\f412";
    }
        .fi-rr-phone-pause:before {
    content: "\f413";
    }
        .fi-rr-phone-slash:before {
    content: "\f414";
    }
        .fi-rr-photo-film-music:before {
    content: "\f415";
    }
        .fi-rr-photo-video:before {
    content: "\f416";
    }
        .fi-rr-physics:before {
    content: "\f417";
    }
        .fi-rr-picnic:before {
    content: "\f418";
    }
        .fi-rr-picture:before {
    content: "\f419";
    }
        .fi-rr-pie:before {
    content: "\f41a";
    }
        .fi-rr-piece:before {
    content: "\f41b";
    }
        .fi-rr-piggy-bank:before {
    content: "\f41c";
    }
        .fi-rr-pineapple:before {
    content: "\f41d";
    }
        .fi-rr-ping-pong:before {
    content: "\f41e";
    }
        .fi-rr-pizza-slice:before {
    content: "\f41f";
    }
        .fi-rr-plane-alt:before {
    content: "\f420";
    }
        .fi-rr-plane:before {
    content: "\f421";
    }
        .fi-rr-plate:before {
    content: "\f422";
    }
        .fi-rr-play-alt:before {
    content: "\f423";
    }
        .fi-rr-play-pause:before {
    content: "\f424";
    }
        .fi-rr-play:before {
    content: "\f425";
    }
        .fi-rr-playing-cards:before {
    content: "\f426";
    }
        .fi-rr-plus-small:before {
    content: "\f427";
    }
        .fi-rr-plus:before {
    content: "\f428";
    }
        .fi-rr-podium-star:before {
    content: "\f429";
    }
        .fi-rr-podium:before {
    content: "\f42a";
    }
        .fi-rr-poker-chip:before {
    content: "\f42b";
    }
        .fi-rr-poo:before {
    content: "\f42c";
    }
        .fi-rr-popcorn:before {
    content: "\f42d";
    }
        .fi-rr-portrait:before {
    content: "\f42e";
    }
        .fi-rr-pot:before {
    content: "\f42f";
    }
        .fi-rr-pound:before {
    content: "\f430";
    }
        .fi-rr-power:before {
    content: "\f431";
    }
        .fi-rr-presentation:before {
    content: "\f432";
    }
        .fi-rr-print:before {
    content: "\f433";
    }
        .fi-rr-protractor:before {
    content: "\f434";
    }
        .fi-rr-pulse:before {
    content: "\f435";
    }
        .fi-rr-pumpkin:before {
    content: "\f436";
    }
        .fi-rr-puzzle:before {
    content: "\f437";
    }
        .fi-rr-pyramid:before {
    content: "\f438";
    }
        .fi-rr-q:before {
    content: "\f439";
    }
        .fi-rr-qrcode:before {
    content: "\f43a";
    }
        .fi-rr-question-square:before {
    content: "\f43b";
    }
        .fi-rr-question:before {
    content: "\f43c";
    }
        .fi-rr-quote-right:before {
    content: "\f43d";
    }
        .fi-rr-r:before {
    content: "\f43e";
    }
        .fi-rr-racquet:before {
    content: "\f43f";
    }
        .fi-rr-radish:before {
    content: "\f440";
    }
        .fi-rr-rainbow:before {
    content: "\f441";
    }
        .fi-rr-raindrops:before {
    content: "\f442";
    }
        .fi-rr-rec:before {
    content: "\f443";
    }
        .fi-rr-receipt:before {
    content: "\f444";
    }
        .fi-rr-record-vinyl:before {
    content: "\f445";
    }
        .fi-rr-rectabgle-vertical:before {
    content: "\f446";
    }
        .fi-rr-rectangle-barcode:before {
    content: "\f447";
    }
        .fi-rr-rectangle-code:before {
    content: "\f448";
    }
        .fi-rr-rectangle-horizontal:before {
    content: "\f449";
    }
        .fi-rr-rectangle-panoramic:before {
    content: "\f44a";
    }
        .fi-rr-rectangle-xmark:before {
    content: "\f44b";
    }
        .fi-rr-recycle:before {
    content: "\f44c";
    }
        .fi-rr-redo-alt:before {
    content: "\f44d";
    }
        .fi-rr-redo:before {
    content: "\f44e";
    }
        .fi-rr-reflect:before {
    content: "\f44f";
    }
        .fi-rr-refresh:before {
    content: "\f450";
    }
        .fi-rr-registered:before {
    content: "\f451";
    }
        .fi-rr-remove-user:before {
    content: "\f452";
    }
        .fi-rr-reply-all:before {
    content: "\f453";
    }
        .fi-rr-republican:before {
    content: "\f454";
    }
        .fi-rr-resize:before {
    content: "\f455";
    }
        .fi-rr-resources:before {
    content: "\f456";
    }
        .fi-rr-restaurant:before {
    content: "\f457";
    }
        .fi-rr-rewind:before {
    content: "\f458";
    }
        .fi-rr-rhombus:before {
    content: "\f459";
    }
        .fi-rr-rings-wedding:before {
    content: "\f45a";
    }
        .fi-rr-road:before {
    content: "\f45b";
    }
        .fi-rr-rocket-lunch:before {
    content: "\f45c";
    }
        .fi-rr-rocket:before {
    content: "\f45d";
    }
        .fi-rr-room-service:before {
    content: "\f45e";
    }
        .fi-rr-rotate-left:before {
    content: "\f45f";
    }
        .fi-rr-rotate-right:before {
    content: "\f460";
    }
        .fi-rr-ruble-sign:before {
    content: "\f461";
    }
        .fi-rr-rugby:before {
    content: "\f462";
    }
        .fi-rr-ruler-combined:before {
    content: "\f463";
    }
        .fi-rr-ruler-horizontal:before {
    content: "\f464";
    }
        .fi-rr-ruler-triangle:before {
    content: "\f465";
    }
        .fi-rr-ruler-vertical:before {
    content: "\f466";
    }
        .fi-rr-running:before {
    content: "\f467";
    }
        .fi-rr-rupee-sign:before {
    content: "\f468";
    }
        .fi-rr-rv:before {
    content: "\f469";
    }
        .fi-rr-s:before {
    content: "\f46a";
    }
        .fi-rr-sack-dollar:before {
    content: "\f46b";
    }
        .fi-rr-sack:before {
    content: "\f46c";
    }
        .fi-rr-sad-cry:before {
    content: "\f46d";
    }
        .fi-rr-sad-tear:before {
    content: "\f46e";
    }
        .fi-rr-sad:before {
    content: "\f46f";
    }
        .fi-rr-salad:before {
    content: "\f470";
    }
        .fi-rr-salt-pepper:before {
    content: "\f471";
    }
        .fi-rr-sandwich:before {
    content: "\f472";
    }
        .fi-rr-sauce:before {
    content: "\f473";
    }
        .fi-rr-sausage:before {
    content: "\f474";
    }
        .fi-rr-scale:before {
    content: "\f475";
    }
        .fi-rr-school-bus:before {
    content: "\f476";
    }
        .fi-rr-school:before {
    content: "\f477";
    }
        .fi-rr-scissors:before {
    content: "\f478";
    }
        .fi-rr-screen:before {
    content: "\f479";
    }
        .fi-rr-search-alt:before {
    content: "\f47a";
    }
        .fi-rr-search-dollar:before {
    content: "\f47b";
    }
        .fi-rr-search-heart:before {
    content: "\f47c";
    }
        .fi-rr-search-location:before {
    content: "\f47d";
    }
        .fi-rr-search:before {
    content: "\f47e";
    }
        .fi-rr-security:before {
    content: "\f47f";
    }
        .fi-rr-sensor-alert:before {
    content: "\f480";
    }
        .fi-rr-sensor-fire:before {
    content: "\f481";
    }
        .fi-rr-sensor-on:before {
    content: "\f482";
    }
        .fi-rr-sensor-smoke:before {
    content: "\f483";
    }
        .fi-rr-sensor:before {
    content: "\f484";
    }
        .fi-rr-settings-sliders:before {
    content: "\f485";
    }
        .fi-rr-settings:before {
    content: "\f486";
    }
        .fi-rr-share:before {
    content: "\f487";
    }
        .fi-rr-shekel-sign:before {
    content: "\f488";
    }
        .fi-rr-shield-check:before {
    content: "\f489";
    }
        .fi-rr-shield-exclamation:before {
    content: "\f48a";
    }
        .fi-rr-shield-interrogation:before {
    content: "\f48b";
    }
        .fi-rr-shield-plus:before {
    content: "\f48c";
    }
        .fi-rr-shield:before {
    content: "\f48d";
    }
        .fi-rr-ship-side:before {
    content: "\f48e";
    }
        .fi-rr-ship:before {
    content: "\f48f";
    }
        .fi-rr-shop:before {
    content: "\f490";
    }
        .fi-rr-shopping-bag-add:before {
    content: "\f491";
    }
        .fi-rr-shopping-bag:before {
    content: "\f492";
    }
        .fi-rr-shopping-basket:before {
    content: "\f493";
    }
        .fi-rr-shopping-cart-add:before {
    content: "\f494";
    }
        .fi-rr-shopping-cart-check:before {
    content: "\f495";
    }
        .fi-rr-shopping-cart:before {
    content: "\f496";
    }
        .fi-rr-shrimp:before {
    content: "\f497";
    }
        .fi-rr-shuffle:before {
    content: "\f498";
    }
        .fi-rr-shuttle-van:before {
    content: "\f499";
    }
        .fi-rr-shuttlecock:before {
    content: "\f49a";
    }
        .fi-rr-sidebar-flip:before {
    content: "\f49b";
    }
        .fi-rr-sidebar:before {
    content: "\f49c";
    }
        .fi-rr-sign-in-alt:before {
    content: "\f49d";
    }
        .fi-rr-sign-out-alt:before {
    content: "\f49e";
    }
        .fi-rr-signal-alt-1:before {
    content: "\f49f";
    }
        .fi-rr-signal-alt-2:before {
    content: "\f4a0";
    }
        .fi-rr-signal-alt:before {
    content: "\f4a1";
    }
        .fi-rr-skateboard:before {
    content: "\f4a2";
    }
        .fi-rr-skating:before {
    content: "\f4a3";
    }
        .fi-rr-skewer:before {
    content: "\f4a4";
    }
        .fi-rr-ski-jump:before {
    content: "\f4a5";
    }
        .fi-rr-ski-lift:before {
    content: "\f4a6";
    }
        .fi-rr-skiing-nordic:before {
    content: "\f4a7";
    }
        .fi-rr-skiing:before {
    content: "\f4a8";
    }
        .fi-rr-sledding:before {
    content: "\f4a9";
    }
        .fi-rr-sleigh:before {
    content: "\f4aa";
    }
        .fi-rr-smartphone:before {
    content: "\f4ab";
    }
        .fi-rr-smile-beam:before {
    content: "\f4ac";
    }
        .fi-rr-smile-wink:before {
    content: "\f4ad";
    }
        .fi-rr-smile:before {
    content: "\f4ae";
    }
        .fi-rr-smog:before {
    content: "\f4af";
    }
        .fi-rr-smoke:before {
    content: "\f4b0";
    }
        .fi-rr-snow-blowing:before {
    content: "\f4b1";
    }
        .fi-rr-snowboarding:before {
    content: "\f4b2";
    }
        .fi-rr-snowflake:before {
    content: "\f4b3";
    }
        .fi-rr-snowflakes:before {
    content: "\f4b4";
    }
        .fi-rr-snowmobile:before {
    content: "\f4b5";
    }
        .fi-rr-snowplow:before {
    content: "\f4b6";
    }
        .fi-rr-soap:before {
    content: "\f4b7";
    }
        .fi-rr-social-network:before {
    content: "\f4b8";
    }
        .fi-rr-sort-alpha-down-alt:before {
    content: "\f4b9";
    }
        .fi-rr-sort-alpha-down:before {
    content: "\f4ba";
    }
        .fi-rr-sort-alpha-up-alt:before {
    content: "\f4bb";
    }
        .fi-rr-sort-alpha-up:before {
    content: "\f4bc";
    }
        .fi-rr-sort-alt:before {
    content: "\f4bd";
    }
        .fi-rr-sort-amount-down-alt:before {
    content: "\f4be";
    }
        .fi-rr-sort-amount-down:before {
    content: "\f4bf";
    }
        .fi-rr-sort-amount-up-alt:before {
    content: "\f4c0";
    }
        .fi-rr-sort-amount-up:before {
    content: "\f4c1";
    }
        .fi-rr-sort-down:before {
    content: "\f4c2";
    }
        .fi-rr-sort-numeric-down-alt:before {
    content: "\f4c3";
    }
        .fi-rr-sort-numeric-down:before {
    content: "\f4c4";
    }
        .fi-rr-sort:before {
    content: "\f4c5";
    }
        .fi-rr-soup:before {
    content: "\f4c6";
    }
        .fi-rr-spa:before {
    content: "\f4c7";
    }
        .fi-rr-space-shuttle:before {
    content: "\f4c8";
    }
        .fi-rr-spade:before {
    content: "\f4c9";
    }
        .fi-rr-sparkles:before {
    content: "\f4ca";
    }
        .fi-rr-speaker:before {
    content: "\f4cb";
    }
        .fi-rr-sphere:before {
    content: "\f4cc";
    }
        .fi-rr-spinner:before {
    content: "\f4cd";
    }
        .fi-rr-spoon:before {
    content: "\f4ce";
    }
        .fi-rr-sport:before {
    content: "\f4cf";
    }
        .fi-rr-square-code:before {
    content: "\f4d0";
    }
        .fi-rr-square-root:before {
    content: "\f4d1";
    }
        .fi-rr-square-terminal:before {
    content: "\f4d2";
    }
        .fi-rr-square:before {
    content: "\f4d3";
    }
        .fi-rr-stamp:before {
    content: "\f4d4";
    }
        .fi-rr-star-octogram:before {
    content: "\f4d5";
    }
        .fi-rr-star:before {
    content: "\f4d6";
    }
        .fi-rr-starfighter:before {
    content: "\f4d7";
    }
        .fi-rr-stars:before {
    content: "\f4d8";
    }
        .fi-rr-stats:before {
    content: "\f4d9";
    }
        .fi-rr-steak:before {
    content: "\f4da";
    }
        .fi-rr-steering-wheel:before {
    content: "\f4db";
    }
        .fi-rr-stethoscope:before {
    content: "\f4dc";
    }
        .fi-rr-sticker:before {
    content: "\f4dd";
    }
        .fi-rr-stop:before {
    content: "\f4de";
    }
        .fi-rr-stopwatch:before {
    content: "\f4df";
    }
        .fi-rr-store-alt:before {
    content: "\f4e0";
    }
        .fi-rr-strawberry:before {
    content: "\f4e1";
    }
        .fi-rr-subtitles:before {
    content: "\f4e2";
    }
        .fi-rr-subway:before {
    content: "\f4e3";
    }
        .fi-rr-suitcase-alt:before {
    content: "\f4e4";
    }
        .fi-rr-summer:before {
    content: "\f4e5";
    }
        .fi-rr-sun:before {
    content: "\f4e6";
    }
        .fi-rr-sunrise-alt:before {
    content: "\f4e7";
    }
        .fi-rr-sunrise:before {
    content: "\f4e8";
    }
        .fi-rr-sunset:before {
    content: "\f4e9";
    }
        .fi-rr-surfing:before {
    content: "\f4ea";
    }
        .fi-rr-surprise:before {
    content: "\f4eb";
    }
        .fi-rr-sushi:before {
    content: "\f4ec";
    }
        .fi-rr-swimmer:before {
    content: "\f4ed";
    }
        .fi-rr-sword:before {
    content: "\f4ee";
    }
        .fi-rr-symbol:before {
    content: "\f4ef";
    }
        .fi-rr-syringe:before {
    content: "\f4f0";
    }
        .fi-rr-t:before {
    content: "\f4f1";
    }
        .fi-rr-table-columns:before {
    content: "\f4f2";
    }
        .fi-rr-table-layout:before {
    content: "\f4f3";
    }
        .fi-rr-table-pivot:before {
    content: "\f4f4";
    }
        .fi-rr-table-rows:before {
    content: "\f4f5";
    }
        .fi-rr-table-tree:before {
    content: "\f4f6";
    }
        .fi-rr-tablet:before {
    content: "\f4f7";
    }
        .fi-rr-tachometer-alt-average:before {
    content: "\f4f8";
    }
        .fi-rr-tachometer-alt-fastest:before {
    content: "\f4f9";
    }
        .fi-rr-tachometer-alt-slow:before {
    content: "\f4fa";
    }
        .fi-rr-tachometer-alt-slowest:before {
    content: "\f4fb";
    }
        .fi-rr-tachometer-average:before {
    content: "\f4fc";
    }
        .fi-rr-tachometer-fast:before {
    content: "\f4fd";
    }
        .fi-rr-tachometer-fastest:before {
    content: "\f4fe";
    }
        .fi-rr-tachometer-slow:before {
    content: "\f4ff";
    }
        .fi-rr-tachometer-slowest:before {
    content: "\f500";
    }
        .fi-rr-tachometer:before {
    content: "\f501";
    }
        .fi-rr-taco:before {
    content: "\f502";
    }
        .fi-rr-tags:before {
    content: "\f503";
    }
        .fi-rr-tally:before {
    content: "\f504";
    }
        .fi-rr-target:before {
    content: "\f505";
    }
        .fi-rr-taxi:before {
    content: "\f506";
    }
        .fi-rr-temperature-down:before {
    content: "\f507";
    }
        .fi-rr-temperature-frigid:before {
    content: "\f508";
    }
        .fi-rr-temperature-high:before {
    content: "\f509";
    }
        .fi-rr-temperature-low:before {
    content: "\f50a";
    }
        .fi-rr-temperature-up:before {
    content: "\f50b";
    }
        .fi-rr-tenge:before {
    content: "\f50c";
    }
        .fi-rr-tennis:before {
    content: "\f50d";
    }
        .fi-rr-terrace:before {
    content: "\f50e";
    }
        .fi-rr-test-tube:before {
    content: "\f50f";
    }
        .fi-rr-test:before {
    content: "\f510";
    }
        .fi-rr-text-check:before {
    content: "\f511";
    }
        .fi-rr-text-slash:before {
    content: "\f512";
    }
        .fi-rr-text:before {
    content: "\f513";
    }
        .fi-rr-thermometer-half:before {
    content: "\f514";
    }
        .fi-rr-thumbtack:before {
    content: "\f515";
    }
        .fi-rr-thunderstorm-moon:before {
    content: "\f516";
    }
        .fi-rr-thunderstorm-sun:before {
    content: "\f517";
    }
        .fi-rr-thunderstorm:before {
    content: "\f518";
    }
        .fi-rr-ticket:before {
    content: "\f519";
    }
        .fi-rr-time-add:before {
    content: "\f51a";
    }
        .fi-rr-time-check:before {
    content: "\f51b";
    }
        .fi-rr-time-delete:before {
    content: "\f51c";
    }
        .fi-rr-time-fast:before {
    content: "\f51d";
    }
        .fi-rr-time-forward-sixty:before {
    content: "\f51e";
    }
        .fi-rr-time-forward-ten:before {
    content: "\f51f";
    }
        .fi-rr-time-forward:before {
    content: "\f520";
    }
        .fi-rr-time-half-past:before {
    content: "\f521";
    }
        .fi-rr-time-oclock:before {
    content: "\f522";
    }
        .fi-rr-time-past:before {
    content: "\f523";
    }
        .fi-rr-time-quarter-past:before {
    content: "\f524";
    }
        .fi-rr-time-quarter-to:before {
    content: "\f525";
    }
        .fi-rr-time-twenty-four:before {
    content: "\f526";
    }
        .fi-rr-tire-flat:before {
    content: "\f527";
    }
        .fi-rr-tire-pressure-warning:before {
    content: "\f528";
    }
        .fi-rr-tire-rugged:before {
    content: "\f529";
    }
        .fi-rr-tire:before {
    content: "\f52a";
    }
        .fi-rr-tired:before {
    content: "\f52b";
    }
        .fi-rr-tomato:before {
    content: "\f52c";
    }
        .fi-rr-tool-box:before {
    content: "\f52d";
    }
        .fi-rr-tool-crop:before {
    content: "\f52e";
    }
        .fi-rr-tool-marquee:before {
    content: "\f52f";
    }
        .fi-rr-tooth:before {
    content: "\f530";
    }
        .fi-rr-tornado:before {
    content: "\f531";
    }
        .fi-rr-tractor:before {
    content: "\f532";
    }
        .fi-rr-trailer:before {
    content: "\f533";
    }
        .fi-rr-train-side:before {
    content: "\f534";
    }
        .fi-rr-train:before {
    content: "\f535";
    }
        .fi-rr-tram:before {
    content: "\f536";
    }
        .fi-rr-transform:before {
    content: "\f537";
    }
        .fi-rr-trash:before {
    content: "\f538";
    }
        .fi-rr-treasure-chest:before {
    content: "\f539";
    }
        .fi-rr-treatment:before {
    content: "\f53a";
    }
        .fi-rr-tree-christmas:before {
    content: "\f53b";
    }
        .fi-rr-tree:before {
    content: "\f53c";
    }
        .fi-rr-triangle:before {
    content: "\f53d";
    }
        .fi-rr-trophy:before {
    content: "\f53e";
    }
        .fi-rr-truck-container:before {
    content: "\f53f";
    }
        .fi-rr-truck-couch:before {
    content: "\f540";
    }
        .fi-rr-truck-loading:before {
    content: "\f541";
    }
        .fi-rr-truck-monster:before {
    content: "\f542";
    }
        .fi-rr-truck-moving:before {
    content: "\f543";
    }
        .fi-rr-truck-pickup:before {
    content: "\f544";
    }
        .fi-rr-truck-plow:before {
    content: "\f545";
    }
        .fi-rr-truck-ramp:before {
    content: "\f546";
    }
        .fi-rr-truck-side:before {
    content: "\f547";
    }
        .fi-rr-tty:before {
    content: "\f548";
    }
        .fi-rr-turkey:before {
    content: "\f549";
    }
        .fi-rr-tv-music:before {
    content: "\f54a";
    }
        .fi-rr-typewriter:before {
    content: "\f54b";
    }
        .fi-rr-u:before {
    content: "\f54c";
    }
        .fi-rr-umbrella:before {
    content: "\f54d";
    }
        .fi-rr-underline:before {
    content: "\f54e";
    }
        .fi-rr-undo-alt:before {
    content: "\f54f";
    }
        .fi-rr-undo:before {
    content: "\f550";
    }
        .fi-rr-unlock:before {
    content: "\f551";
    }
        .fi-rr-upload:before {
    content: "\f552";
    }
        .fi-rr-usb-pendrive:before {
    content: "\f553";
    }
        .fi-rr-usd-circle:before {
    content: "\f554";
    }
        .fi-rr-usd-square:before {
    content: "\f555";
    }
        .fi-rr-user-add:before {
    content: "\f556";
    }
        .fi-rr-user-time:before {
    content: "\f557";
    }
        .fi-rr-user:before {
    content: "\f558";
    }
        .fi-rr-users-alt:before {
    content: "\f559";
    }
        .fi-rr-users:before {
    content: "\f55a";
    }
        .fi-rr-utensils:before {
    content: "\f55b";
    }
        .fi-rr-v:before {
    content: "\f55c";
    }
        .fi-rr-vector-alt:before {
    content: "\f55d";
    }
        .fi-rr-vector:before {
    content: "\f55e";
    }
        .fi-rr-venus-double:before {
    content: "\f55f";
    }
        .fi-rr-venus-mars:before {
    content: "\f560";
    }
        .fi-rr-venus:before {
    content: "\f561";
    }
        .fi-rr-video-arrow-down-left:before {
    content: "\f562";
    }
        .fi-rr-video-arrow-up-right:before {
    content: "\f563";
    }
        .fi-rr-video-camera-alt:before {
    content: "\f564";
    }
        .fi-rr-video-camera:before {
    content: "\f565";
    }
        .fi-rr-video-plus:before {
    content: "\f566";
    }
        .fi-rr-video-slash:before {
    content: "\f567";
    }
        .fi-rr-volcano:before {
    content: "\f568";
    }
        .fi-rr-volleyball:before {
    content: "\f569";
    }
        .fi-rr-volume:before {
    content: "\f56a";
    }
        .fi-rr-w:before {
    content: "\f56b";
    }
        .fi-rr-wagon-covered:before {
    content: "\f56c";
    }
        .fi-rr-wallet:before {
    content: "\f56d";
    }
        .fi-rr-water-bottle:before {
    content: "\f56e";
    }
        .fi-rr-water-lower:before {
    content: "\f56f";
    }
        .fi-rr-water-rise:before {
    content: "\f570";
    }
        .fi-rr-water:before {
    content: "\f571";
    }
        .fi-rr-watermelon:before {
    content: "\f572";
    }
        .fi-rr-wheat:before {
    content: "\f573";
    }
        .fi-rr-wheelchair:before {
    content: "\f574";
    }
        .fi-rr-whistle:before {
    content: "\f575";
    }
        .fi-rr-wifi-alt:before {
    content: "\f576";
    }
        .fi-rr-wind-warning:before {
    content: "\f577";
    }
        .fi-rr-wind:before {
    content: "\f578";
    }
        .fi-rr-windsock:before {
    content: "\f579";
    }
        .fi-rr-woman-head:before {
    content: "\f57a";
    }
        .fi-rr-world:before {
    content: "\f57b";
    }
        .fi-rr-wrench-simple:before {
    content: "\f57c";
    }
        .fi-rr-x:before {
    content: "\f57d";
    }
        .fi-rr-y:before {
    content: "\f57e";
    }
        .fi-rr-yen:before {
    content: "\f57f";
    }
        .fi-rr-z:before {
    content: "\f580";
    }
        .fi-rr-zoom-in:before {
    content: "\f581";
    }
        .fi-rr-zoom-out:before {
    content: "\f582";
    }

@font-face {
font-family: "uicons-solid-rounded";
src: url(/_next/static/media/uicons-solid-rounded.e87ef870.eot#iefix) format("embedded-opentype"),
url(/_next/static/media/uicons-solid-rounded.dba87a2c.woff2) format("woff2"),
url(/_next/static/media/uicons-solid-rounded.7c11b18f.woff) format("woff");
}

    i[class^="fi-sr-"]:before, i[class*=" fi-sr-"]:before, span[class^="fi-sr-"]:before, span[class*="fi-sr-"]:before {
font-family: uicons-solid-rounded !important;
font-style: normal;
font-weight: normal !important;
font-variant: normal;
text-transform: none;
line-height: 1;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}

        .fi-sr-a:before {
    content: "\f101";
    }
        .fi-sr-ad:before {
    content: "\f102";
    }
        .fi-sr-add-document:before {
    content: "\f103";
    }
        .fi-sr-add-folder:before {
    content: "\f104";
    }
        .fi-sr-add:before {
    content: "\f105";
    }
        .fi-sr-address-book:before {
    content: "\f106";
    }
        .fi-sr-air-conditioner:before {
    content: "\f107";
    }
        .fi-sr-air-freshener:before {
    content: "\f108";
    }
        .fi-sr-alarm-clock:before {
    content: "\f109";
    }
        .fi-sr-alarm-exclamation:before {
    content: "\f10a";
    }
        .fi-sr-alarm-plus:before {
    content: "\f10b";
    }
        .fi-sr-alarm-snooze:before {
    content: "\f10c";
    }
        .fi-sr-align-justify:before {
    content: "\f10d";
    }
        .fi-sr-align-left:before {
    content: "\f10e";
    }
        .fi-sr-ambulance:before {
    content: "\f10f";
    }
        .fi-sr-amusement-park:before {
    content: "\f110";
    }
        .fi-sr-angle-circle-down:before {
    content: "\f111";
    }
        .fi-sr-angle-circle-left:before {
    content: "\f112";
    }
        .fi-sr-angle-circle-right:before {
    content: "\f113";
    }
        .fi-sr-angle-circle-up:before {
    content: "\f114";
    }
        .fi-sr-angle-double-left:before {
    content: "\f115";
    }
        .fi-sr-angle-double-right:before {
    content: "\f116";
    }
        .fi-sr-angle-double-small-down:before {
    content: "\f117";
    }
        .fi-sr-angle-double-small-left:before {
    content: "\f118";
    }
        .fi-sr-angle-double-small-right:before {
    content: "\f119";
    }
        .fi-sr-angle-double-small-up:before {
    content: "\f11a";
    }
        .fi-sr-angle-down:before {
    content: "\f11b";
    }
        .fi-sr-angle-left:before {
    content: "\f11c";
    }
        .fi-sr-angle-right:before {
    content: "\f11d";
    }
        .fi-sr-angle-small-down:before {
    content: "\f11e";
    }
        .fi-sr-angle-small-left:before {
    content: "\f11f";
    }
        .fi-sr-angle-small-right:before {
    content: "\f120";
    }
        .fi-sr-angle-small-up:before {
    content: "\f121";
    }
        .fi-sr-angle-square-down:before {
    content: "\f122";
    }
        .fi-sr-angle-square-left:before {
    content: "\f123";
    }
        .fi-sr-angle-square-right:before {
    content: "\f124";
    }
        .fi-sr-angle-square-up:before {
    content: "\f125";
    }
        .fi-sr-angle-up:before {
    content: "\f126";
    }
        .fi-sr-angry:before {
    content: "\f127";
    }
        .fi-sr-apple-whole:before {
    content: "\f128";
    }
        .fi-sr-apps-add:before {
    content: "\f129";
    }
        .fi-sr-apps-delete:before {
    content: "\f12a";
    }
        .fi-sr-apps-sort:before {
    content: "\f12b";
    }
        .fi-sr-apps:before {
    content: "\f12c";
    }
        .fi-sr-archive:before {
    content: "\f12d";
    }
        .fi-sr-arrow-alt-circle-down:before {
    content: "\f12e";
    }
        .fi-sr-arrow-alt-circle-left:before {
    content: "\f12f";
    }
        .fi-sr-arrow-alt-circle-right:before {
    content: "\f130";
    }
        .fi-sr-arrow-alt-circle-up:before {
    content: "\f131";
    }
        .fi-sr-arrow-alt-down:before {
    content: "\f132";
    }
        .fi-sr-arrow-alt-from-bottom:before {
    content: "\f133";
    }
        .fi-sr-arrow-alt-from-left:before {
    content: "\f134";
    }
        .fi-sr-arrow-alt-from-right:before {
    content: "\f135";
    }
        .fi-sr-arrow-alt-from-top:before {
    content: "\f136";
    }
        .fi-sr-arrow-alt-left:before {
    content: "\f137";
    }
        .fi-sr-arrow-alt-right:before {
    content: "\f138";
    }
        .fi-sr-arrow-alt-square-down:before {
    content: "\f139";
    }
        .fi-sr-arrow-alt-square-left:before {
    content: "\f13a";
    }
        .fi-sr-arrow-alt-square-right:before {
    content: "\f13b";
    }
        .fi-sr-arrow-alt-square-up:before {
    content: "\f13c";
    }
        .fi-sr-arrow-alt-to-bottom:before {
    content: "\f13d";
    }
        .fi-sr-arrow-alt-to-left:before {
    content: "\f13e";
    }
        .fi-sr-arrow-alt-to-right:before {
    content: "\f13f";
    }
        .fi-sr-arrow-alt-to-top:before {
    content: "\f140";
    }
        .fi-sr-arrow-alt-up:before {
    content: "\f141";
    }
        .fi-sr-arrow-circle-down:before {
    content: "\f142";
    }
        .fi-sr-arrow-circle-left:before {
    content: "\f143";
    }
        .fi-sr-arrow-circle-right:before {
    content: "\f144";
    }
        .fi-sr-arrow-circle-up:before {
    content: "\f145";
    }
        .fi-sr-arrow-down:before {
    content: "\f146";
    }
        .fi-sr-arrow-from-bottom:before {
    content: "\f147";
    }
        .fi-sr-arrow-from-left:before {
    content: "\f148";
    }
        .fi-sr-arrow-from-right:before {
    content: "\f149";
    }
        .fi-sr-arrow-from-top:before {
    content: "\f14a";
    }
        .fi-sr-arrow-left:before {
    content: "\f14b";
    }
        .fi-sr-arrow-right:before {
    content: "\f14c";
    }
        .fi-sr-arrow-small-down:before {
    content: "\f14d";
    }
        .fi-sr-arrow-small-left:before {
    content: "\f14e";
    }
        .fi-sr-arrow-small-right:before {
    content: "\f14f";
    }
        .fi-sr-arrow-small-up:before {
    content: "\f150";
    }
        .fi-sr-arrow-square-down:before {
    content: "\f151";
    }
        .fi-sr-arrow-square-left:before {
    content: "\f152";
    }
        .fi-sr-arrow-square-right:before {
    content: "\f153";
    }
        .fi-sr-arrow-square-up:before {
    content: "\f154";
    }
        .fi-sr-arrow-to-bottom:before {
    content: "\f155";
    }
        .fi-sr-arrow-to-left:before {
    content: "\f156";
    }
        .fi-sr-arrow-to-right:before {
    content: "\f157";
    }
        .fi-sr-arrow-to-top:before {
    content: "\f158";
    }
        .fi-sr-arrow-trend-down:before {
    content: "\f159";
    }
        .fi-sr-arrow-trend-up:before {
    content: "\f15a";
    }
        .fi-sr-arrow-up-from-square:before {
    content: "\f15b";
    }
        .fi-sr-arrow-up-left-from-circle:before {
    content: "\f15c";
    }
        .fi-sr-arrow-up-left:before {
    content: "\f15d";
    }
        .fi-sr-arrow-up-right-and-arrow-down-left-from-center:before {
    content: "\f15e";
    }
        .fi-sr-arrow-up-right-from-square:before {
    content: "\f15f";
    }
        .fi-sr-arrow-up-right:before {
    content: "\f160";
    }
        .fi-sr-arrow-up-square-triangle:before {
    content: "\f161";
    }
        .fi-sr-arrow-up-to-dotted-line:before {
    content: "\f162";
    }
        .fi-sr-arrow-up:before {
    content: "\f163";
    }
        .fi-sr-arrows-alt-h:before {
    content: "\f164";
    }
        .fi-sr-arrows-alt-v:before {
    content: "\f165";
    }
        .fi-sr-arrows-alt:before {
    content: "\f166";
    }
        .fi-sr-arrows-cross:before {
    content: "\f167";
    }
        .fi-sr-arrows-from-dotted-line:before {
    content: "\f168";
    }
        .fi-sr-arrows-from-line:before {
    content: "\f169";
    }
        .fi-sr-arrows-h-copy:before {
    content: "\f16a";
    }
        .fi-sr-arrows-h:before {
    content: "\f16b";
    }
        .fi-sr-arrows-repeat-1:before {
    content: "\f16c";
    }
        .fi-sr-arrows-repeat:before {
    content: "\f16d";
    }
        .fi-sr-arrows-to-dotted-line:before {
    content: "\f16e";
    }
        .fi-sr-arrows-to-line:before {
    content: "\f16f";
    }
        .fi-sr-arrows:before {
    content: "\f170";
    }
        .fi-sr-assept-document:before {
    content: "\f171";
    }
        .fi-sr-assistive-listening-systems:before {
    content: "\f172";
    }
        .fi-sr-asterik:before {
    content: "\f173";
    }
        .fi-sr-at:before {
    content: "\f174";
    }
        .fi-sr-aubergine:before {
    content: "\f175";
    }
        .fi-sr-avocado:before {
    content: "\f176";
    }
        .fi-sr-b:before {
    content: "\f177";
    }
        .fi-sr-baby-carriage:before {
    content: "\f178";
    }
        .fi-sr-backpack:before {
    content: "\f179";
    }
        .fi-sr-bacon:before {
    content: "\f17a";
    }
        .fi-sr-badge-check:before {
    content: "\f17b";
    }
        .fi-sr-badge-dollar:before {
    content: "\f17c";
    }
        .fi-sr-badge-percent:before {
    content: "\f17d";
    }
        .fi-sr-badge-sheriff:before {
    content: "\f17e";
    }
        .fi-sr-badge:before {
    content: "\f17f";
    }
        .fi-sr-bags-shopping:before {
    content: "\f180";
    }
        .fi-sr-bahai:before {
    content: "\f181";
    }
        .fi-sr-balance-scale-left:before {
    content: "\f182";
    }
        .fi-sr-balance-scale-right:before {
    content: "\f183";
    }
        .fi-sr-balance-scale:before {
    content: "\f184";
    }
        .fi-sr-balloons:before {
    content: "\f185";
    }
        .fi-sr-ballot:before {
    content: "\f186";
    }
        .fi-sr-ban:before {
    content: "\f187";
    }
        .fi-sr-band-aid:before {
    content: "\f188";
    }
        .fi-sr-bank:before {
    content: "\f189";
    }
        .fi-sr-barber-shop:before {
    content: "\f18a";
    }
        .fi-sr-barcode-read:before {
    content: "\f18b";
    }
        .fi-sr-barcode-scan:before {
    content: "\f18c";
    }
        .fi-sr-barcode:before {
    content: "\f18d";
    }
        .fi-sr-bars-progress:before {
    content: "\f18e";
    }
        .fi-sr-baseball-alt:before {
    content: "\f18f";
    }
        .fi-sr-baseball:before {
    content: "\f190";
    }
        .fi-sr-basketball-hoop:before {
    content: "\f191";
    }
        .fi-sr-basketball:before {
    content: "\f192";
    }
        .fi-sr-bed-alt:before {
    content: "\f193";
    }
        .fi-sr-bed:before {
    content: "\f194";
    }
        .fi-sr-beer:before {
    content: "\f195";
    }
        .fi-sr-bell-ring:before {
    content: "\f196";
    }
        .fi-sr-bell-school:before {
    content: "\f197";
    }
        .fi-sr-bell:before {
    content: "\f198";
    }
        .fi-sr-bike:before {
    content: "\f199";
    }
        .fi-sr-biking-mountain:before {
    content: "\f19a";
    }
        .fi-sr-biking:before {
    content: "\f19b";
    }
        .fi-sr-billiard:before {
    content: "\f19c";
    }
        .fi-sr-blinds-open:before {
    content: "\f19d";
    }
        .fi-sr-blinds-raised:before {
    content: "\f19e";
    }
        .fi-sr-blinds:before {
    content: "\f19f";
    }
        .fi-sr-blossom:before {
    content: "\f1a0";
    }
        .fi-sr-bold:before {
    content: "\f1a1";
    }
        .fi-sr-bolt:before {
    content: "\f1a2";
    }
        .fi-sr-book-alt:before {
    content: "\f1a3";
    }
        .fi-sr-book-arrow-right:before {
    content: "\f1a4";
    }
        .fi-sr-book-arrow-up:before {
    content: "\f1a5";
    }
        .fi-sr-book-bookmark:before {
    content: "\f1a6";
    }
        .fi-sr-book-open-cover:before {
    content: "\f1a7";
    }
        .fi-sr-book-open-reader:before {
    content: "\f1a8";
    }
        .fi-sr-book:before {
    content: "\f1a9";
    }
        .fi-sr-bookmark:before {
    content: "\f1aa";
    }
        .fi-sr-books:before {
    content: "\f1ab";
    }
        .fi-sr-bottle:before {
    content: "\f1ac";
    }
        .fi-sr-bow-arrow:before {
    content: "\f1ad";
    }
        .fi-sr-bowling-ball:before {
    content: "\f1ae";
    }
        .fi-sr-bowling-pins:before {
    content: "\f1af";
    }
        .fi-sr-bowling:before {
    content: "\f1b0";
    }
        .fi-sr-box-alt:before {
    content: "\f1b1";
    }
        .fi-sr-box-ballot:before {
    content: "\f1b2";
    }
        .fi-sr-box-check:before {
    content: "\f1b3";
    }
        .fi-sr-box-tissue:before {
    content: "\f1b4";
    }
        .fi-sr-box:before {
    content: "\f1b5";
    }
        .fi-sr-boxes:before {
    content: "\f1b6";
    }
        .fi-sr-boxing-glove:before {
    content: "\f1b7";
    }
        .fi-sr-braille:before {
    content: "\f1b8";
    }
        .fi-sr-brain-circuit:before {
    content: "\f1b9";
    }
        .fi-sr-bread-slice:before {
    content: "\f1ba";
    }
        .fi-sr-bread:before {
    content: "\f1bb";
    }
        .fi-sr-briefcase:before {
    content: "\f1bc";
    }
        .fi-sr-broccoli:before {
    content: "\f1bd";
    }
        .fi-sr-broom:before {
    content: "\f1be";
    }
        .fi-sr-browser:before {
    content: "\f1bf";
    }
        .fi-sr-brush:before {
    content: "\f1c0";
    }
        .fi-sr-bug:before {
    content: "\f1c1";
    }
        .fi-sr-building:before {
    content: "\f1c2";
    }
        .fi-sr-bulb:before {
    content: "\f1c3";
    }
        .fi-sr-bullseye-pointer:before {
    content: "\f1c4";
    }
        .fi-sr-bullseye:before {
    content: "\f1c5";
    }
        .fi-sr-burrito:before {
    content: "\f1c6";
    }
        .fi-sr-bus-alt:before {
    content: "\f1c7";
    }
        .fi-sr-bus:before {
    content: "\f1c8";
    }
        .fi-sr-business-time:before {
    content: "\f1c9";
    }
        .fi-sr-butterfly:before {
    content: "\f1ca";
    }
        .fi-sr-c:before {
    content: "\f1cb";
    }
        .fi-sr-cake-birthday:before {
    content: "\f1cc";
    }
        .fi-sr-cake-wedding:before {
    content: "\f1cd";
    }
        .fi-sr-calculator:before {
    content: "\f1ce";
    }
        .fi-sr-calendar-check:before {
    content: "\f1cf";
    }
        .fi-sr-calendar-clock:before {
    content: "\f1d0";
    }
        .fi-sr-calendar-exclamation:before {
    content: "\f1d1";
    }
        .fi-sr-calendar-lines-pen:before {
    content: "\f1d2";
    }
        .fi-sr-calendar-lines:before {
    content: "\f1d3";
    }
        .fi-sr-calendar-minus:before {
    content: "\f1d4";
    }
        .fi-sr-calendar-pen:before {
    content: "\f1d5";
    }
        .fi-sr-calendar-plus:before {
    content: "\f1d6";
    }
        .fi-sr-calendar:before {
    content: "\f1d7";
    }
        .fi-sr-calendars:before {
    content: "\f1d8";
    }
        .fi-sr-call-history:before {
    content: "\f1d9";
    }
        .fi-sr-call-incoming:before {
    content: "\f1da";
    }
        .fi-sr-call-missed:before {
    content: "\f1db";
    }
        .fi-sr-call-outgoing:before {
    content: "\f1dc";
    }
        .fi-sr-camcorder:before {
    content: "\f1dd";
    }
        .fi-sr-camera:before {
    content: "\f1de";
    }
        .fi-sr-camping:before {
    content: "\f1df";
    }
        .fi-sr-candy-alt:before {
    content: "\f1e0";
    }
        .fi-sr-candy:before {
    content: "\f1e1";
    }
        .fi-sr-canned-food:before {
    content: "\f1e2";
    }
        .fi-sr-car-alt:before {
    content: "\f1e3";
    }
        .fi-sr-car-battery:before {
    content: "\f1e4";
    }
        .fi-sr-car-bolt:before {
    content: "\f1e5";
    }
        .fi-sr-car-building:before {
    content: "\f1e6";
    }
        .fi-sr-car-bump:before {
    content: "\f1e7";
    }
        .fi-sr-car-bus:before {
    content: "\f1e8";
    }
        .fi-sr-car-circle-bolt:before {
    content: "\f1e9";
    }
        .fi-sr-car-crash:before {
    content: "\f1ea";
    }
        .fi-sr-car-garage:before {
    content: "\f1eb";
    }
        .fi-sr-car-mechanic:before {
    content: "\f1ec";
    }
        .fi-sr-car-side-bolt:before {
    content: "\f1ed";
    }
        .fi-sr-car-side:before {
    content: "\f1ee";
    }
        .fi-sr-car-tilt:before {
    content: "\f1ef";
    }
        .fi-sr-car-wash:before {
    content: "\f1f0";
    }
        .fi-sr-car:before {
    content: "\f1f1";
    }
        .fi-sr-caravan-alt:before {
    content: "\f1f2";
    }
        .fi-sr-caravan:before {
    content: "\f1f3";
    }
        .fi-sr-caret-circle-down:before {
    content: "\f1f4";
    }
        .fi-sr-caret-circle-right:before {
    content: "\f1f5";
    }
        .fi-sr-caret-circle-up:before {
    content: "\f1f6";
    }
        .fi-sr-caret-down:before {
    content: "\f1f7";
    }
        .fi-sr-caret-left:before {
    content: "\f1f8";
    }
        .fi-sr-caret-quare-up:before {
    content: "\f1f9";
    }
        .fi-sr-caret-right:before {
    content: "\f1fa";
    }
        .fi-sr-caret-square-down:before {
    content: "\f1fb";
    }
        .fi-sr-caret-square-left_1:before {
    content: "\f1fc";
    }
        .fi-sr-caret-square-left:before {
    content: "\f1fd";
    }
        .fi-sr-caret-square-right:before {
    content: "\f1fe";
    }
        .fi-sr-caret-up:before {
    content: "\f1ff";
    }
        .fi-sr-carrot:before {
    content: "\f200";
    }
        .fi-sr-cars:before {
    content: "\f201";
    }
        .fi-sr-cash-register:before {
    content: "\f202";
    }
        .fi-sr-chair-office:before {
    content: "\f203";
    }
        .fi-sr-chair:before {
    content: "\f204";
    }
        .fi-sr-charging-station:before {
    content: "\f205";
    }
        .fi-sr-chart-area:before {
    content: "\f206";
    }
        .fi-sr-chart-connected:before {
    content: "\f207";
    }
        .fi-sr-chart-histogram:before {
    content: "\f208";
    }
        .fi-sr-chart-line-up:before {
    content: "\f209";
    }
        .fi-sr-chart-network:before {
    content: "\f20a";
    }
        .fi-sr-chart-pie-alt:before {
    content: "\f20b";
    }
        .fi-sr-chart-pie:before {
    content: "\f20c";
    }
        .fi-sr-chart-pyramid:before {
    content: "\f20d";
    }
        .fi-sr-chart-scatter:before {
    content: "\f20e";
    }
        .fi-sr-chart-set-theory:before {
    content: "\f20f";
    }
        .fi-sr-chart-tree:before {
    content: "\f210";
    }
        .fi-sr-chat-arrow-down:before {
    content: "\f211";
    }
        .fi-sr-chat-arrow-grow:before {
    content: "\f212";
    }
        .fi-sr-check:before {
    content: "\f213";
    }
        .fi-sr-checkbox:before {
    content: "\f214";
    }
        .fi-sr-cheese:before {
    content: "\f215";
    }
        .fi-sr-cherry:before {
    content: "\f216";
    }
        .fi-sr-chess-bishop:before {
    content: "\f217";
    }
        .fi-sr-chess-board:before {
    content: "\f218";
    }
        .fi-sr-chess-clock-alt:before {
    content: "\f219";
    }
        .fi-sr-chess-clock:before {
    content: "\f21a";
    }
        .fi-sr-chess-king-alt:before {
    content: "\f21b";
    }
        .fi-sr-chess-king:before {
    content: "\f21c";
    }
        .fi-sr-chess-knight-alt:before {
    content: "\f21d";
    }
        .fi-sr-chess-knight:before {
    content: "\f21e";
    }
        .fi-sr-chess-pawn-alt:before {
    content: "\f21f";
    }
        .fi-sr-chess-pawn:before {
    content: "\f220";
    }
        .fi-sr-chess-piece:before {
    content: "\f221";
    }
        .fi-sr-chess-queen-alt:before {
    content: "\f222";
    }
        .fi-sr-chess-queen:before {
    content: "\f223";
    }
        .fi-sr-chess-rook-alt:before {
    content: "\f224";
    }
        .fi-sr-chess-rook:before {
    content: "\f225";
    }
        .fi-sr-chess:before {
    content: "\f226";
    }
        .fi-sr-chevron-double-down:before {
    content: "\f227";
    }
        .fi-sr-chevron-double-up:before {
    content: "\f228";
    }
        .fi-sr-child-head:before {
    content: "\f229";
    }
        .fi-sr-chocolate:before {
    content: "\f22a";
    }
        .fi-sr-circle-envelope:before {
    content: "\f22b";
    }
        .fi-sr-circle-phone-flip:before {
    content: "\f22c";
    }
        .fi-sr-circle-phone-hangup:before {
    content: "\f22d";
    }
        .fi-sr-circle-phone:before {
    content: "\f22e";
    }
        .fi-sr-circle-small:before {
    content: "\f22f";
    }
        .fi-sr-circle:before {
    content: "\f230";
    }
        .fi-sr-clip:before {
    content: "\f231";
    }
        .fi-sr-clipboard-list-check:before {
    content: "\f232";
    }
        .fi-sr-clipboard-list:before {
    content: "\f233";
    }
        .fi-sr-clock-eight-thirty:before {
    content: "\f234";
    }
        .fi-sr-clock-eleven-thirty:before {
    content: "\f235";
    }
        .fi-sr-clock-eleven:before {
    content: "\f236";
    }
        .fi-sr-clock-five-thirty:before {
    content: "\f237";
    }
        .fi-sr-clock-five:before {
    content: "\f238";
    }
        .fi-sr-clock-four-thirty:before {
    content: "\f239";
    }
        .fi-sr-clock-nine-thirty:before {
    content: "\f23a";
    }
        .fi-sr-clock-nine:before {
    content: "\f23b";
    }
        .fi-sr-clock-one-thirty:before {
    content: "\f23c";
    }
        .fi-sr-clock-one:before {
    content: "\f23d";
    }
        .fi-sr-clock-seven-thirty:before {
    content: "\f23e";
    }
        .fi-sr-clock-seven:before {
    content: "\f23f";
    }
        .fi-sr-clock-six-thirty:before {
    content: "\f240";
    }
        .fi-sr-clock-six:before {
    content: "\f241";
    }
        .fi-sr-clock-ten-thirty:before {
    content: "\f242";
    }
        .fi-sr-clock-ten:before {
    content: "\f243";
    }
        .fi-sr-clock-three-thirty:before {
    content: "\f244";
    }
        .fi-sr-clock-three:before {
    content: "\f245";
    }
        .fi-sr-clock-twelve-thirty:before {
    content: "\f246";
    }
        .fi-sr-clock-twelve:before {
    content: "\f247";
    }
        .fi-sr-clock-two-thirty:before {
    content: "\f248";
    }
        .fi-sr-clock-two:before {
    content: "\f249";
    }
        .fi-sr-clock:before {
    content: "\f24a";
    }
        .fi-sr-cloud-check:before {
    content: "\f24b";
    }
        .fi-sr-cloud-disabled:before {
    content: "\f24c";
    }
        .fi-sr-cloud-download-alt:before {
    content: "\f24d";
    }
        .fi-sr-cloud-download:before {
    content: "\f24e";
    }
        .fi-sr-cloud-drizzle:before {
    content: "\f24f";
    }
        .fi-sr-cloud-hail-mixed:before {
    content: "\f250";
    }
        .fi-sr-cloud-hail:before {
    content: "\f251";
    }
        .fi-sr-cloud-moon-rain:before {
    content: "\f252";
    }
        .fi-sr-cloud-moon:before {
    content: "\f253";
    }
        .fi-sr-cloud-rain:before {
    content: "\f254";
    }
        .fi-sr-cloud-rainbow:before {
    content: "\f255";
    }
        .fi-sr-cloud-share:before {
    content: "\f256";
    }
        .fi-sr-cloud-showers-heavy:before {
    content: "\f257";
    }
        .fi-sr-cloud-showers:before {
    content: "\f258";
    }
        .fi-sr-cloud-sleet:before {
    content: "\f259";
    }
        .fi-sr-cloud-snow:before {
    content: "\f25a";
    }
        .fi-sr-cloud-sun-rain:before {
    content: "\f25b";
    }
        .fi-sr-cloud-sun:before {
    content: "\f25c";
    }
        .fi-sr-cloud-upload-alt:before {
    content: "\f25d";
    }
        .fi-sr-cloud-upload:before {
    content: "\f25e";
    }
        .fi-sr-cloud:before {
    content: "\f25f";
    }
        .fi-sr-clouds-moon:before {
    content: "\f260";
    }
        .fi-sr-clouds-sun:before {
    content: "\f261";
    }
        .fi-sr-clouds:before {
    content: "\f262";
    }
        .fi-sr-club:before {
    content: "\f263";
    }
        .fi-sr-cocktail-alt:before {
    content: "\f264";
    }
        .fi-sr-cocktail:before {
    content: "\f265";
    }
        .fi-sr-code-branch:before {
    content: "\f266";
    }
        .fi-sr-code-commit:before {
    content: "\f267";
    }
        .fi-sr-code-compare:before {
    content: "\f268";
    }
        .fi-sr-code-fork:before {
    content: "\f269";
    }
        .fi-sr-code-merge:before {
    content: "\f26a";
    }
        .fi-sr-code-pull-request-closed:before {
    content: "\f26b";
    }
        .fi-sr-code-pull-request-draft:before {
    content: "\f26c";
    }
        .fi-sr-code-pull-request:before {
    content: "\f26d";
    }
        .fi-sr-code-simple:before {
    content: "\f26e";
    }
        .fi-sr-coffee-pot:before {
    content: "\f26f";
    }
        .fi-sr-coffee:before {
    content: "\f270";
    }
        .fi-sr-coin:before {
    content: "\f271";
    }
        .fi-sr-coins:before {
    content: "\f272";
    }
        .fi-sr-comet:before {
    content: "\f273";
    }
        .fi-sr-comment-alt-middle-top:before {
    content: "\f274";
    }
        .fi-sr-comment-alt-middle:before {
    content: "\f275";
    }
        .fi-sr-comment-alt:before {
    content: "\f276";
    }
        .fi-sr-comment-arrow-down:before {
    content: "\f277";
    }
        .fi-sr-comment-arrow-up-right:before {
    content: "\f278";
    }
        .fi-sr-comment-arrow-up:before {
    content: "\f279";
    }
        .fi-sr-comment-check:before {
    content: "\f27a";
    }
        .fi-sr-comment-code:before {
    content: "\f27b";
    }
        .fi-sr-comment-dollar:before {
    content: "\f27c";
    }
        .fi-sr-comment-exclamation:before {
    content: "\f27d";
    }
        .fi-sr-comment-heart:before {
    content: "\f27e";
    }
        .fi-sr-comment-image:before {
    content: "\f27f";
    }
        .fi-sr-comment-info:before {
    content: "\f280";
    }
        .fi-sr-comment-pen:before {
    content: "\f281";
    }
        .fi-sr-comment-question:before {
    content: "\f282";
    }
        .fi-sr-comment-quote:before {
    content: "\f283";
    }
        .fi-sr-comment-slash:before {
    content: "\f284";
    }
        .fi-sr-comment-sms:before {
    content: "\f285";
    }
        .fi-sr-comment-text:before {
    content: "\f286";
    }
        .fi-sr-comment-user:before {
    content: "\f287";
    }
        .fi-sr-comment-xmark:before {
    content: "\f288";
    }
        .fi-sr-comment:before {
    content: "\f289";
    }
        .fi-sr-comments-dollar:before {
    content: "\f28a";
    }
        .fi-sr-comments-question-check:before {
    content: "\f28b";
    }
        .fi-sr-comments-question:before {
    content: "\f28c";
    }
        .fi-sr-comments:before {
    content: "\f28d";
    }
        .fi-sr-compress-alt:before {
    content: "\f28e";
    }
        .fi-sr-compress:before {
    content: "\f28f";
    }
        .fi-sr-computer:before {
    content: "\f290";
    }
        .fi-sr-confetti:before {
    content: "\f291";
    }
        .fi-sr-cookie:before {
    content: "\f292";
    }
        .fi-sr-copy-alt:before {
    content: "\f293";
    }
        .fi-sr-copy:before {
    content: "\f294";
    }
        .fi-sr-copyright:before {
    content: "\f295";
    }
        .fi-sr-corn:before {
    content: "\f296";
    }
        .fi-sr-cow:before {
    content: "\f297";
    }
        .fi-sr-cream:before {
    content: "\f298";
    }
        .fi-sr-credit-card:before {
    content: "\f299";
    }
        .fi-sr-cricket:before {
    content: "\f29a";
    }
        .fi-sr-croissant:before {
    content: "\f29b";
    }
        .fi-sr-cross-circle:before {
    content: "\f29c";
    }
        .fi-sr-cross-small:before {
    content: "\f29d";
    }
        .fi-sr-cross:before {
    content: "\f29e";
    }
        .fi-sr-crown:before {
    content: "\f29f";
    }
        .fi-sr-crystal-ball:before {
    content: "\f2a0";
    }
        .fi-sr-cube:before {
    content: "\f2a1";
    }
        .fi-sr-cupcake:before {
    content: "\f2a2";
    }
        .fi-sr-curling:before {
    content: "\f2a3";
    }
        .fi-sr-cursor-finger:before {
    content: "\f2a4";
    }
        .fi-sr-cursor-plus:before {
    content: "\f2a5";
    }
        .fi-sr-cursor-text-alt:before {
    content: "\f2a6";
    }
        .fi-sr-cursor-text:before {
    content: "\f2a7";
    }
        .fi-sr-cursor:before {
    content: "\f2a8";
    }
        .fi-sr-d:before {
    content: "\f2a9";
    }
        .fi-sr-dart:before {
    content: "\f2aa";
    }
        .fi-sr-dashboard:before {
    content: "\f2ab";
    }
        .fi-sr-data-transfer:before {
    content: "\f2ac";
    }
        .fi-sr-database:before {
    content: "\f2ad";
    }
        .fi-sr-delete-document:before {
    content: "\f2ae";
    }
        .fi-sr-delete-user:before {
    content: "\f2af";
    }
        .fi-sr-delete:before {
    content: "\f2b0";
    }
        .fi-sr-democrat:before {
    content: "\f2b1";
    }
        .fi-sr-dewpoint:before {
    content: "\f2b2";
    }
        .fi-sr-diagram-project:before {
    content: "\f2b3";
    }
        .fi-sr-diamond:before {
    content: "\f2b4";
    }
        .fi-sr-dice-alt:before {
    content: "\f2b5";
    }
        .fi-sr-dice-d10:before {
    content: "\f2b6";
    }
        .fi-sr-dice-d12:before {
    content: "\f2b7";
    }
        .fi-sr-dice-d20:before {
    content: "\f2b8";
    }
        .fi-sr-dice-d4:before {
    content: "\f2b9";
    }
        .fi-sr-dice-d6:before {
    content: "\f2ba";
    }
        .fi-sr-dice-d8:before {
    content: "\f2bb";
    }
        .fi-sr-dice-four:before {
    content: "\f2bc";
    }
        .fi-sr-dice-one:before {
    content: "\f2bd";
    }
        .fi-sr-dice-six:before {
    content: "\f2be";
    }
        .fi-sr-dice-three:before {
    content: "\f2bf";
    }
        .fi-sr-dice-two:before {
    content: "\f2c0";
    }
        .fi-sr-dice:before {
    content: "\f2c1";
    }
        .fi-sr-diploma:before {
    content: "\f2c2";
    }
        .fi-sr-disk:before {
    content: "\f2c3";
    }
        .fi-sr-display-code:before {
    content: "\f2c4";
    }
        .fi-sr-dizzy:before {
    content: "\f2c5";
    }
        .fi-sr-doctor:before {
    content: "\f2c6";
    }
        .fi-sr-document-signed:before {
    content: "\f2c7";
    }
        .fi-sr-document:before {
    content: "\f2c8";
    }
        .fi-sr-dollar:before {
    content: "\f2c9";
    }
        .fi-sr-donate:before {
    content: "\f2ca";
    }
        .fi-sr-down-left-and-up-right-to-center:before {
    content: "\f2cb";
    }
        .fi-sr-download:before {
    content: "\f2cc";
    }
        .fi-sr-drafting-compass:before {
    content: "\f2cd";
    }
        .fi-sr-dreidel:before {
    content: "\f2ce";
    }
        .fi-sr-drink-alt:before {
    content: "\f2cf";
    }
        .fi-sr-drumstick:before {
    content: "\f2d0";
    }
        .fi-sr-duplicate:before {
    content: "\f2d1";
    }
        .fi-sr-e-learning:before {
    content: "\f2d2";
    }
        .fi-sr-e:before {
    content: "\f2d3";
    }
        .fi-sr-earnings:before {
    content: "\f2d4";
    }
        .fi-sr-eclipse-alt:before {
    content: "\f2d5";
    }
        .fi-sr-eclipse:before {
    content: "\f2d6";
    }
        .fi-sr-edit-alt:before {
    content: "\f2d7";
    }
        .fi-sr-edit:before {
    content: "\f2d8";
    }
        .fi-sr-egg-fried:before {
    content: "\f2d9";
    }
        .fi-sr-egg:before {
    content: "\f2da";
    }
        .fi-sr-engine-warning:before {
    content: "\f2db";
    }
        .fi-sr-enter:before {
    content: "\f2dc";
    }
        .fi-sr-entertainment:before {
    content: "\f2dd";
    }
        .fi-sr-envelope-ban:before {
    content: "\f2de";
    }
        .fi-sr-envelope-bulk:before {
    content: "\f2df";
    }
        .fi-sr-envelope-download:before {
    content: "\f2e0";
    }
        .fi-sr-envelope-marker:before {
    content: "\f2e1";
    }
        .fi-sr-envelope-open-dollar:before {
    content: "\f2e2";
    }
        .fi-sr-envelope-open-text:before {
    content: "\f2e3";
    }
        .fi-sr-envelope-open:before {
    content: "\f2e4";
    }
        .fi-sr-envelope-plus:before {
    content: "\f2e5";
    }
        .fi-sr-envelope:before {
    content: "\f2e6";
    }
        .fi-sr-equipment:before {
    content: "\f2e7";
    }
        .fi-sr-euro:before {
    content: "\f2e8";
    }
        .fi-sr-exchange-alt:before {
    content: "\f2e9";
    }
        .fi-sr-exchange:before {
    content: "\f2ea";
    }
        .fi-sr-exclamation:before {
    content: "\f2eb";
    }
        .fi-sr-exit:before {
    content: "\f2ec";
    }
        .fi-sr-expand-arrows-alt:before {
    content: "\f2ed";
    }
        .fi-sr-expand-arrows:before {
    content: "\f2ee";
    }
        .fi-sr-expand:before {
    content: "\f2ef";
    }
        .fi-sr-eye-crossed:before {
    content: "\f2f0";
    }
        .fi-sr-eye:before {
    content: "\f2f1";
    }
        .fi-sr-f:before {
    content: "\f2f2";
    }
        .fi-sr-feather:before {
    content: "\f2f3";
    }
        .fi-sr-field-hockey:before {
    content: "\f2f4";
    }
        .fi-sr-fighter-jet:before {
    content: "\f2f5";
    }
        .fi-sr-file-ai:before {
    content: "\f2f6";
    }
        .fi-sr-file-chart-line:before {
    content: "\f2f7";
    }
        .fi-sr-file-chart-pie:before {
    content: "\f2f8";
    }
        .fi-sr-file-code:before {
    content: "\f2f9";
    }
        .fi-sr-file-eps:before {
    content: "\f2fa";
    }
        .fi-sr-file-invoice-dollar:before {
    content: "\f2fb";
    }
        .fi-sr-file-invoice:before {
    content: "\f2fc";
    }
        .fi-sr-file-psd:before {
    content: "\f2fd";
    }
        .fi-sr-file-spreadsheet:before {
    content: "\f2fe";
    }
        .fi-sr-file-video:before {
    content: "\f2ff";
    }
        .fi-sr-file:before {
    content: "\f300";
    }
        .fi-sr-fill:before {
    content: "\f301";
    }
        .fi-sr-film-slash:before {
    content: "\f302";
    }
        .fi-sr-film:before {
    content: "\f303";
    }
        .fi-sr-filter-slash:before {
    content: "\f304";
    }
        .fi-sr-filter:before {
    content: "\f305";
    }
        .fi-sr-filters:before {
    content: "\f306";
    }
        .fi-sr-fingerprint:before {
    content: "\f307";
    }
        .fi-sr-fish:before {
    content: "\f308";
    }
        .fi-sr-flag-alt:before {
    content: "\f309";
    }
        .fi-sr-flag-usa:before {
    content: "\f30a";
    }
        .fi-sr-flag:before {
    content: "\f30b";
    }
        .fi-sr-flame:before {
    content: "\f30c";
    }
        .fi-sr-flip-horizontal:before {
    content: "\f30d";
    }
        .fi-sr-flower-tulip:before {
    content: "\f30e";
    }
        .fi-sr-flower:before {
    content: "\f30f";
    }
        .fi-sr-flushed:before {
    content: "\f310";
    }
        .fi-sr-fog:before {
    content: "\f311";
    }
        .fi-sr-folder-download:before {
    content: "\f312";
    }
        .fi-sr-folder-minus:before {
    content: "\f313";
    }
        .fi-sr-folder-times:before {
    content: "\f314";
    }
        .fi-sr-folder-tree:before {
    content: "\f315";
    }
        .fi-sr-folder-upload:before {
    content: "\f316";
    }
        .fi-sr-folder:before {
    content: "\f317";
    }
        .fi-sr-folders:before {
    content: "\f318";
    }
        .fi-sr-following:before {
    content: "\f319";
    }
        .fi-sr-football:before {
    content: "\f31a";
    }
        .fi-sr-fork:before {
    content: "\f31b";
    }
        .fi-sr-form:before {
    content: "\f31c";
    }
        .fi-sr-forward:before {
    content: "\f31d";
    }
        .fi-sr-fox:before {
    content: "\f31e";
    }
        .fi-sr-french-fries:before {
    content: "\f31f";
    }
        .fi-sr-frown:before {
    content: "\f320";
    }
        .fi-sr-ftp:before {
    content: "\f321";
    }
        .fi-sr-funnel-dollar:before {
    content: "\f322";
    }
        .fi-sr-g:before {
    content: "\f323";
    }
        .fi-sr-gallery:before {
    content: "\f324";
    }
        .fi-sr-game-board-alt:before {
    content: "\f325";
    }
        .fi-sr-game:before {
    content: "\f326";
    }
        .fi-sr-gamepad:before {
    content: "\f327";
    }
        .fi-sr-games:before {
    content: "\f328";
    }
        .fi-sr-garage-car:before {
    content: "\f329";
    }
        .fi-sr-garage-open:before {
    content: "\f32a";
    }
        .fi-sr-garage:before {
    content: "\f32b";
    }
        .fi-sr-garlic:before {
    content: "\f32c";
    }
        .fi-sr-gas-pump-alt:before {
    content: "\f32d";
    }
        .fi-sr-gas-pump-slash:before {
    content: "\f32e";
    }
        .fi-sr-gas-pump:before {
    content: "\f32f";
    }
        .fi-sr-gem:before {
    content: "\f330";
    }
        .fi-sr-gif:before {
    content: "\f331";
    }
        .fi-sr-gift-card:before {
    content: "\f332";
    }
        .fi-sr-gift:before {
    content: "\f333";
    }
        .fi-sr-gifts:before {
    content: "\f334";
    }
        .fi-sr-gingerbread-man:before {
    content: "\f335";
    }
        .fi-sr-glass-cheers:before {
    content: "\f336";
    }
        .fi-sr-glass:before {
    content: "\f337";
    }
        .fi-sr-glasses:before {
    content: "\f338";
    }
        .fi-sr-globe-alt:before {
    content: "\f339";
    }
        .fi-sr-globe:before {
    content: "\f33a";
    }
        .fi-sr-golf-ball:before {
    content: "\f33b";
    }
        .fi-sr-golf-club:before {
    content: "\f33c";
    }
        .fi-sr-golf:before {
    content: "\f33d";
    }
        .fi-sr-graduation-cap:before {
    content: "\f33e";
    }
        .fi-sr-grape:before {
    content: "\f33f";
    }
        .fi-sr-graphic-tablet:before {
    content: "\f340";
    }
        .fi-sr-grid-alt:before {
    content: "\f341";
    }
        .fi-sr-grid:before {
    content: "\f342";
    }
        .fi-sr-grill:before {
    content: "\f343";
    }
        .fi-sr-grimace:before {
    content: "\f344";
    }
        .fi-sr-grin-alt:before {
    content: "\f345";
    }
        .fi-sr-grin-beam-sweat:before {
    content: "\f346";
    }
        .fi-sr-grin-beam:before {
    content: "\f347";
    }
        .fi-sr-grin-hearts:before {
    content: "\f348";
    }
        .fi-sr-grin-squint-tears:before {
    content: "\f349";
    }
        .fi-sr-grin-squint:before {
    content: "\f34a";
    }
        .fi-sr-grin-stars:before {
    content: "\f34b";
    }
        .fi-sr-grin-tears:before {
    content: "\f34c";
    }
        .fi-sr-grin-tongue-squint:before {
    content: "\f34d";
    }
        .fi-sr-grin-tongue-wink:before {
    content: "\f34e";
    }
        .fi-sr-grin-tongue:before {
    content: "\f34f";
    }
        .fi-sr-grin-wink:before {
    content: "\f350";
    }
        .fi-sr-grin:before {
    content: "\f351";
    }
        .fi-sr-guitar:before {
    content: "\f352";
    }
        .fi-sr-gym:before {
    content: "\f353";
    }
        .fi-sr-h:before {
    content: "\f354";
    }
        .fi-sr-hamburger-soda:before {
    content: "\f355";
    }
        .fi-sr-hamburger:before {
    content: "\f356";
    }
        .fi-sr-hand-holding-box:before {
    content: "\f357";
    }
        .fi-sr-hand-holding-heart:before {
    content: "\f358";
    }
        .fi-sr-hand-holding-seeding:before {
    content: "\f359";
    }
        .fi-sr-handshake:before {
    content: "\f35a";
    }
        .fi-sr-hastag:before {
    content: "\f35b";
    }
        .fi-sr-hat-birthday:before {
    content: "\f35c";
    }
        .fi-sr-hat-chef:before {
    content: "\f35d";
    }
        .fi-sr-head-side-thinking:before {
    content: "\f35e";
    }
        .fi-sr-headphones:before {
    content: "\f35f";
    }
        .fi-sr-headset:before {
    content: "\f360";
    }
        .fi-sr-heart-arrow:before {
    content: "\f361";
    }
        .fi-sr-heart:before {
    content: "\f362";
    }
        .fi-sr-heat:before {
    content: "\f363";
    }
        .fi-sr-helicopter-side:before {
    content: "\f364";
    }
        .fi-sr-highlighter:before {
    content: "\f365";
    }
        .fi-sr-hiking:before {
    content: "\f366";
    }
        .fi-sr-hockey-mask:before {
    content: "\f367";
    }
        .fi-sr-hockey-puck:before {
    content: "\f368";
    }
        .fi-sr-hockey-sticks:before {
    content: "\f369";
    }
        .fi-sr-home-location-alt:before {
    content: "\f36a";
    }
        .fi-sr-home-location:before {
    content: "\f36b";
    }
        .fi-sr-home:before {
    content: "\f36c";
    }
        .fi-sr-hotdog:before {
    content: "\f36d";
    }
        .fi-sr-hourglass-end:before {
    content: "\f36e";
    }
        .fi-sr-hourglass:before {
    content: "\f36f";
    }
        .fi-sr-house-flood:before {
    content: "\f370";
    }
        .fi-sr-hryvnia:before {
    content: "\f371";
    }
        .fi-sr-humidity:before {
    content: "\f372";
    }
        .fi-sr-hurricane:before {
    content: "\f373";
    }
        .fi-sr-i:before {
    content: "\f374";
    }
        .fi-sr-ice-cream:before {
    content: "\f375";
    }
        .fi-sr-id-badge:before {
    content: "\f376";
    }
        .fi-sr-inbox-in:before {
    content: "\f377";
    }
        .fi-sr-inbox-out:before {
    content: "\f378";
    }
        .fi-sr-inbox:before {
    content: "\f379";
    }
        .fi-sr-incognito:before {
    content: "\f37a";
    }
        .fi-sr-indent:before {
    content: "\f37b";
    }
        .fi-sr-infinity:before {
    content: "\f37c";
    }
        .fi-sr-info:before {
    content: "\f37d";
    }
        .fi-sr-interactive:before {
    content: "\f37e";
    }
        .fi-sr-interlining:before {
    content: "\f37f";
    }
        .fi-sr-interrogation:before {
    content: "\f380";
    }
        .fi-sr-italic:before {
    content: "\f381";
    }
        .fi-sr-j:before {
    content: "\f382";
    }
        .fi-sr-jam:before {
    content: "\f383";
    }
        .fi-sr-jpg:before {
    content: "\f384";
    }
        .fi-sr-k:before {
    content: "\f385";
    }
        .fi-sr-kerning:before {
    content: "\f386";
    }
        .fi-sr-key:before {
    content: "\f387";
    }
        .fi-sr-keyboard:before {
    content: "\f388";
    }
        .fi-sr-keynote:before {
    content: "\f389";
    }
        .fi-sr-kiss-beam:before {
    content: "\f38a";
    }
        .fi-sr-kiss-wink-heart:before {
    content: "\f38b";
    }
        .fi-sr-kiss:before {
    content: "\f38c";
    }
        .fi-sr-kite:before {
    content: "\f38d";
    }
        .fi-sr-knife:before {
    content: "\f38e";
    }
        .fi-sr-l:before {
    content: "\f38f";
    }
        .fi-sr-label:before {
    content: "\f390";
    }
        .fi-sr-laptop-code:before {
    content: "\f391";
    }
        .fi-sr-laptop:before {
    content: "\f392";
    }
        .fi-sr-lasso:before {
    content: "\f393";
    }
        .fi-sr-laugh-beam:before {
    content: "\f394";
    }
        .fi-sr-laugh-squint:before {
    content: "\f395";
    }
        .fi-sr-laugh-wink:before {
    content: "\f396";
    }
        .fi-sr-laugh:before {
    content: "\f397";
    }
        .fi-sr-layer-minus:before {
    content: "\f398";
    }
        .fi-sr-layer-plus:before {
    content: "\f399";
    }
        .fi-sr-layers:before {
    content: "\f39a";
    }
        .fi-sr-layout-fluid:before {
    content: "\f39b";
    }
        .fi-sr-leaf:before {
    content: "\f39c";
    }
        .fi-sr-lemon:before {
    content: "\f39d";
    }
        .fi-sr-letter-case:before {
    content: "\f39e";
    }
        .fi-sr-lettuce:before {
    content: "\f39f";
    }
        .fi-sr-level-down-alt:before {
    content: "\f3a0";
    }
        .fi-sr-level-down:before {
    content: "\f3a1";
    }
        .fi-sr-level-up-alt:before {
    content: "\f3a2";
    }
        .fi-sr-level-up:before {
    content: "\f3a3";
    }
        .fi-sr-life-ring:before {
    content: "\f3a4";
    }
        .fi-sr-lightbulb-dollar:before {
    content: "\f3a5";
    }
        .fi-sr-line-width:before {
    content: "\f3a6";
    }
        .fi-sr-link-alt:before {
    content: "\f3a7";
    }
        .fi-sr-link-horizontal-slash:before {
    content: "\f3a8";
    }
        .fi-sr-link-horizontal:before {
    content: "\f3a9";
    }
        .fi-sr-link-slash-alt:before {
    content: "\f3aa";
    }
        .fi-sr-link-slash:before {
    content: "\f3ab";
    }
        .fi-sr-link:before {
    content: "\f3ac";
    }
        .fi-sr-lipstick:before {
    content: "\f3ad";
    }
        .fi-sr-lira-sign:before {
    content: "\f3ae";
    }
        .fi-sr-list-check:before {
    content: "\f3af";
    }
        .fi-sr-list:before {
    content: "\f3b0";
    }
        .fi-sr-loading:before {
    content: "\f3b1";
    }
        .fi-sr-location-alt:before {
    content: "\f3b2";
    }
        .fi-sr-lock-alt:before {
    content: "\f3b3";
    }
        .fi-sr-lock:before {
    content: "\f3b4";
    }
        .fi-sr-luchador:before {
    content: "\f3b5";
    }
        .fi-sr-luggage-rolling:before {
    content: "\f3b6";
    }
        .fi-sr-m:before {
    content: "\f3b7";
    }
        .fi-sr-mailbox:before {
    content: "\f3b8";
    }
        .fi-sr-makeup-brush:before {
    content: "\f3b9";
    }
        .fi-sr-man-head:before {
    content: "\f3ba";
    }
        .fi-sr-map-marker-cross:before {
    content: "\f3bb";
    }
        .fi-sr-map-marker-home:before {
    content: "\f3bc";
    }
        .fi-sr-map-marker-minus:before {
    content: "\f3bd";
    }
        .fi-sr-map-marker-plus:before {
    content: "\f3be";
    }
        .fi-sr-map-marker:before {
    content: "\f3bf";
    }
        .fi-sr-map:before {
    content: "\f3c0";
    }
        .fi-sr-marker-time:before {
    content: "\f3c1";
    }
        .fi-sr-marker:before {
    content: "\f3c2";
    }
        .fi-sr-mars-double:before {
    content: "\f3c3";
    }
        .fi-sr-mars:before {
    content: "\f3c4";
    }
        .fi-sr-mask-carnival:before {
    content: "\f3c5";
    }
        .fi-sr-medicine:before {
    content: "\f3c6";
    }
        .fi-sr-megaphone:before {
    content: "\f3c7";
    }
        .fi-sr-meh-blank:before {
    content: "\f3c8";
    }
        .fi-sr-meh-rolling-eyes:before {
    content: "\f3c9";
    }
        .fi-sr-meh:before {
    content: "\f3ca";
    }
        .fi-sr-melon:before {
    content: "\f3cb";
    }
        .fi-sr-menu-burger:before {
    content: "\f3cc";
    }
        .fi-sr-menu-dots-vertical:before {
    content: "\f3cd";
    }
        .fi-sr-menu-dots:before {
    content: "\f3ce";
    }
        .fi-sr-message-code:before {
    content: "\f3cf";
    }
        .fi-sr-meteor:before {
    content: "\f3d0";
    }
        .fi-sr-microphone-alt:before {
    content: "\f3d1";
    }
        .fi-sr-microphone:before {
    content: "\f3d2";
    }
        .fi-sr-mind-share:before {
    content: "\f3d3";
    }
        .fi-sr-minus-small:before {
    content: "\f3d4";
    }
        .fi-sr-minus:before {
    content: "\f3d5";
    }
        .fi-sr-mobile-button:before {
    content: "\f3d6";
    }
        .fi-sr-mobile-notch:before {
    content: "\f3d7";
    }
        .fi-sr-mobile:before {
    content: "\f3d8";
    }
        .fi-sr-mode-landscape:before {
    content: "\f3d9";
    }
        .fi-sr-mode-portrait:before {
    content: "\f3da";
    }
        .fi-sr-money-bill-wave-alt:before {
    content: "\f3db";
    }
        .fi-sr-money-bill-wave:before {
    content: "\f3dc";
    }
        .fi-sr-money-check-edit-alt:before {
    content: "\f3dd";
    }
        .fi-sr-money-check-edit:before {
    content: "\f3de";
    }
        .fi-sr-money-check:before {
    content: "\f3df";
    }
        .fi-sr-money:before {
    content: "\f3e0";
    }
        .fi-sr-moon-stars:before {
    content: "\f3e1";
    }
        .fi-sr-moon:before {
    content: "\f3e2";
    }
        .fi-sr-motorcycle:before {
    content: "\f3e3";
    }
        .fi-sr-mountains:before {
    content: "\f3e4";
    }
        .fi-sr-mouse:before {
    content: "\f3e5";
    }
        .fi-sr-mug-alt:before {
    content: "\f3e6";
    }
        .fi-sr-mug-hot-alt:before {
    content: "\f3e7";
    }
        .fi-sr-mug-hot:before {
    content: "\f3e8";
    }
        .fi-sr-mug-tea:before {
    content: "\f3e9";
    }
        .fi-sr-mug:before {
    content: "\f3ea";
    }
        .fi-sr-mushroom:before {
    content: "\f3eb";
    }
        .fi-sr-music-alt:before {
    content: "\f3ec";
    }
        .fi-sr-music-file:before {
    content: "\f3ed";
    }
        .fi-sr-music:before {
    content: "\f3ee";
    }
        .fi-sr-n:before {
    content: "\f3ef";
    }
        .fi-sr-navigation:before {
    content: "\f3f0";
    }
        .fi-sr-network-cloud:before {
    content: "\f3f1";
    }
        .fi-sr-network:before {
    content: "\f3f2";
    }
        .fi-sr-noodles:before {
    content: "\f3f3";
    }
        .fi-sr-notebook:before {
    content: "\f3f4";
    }
        .fi-sr-o:before {
    content: "\f3f5";
    }
        .fi-sr-oil-can:before {
    content: "\f3f6";
    }
        .fi-sr-oil-temp:before {
    content: "\f3f7";
    }
        .fi-sr-olive-oil:before {
    content: "\f3f8";
    }
        .fi-sr-olives:before {
    content: "\f3f9";
    }
        .fi-sr-onion:before {
    content: "\f3fa";
    }
        .fi-sr-opacity:before {
    content: "\f3fb";
    }
        .fi-sr-overline:before {
    content: "\f3fc";
    }
        .fi-sr-p:before {
    content: "\f3fd";
    }
        .fi-sr-package:before {
    content: "\f3fe";
    }
        .fi-sr-page-break:before {
    content: "\f3ff";
    }
        .fi-sr-paint-roller:before {
    content: "\f400";
    }
        .fi-sr-paint:before {
    content: "\f401";
    }
        .fi-sr-palette:before {
    content: "\f402";
    }
        .fi-sr-pan:before {
    content: "\f403";
    }
        .fi-sr-paper-plane:before {
    content: "\f404";
    }
        .fi-sr-party:before {
    content: "\f405";
    }
        .fi-sr-password:before {
    content: "\f406";
    }
        .fi-sr-pause:before {
    content: "\f407";
    }
        .fi-sr-paw:before {
    content: "\f408";
    }
        .fi-sr-peach:before {
    content: "\f409";
    }
        .fi-sr-pencil-ruler:before {
    content: "\f40a";
    }
        .fi-sr-pencil:before {
    content: "\f40b";
    }
        .fi-sr-pennant:before {
    content: "\f40c";
    }
        .fi-sr-people-poll:before {
    content: "\f40d";
    }
        .fi-sr-pepper-hot:before {
    content: "\f40e";
    }
        .fi-sr-pepper:before {
    content: "\f40f";
    }
        .fi-sr-percentage:before {
    content: "\f410";
    }
        .fi-sr-pharmacy:before {
    content: "\f411";
    }
        .fi-sr-phone-call:before {
    content: "\f412";
    }
        .fi-sr-phone-cross:before {
    content: "\f413";
    }
        .fi-sr-phone-office:before {
    content: "\f414";
    }
        .fi-sr-phone-pause:before {
    content: "\f415";
    }
        .fi-sr-phone-slash:before {
    content: "\f416";
    }
        .fi-sr-photo-film-music:before {
    content: "\f417";
    }
        .fi-sr-photo-video:before {
    content: "\f418";
    }
        .fi-sr-physics:before {
    content: "\f419";
    }
        .fi-sr-picnic:before {
    content: "\f41a";
    }
        .fi-sr-picture:before {
    content: "\f41b";
    }
        .fi-sr-pie:before {
    content: "\f41c";
    }
        .fi-sr-piggy-bank:before {
    content: "\f41d";
    }
        .fi-sr-pineapple:before {
    content: "\f41e";
    }
        .fi-sr-pipette:before {
    content: "\f41f";
    }
        .fi-sr-pizza-slice:before {
    content: "\f420";
    }
        .fi-sr-plane-alt:before {
    content: "\f421";
    }
        .fi-sr-plane:before {
    content: "\f422";
    }
        .fi-sr-plate:before {
    content: "\f423";
    }
        .fi-sr-play-alt:before {
    content: "\f424";
    }
        .fi-sr-play-pause:before {
    content: "\f425";
    }
        .fi-sr-play:before {
    content: "\f426";
    }
        .fi-sr-plus-small:before {
    content: "\f427";
    }
        .fi-sr-plus:before {
    content: "\f428";
    }
        .fi-sr-podium-star:before {
    content: "\f429";
    }
        .fi-sr-podium:before {
    content: "\f42a";
    }
        .fi-sr-poo:before {
    content: "\f42b";
    }
        .fi-sr-popcorn:before {
    content: "\f42c";
    }
        .fi-sr-portrait:before {
    content: "\f42d";
    }
        .fi-sr-pot:before {
    content: "\f42e";
    }
        .fi-sr-pound:before {
    content: "\f42f";
    }
        .fi-sr-power:before {
    content: "\f430";
    }
        .fi-sr-presentation:before {
    content: "\f431";
    }
        .fi-sr-print:before {
    content: "\f432";
    }
        .fi-sr-protractor:before {
    content: "\f433";
    }
        .fi-sr-pulse:before {
    content: "\f434";
    }
        .fi-sr-pumpkin:before {
    content: "\f435";
    }
        .fi-sr-puzzle-piece:before {
    content: "\f436";
    }
        .fi-sr-pyramid:before {
    content: "\f437";
    }
        .fi-sr-q:before {
    content: "\f438";
    }
        .fi-sr-qrcode:before {
    content: "\f439";
    }
        .fi-sr-question-square:before {
    content: "\f43a";
    }
        .fi-sr-question:before {
    content: "\f43b";
    }
        .fi-sr-quote-right:before {
    content: "\f43c";
    }
        .fi-sr-r:before {
    content: "\f43d";
    }
        .fi-sr-racquet:before {
    content: "\f43e";
    }
        .fi-sr-radish:before {
    content: "\f43f";
    }
        .fi-sr-rainbow:before {
    content: "\f440";
    }
        .fi-sr-raindrops:before {
    content: "\f441";
    }
        .fi-sr-rec:before {
    content: "\f442";
    }
        .fi-sr-receipt:before {
    content: "\f443";
    }
        .fi-sr-record-vinyl:before {
    content: "\f444";
    }
        .fi-sr-rectabgle-vertical:before {
    content: "\f445";
    }
        .fi-sr-rectangle-barcode:before {
    content: "\f446";
    }
        .fi-sr-rectangle-code:before {
    content: "\f447";
    }
        .fi-sr-rectangle-horizontal:before {
    content: "\f448";
    }
        .fi-sr-rectangle-panoramic:before {
    content: "\f449";
    }
        .fi-sr-rectangle-xmark:before {
    content: "\f44a";
    }
        .fi-sr-recycle:before {
    content: "\f44b";
    }
        .fi-sr-redo-alt:before {
    content: "\f44c";
    }
        .fi-sr-redo:before {
    content: "\f44d";
    }
        .fi-sr-reflect:before {
    content: "\f44e";
    }
        .fi-sr-refresh:before {
    content: "\f44f";
    }
        .fi-sr-registered:before {
    content: "\f450";
    }
        .fi-sr-remove-user:before {
    content: "\f451";
    }
        .fi-sr-reply-all:before {
    content: "\f452";
    }
        .fi-sr-republican:before {
    content: "\f453";
    }
        .fi-sr-resize:before {
    content: "\f454";
    }
        .fi-sr-resources:before {
    content: "\f455";
    }
        .fi-sr-restaurant:before {
    content: "\f456";
    }
        .fi-sr-rewind:before {
    content: "\f457";
    }
        .fi-sr-rhombus:before {
    content: "\f458";
    }
        .fi-sr-rings-wedding:before {
    content: "\f459";
    }
        .fi-sr-road:before {
    content: "\f45a";
    }
        .fi-sr-rocket-lunch:before {
    content: "\f45b";
    }
        .fi-sr-rocket:before {
    content: "\f45c";
    }
        .fi-sr-room-service:before {
    content: "\f45d";
    }
        .fi-sr-rotate-left:before {
    content: "\f45e";
    }
        .fi-sr-rotate-right:before {
    content: "\f45f";
    }
        .fi-sr-ruble-sign:before {
    content: "\f460";
    }
        .fi-sr-rugby-helmet:before {
    content: "\f461";
    }
        .fi-sr-rugby:before {
    content: "\f462";
    }
        .fi-sr-ruler-combined:before {
    content: "\f463";
    }
        .fi-sr-ruler-horizontal:before {
    content: "\f464";
    }
        .fi-sr-ruler-triangle:before {
    content: "\f465";
    }
        .fi-sr-ruler-vertical:before {
    content: "\f466";
    }
        .fi-sr-running:before {
    content: "\f467";
    }
        .fi-sr-rupee-sign:before {
    content: "\f468";
    }
        .fi-sr-rv:before {
    content: "\f469";
    }
        .fi-sr-s:before {
    content: "\f46a";
    }
        .fi-sr-sack-dollar:before {
    content: "\f46b";
    }
        .fi-sr-sack:before {
    content: "\f46c";
    }
        .fi-sr-sad-cry:before {
    content: "\f46d";
    }
        .fi-sr-sad-tear:before {
    content: "\f46e";
    }
        .fi-sr-sad:before {
    content: "\f46f";
    }
        .fi-sr-salad:before {
    content: "\f470";
    }
        .fi-sr-salt-pepper:before {
    content: "\f471";
    }
        .fi-sr-sandwich:before {
    content: "\f472";
    }
        .fi-sr-sauce:before {
    content: "\f473";
    }
        .fi-sr-sausage:before {
    content: "\f474";
    }
        .fi-sr-scale:before {
    content: "\f475";
    }
        .fi-sr-school-bus:before {
    content: "\f476";
    }
        .fi-sr-school:before {
    content: "\f477";
    }
        .fi-sr-scissors:before {
    content: "\f478";
    }
        .fi-sr-screen:before {
    content: "\f479";
    }
        .fi-sr-search-alt:before {
    content: "\f47a";
    }
        .fi-sr-search-dollar:before {
    content: "\f47b";
    }
        .fi-sr-search-heart:before {
    content: "\f47c";
    }
        .fi-sr-search-location:before {
    content: "\f47d";
    }
        .fi-sr-search:before {
    content: "\f47e";
    }
        .fi-sr-select:before {
    content: "\f47f";
    }
        .fi-sr-sensor-alert:before {
    content: "\f480";
    }
        .fi-sr-sensor-fire:before {
    content: "\f481";
    }
        .fi-sr-sensor-on:before {
    content: "\f482";
    }
        .fi-sr-sensor-smoke:before {
    content: "\f483";
    }
        .fi-sr-sensor:before {
    content: "\f484";
    }
        .fi-sr-settings-sliders:before {
    content: "\f485";
    }
        .fi-sr-settings:before {
    content: "\f486";
    }
        .fi-sr-share:before {
    content: "\f487";
    }
        .fi-sr-shekel-sign:before {
    content: "\f488";
    }
        .fi-sr-shield-check:before {
    content: "\f489";
    }
        .fi-sr-shield-exclamation:before {
    content: "\f48a";
    }
        .fi-sr-shield-interrogation:before {
    content: "\f48b";
    }
        .fi-sr-shield-plus:before {
    content: "\f48c";
    }
        .fi-sr-shield:before {
    content: "\f48d";
    }
        .fi-sr-ship-side:before {
    content: "\f48e";
    }
        .fi-sr-ship:before {
    content: "\f48f";
    }
        .fi-sr-shop:before {
    content: "\f490";
    }
        .fi-sr-shopping-bag-add:before {
    content: "\f491";
    }
        .fi-sr-shopping-bag:before {
    content: "\f492";
    }
        .fi-sr-shopping-basket:before {
    content: "\f493";
    }
        .fi-sr-shopping-cart-add:before {
    content: "\f494";
    }
        .fi-sr-shopping-cart-check:before {
    content: "\f495";
    }
        .fi-sr-shopping-cart:before {
    content: "\f496";
    }
        .fi-sr-shrimp:before {
    content: "\f497";
    }
        .fi-sr-shuffle:before {
    content: "\f498";
    }
        .fi-sr-shuttle-van:before {
    content: "\f499";
    }
        .fi-sr-shuttlecock:before {
    content: "\f49a";
    }
        .fi-sr-sidebar-flip:before {
    content: "\f49b";
    }
        .fi-sr-sidebar:before {
    content: "\f49c";
    }
        .fi-sr-sign-in-alt:before {
    content: "\f49d";
    }
        .fi-sr-sign-out-alt:before {
    content: "\f49e";
    }
        .fi-sr-signal-alt-1:before {
    content: "\f49f";
    }
        .fi-sr-signal-alt-2:before {
    content: "\f4a0";
    }
        .fi-sr-signal-alt:before {
    content: "\f4a1";
    }
        .fi-sr-skateboard:before {
    content: "\f4a2";
    }
        .fi-sr-skating:before {
    content: "\f4a3";
    }
        .fi-sr-skewer:before {
    content: "\f4a4";
    }
        .fi-sr-ski-jump:before {
    content: "\f4a5";
    }
        .fi-sr-ski-lift:before {
    content: "\f4a6";
    }
        .fi-sr-skiing-nordic:before {
    content: "\f4a7";
    }
        .fi-sr-skiing:before {
    content: "\f4a8";
    }
        .fi-sr-sledding:before {
    content: "\f4a9";
    }
        .fi-sr-sleigh:before {
    content: "\f4aa";
    }
        .fi-sr-smartphone:before {
    content: "\f4ab";
    }
        .fi-sr-smile-beam:before {
    content: "\f4ac";
    }
        .fi-sr-smile-wink:before {
    content: "\f4ad";
    }
        .fi-sr-smile:before {
    content: "\f4ae";
    }
        .fi-sr-smog:before {
    content: "\f4af";
    }
        .fi-sr-smoke:before {
    content: "\f4b0";
    }
        .fi-sr-snow-blowing:before {
    content: "\f4b1";
    }
        .fi-sr-snowboarding:before {
    content: "\f4b2";
    }
        .fi-sr-snowflake:before {
    content: "\f4b3";
    }
        .fi-sr-snowflakes:before {
    content: "\f4b4";
    }
        .fi-sr-snowmobile:before {
    content: "\f4b5";
    }
        .fi-sr-snowplow:before {
    content: "\f4b6";
    }
        .fi-sr-soap:before {
    content: "\f4b7";
    }
        .fi-sr-sort-alpha-down-alt:before {
    content: "\f4b8";
    }
        .fi-sr-sort-alpha-down:before {
    content: "\f4b9";
    }
        .fi-sr-sort-alpha-up-alt:before {
    content: "\f4ba";
    }
        .fi-sr-sort-alpha-up:before {
    content: "\f4bb";
    }
        .fi-sr-sort-alt:before {
    content: "\f4bc";
    }
        .fi-sr-sort-amount-down-alt:before {
    content: "\f4bd";
    }
        .fi-sr-sort-amount-down:before {
    content: "\f4be";
    }
        .fi-sr-sort-amount-up-alt:before {
    content: "\f4bf";
    }
        .fi-sr-sort-amount-up:before {
    content: "\f4c0";
    }
        .fi-sr-sort-down:before {
    content: "\f4c1";
    }
        .fi-sr-sort-numeric-down-alt:before {
    content: "\f4c2";
    }
        .fi-sr-sort-numeric-down:before {
    content: "\f4c3";
    }
        .fi-sr-sort:before {
    content: "\f4c4";
    }
        .fi-sr-soup:before {
    content: "\f4c5";
    }
        .fi-sr-spa:before {
    content: "\f4c6";
    }
        .fi-sr-space-shuttle:before {
    content: "\f4c7";
    }
        .fi-sr-spade:before {
    content: "\f4c8";
    }
        .fi-sr-sparkles:before {
    content: "\f4c9";
    }
        .fi-sr-speaker:before {
    content: "\f4ca";
    }
        .fi-sr-sphere:before {
    content: "\f4cb";
    }
        .fi-sr-spinner:before {
    content: "\f4cc";
    }
        .fi-sr-spoon:before {
    content: "\f4cd";
    }
        .fi-sr-square-code:before {
    content: "\f4ce";
    }
        .fi-sr-square-root:before {
    content: "\f4cf";
    }
        .fi-sr-square-terminal:before {
    content: "\f4d0";
    }
        .fi-sr-square:before {
    content: "\f4d1";
    }
        .fi-sr-stamp:before {
    content: "\f4d2";
    }
        .fi-sr-star-octogram:before {
    content: "\f4d3";
    }
        .fi-sr-star:before {
    content: "\f4d4";
    }
        .fi-sr-starfighter:before {
    content: "\f4d5";
    }
        .fi-sr-stars:before {
    content: "\f4d6";
    }
        .fi-sr-stats:before {
    content: "\f4d7";
    }
        .fi-sr-steak:before {
    content: "\f4d8";
    }
        .fi-sr-steering-wheel:before {
    content: "\f4d9";
    }
        .fi-sr-stethoscope:before {
    content: "\f4da";
    }
        .fi-sr-sticker:before {
    content: "\f4db";
    }
        .fi-sr-stop:before {
    content: "\f4dc";
    }
        .fi-sr-stopwatch:before {
    content: "\f4dd";
    }
        .fi-sr-store-alt:before {
    content: "\f4de";
    }
        .fi-sr-strawberry:before {
    content: "\f4df";
    }
        .fi-sr-subtitles:before {
    content: "\f4e0";
    }
        .fi-sr-subway:before {
    content: "\f4e1";
    }
        .fi-sr-suitcase-alt:before {
    content: "\f4e2";
    }
        .fi-sr-summer:before {
    content: "\f4e3";
    }
        .fi-sr-sun:before {
    content: "\f4e4";
    }
        .fi-sr-sunrise-alt:before {
    content: "\f4e5";
    }
        .fi-sr-sunrise:before {
    content: "\f4e6";
    }
        .fi-sr-sunset:before {
    content: "\f4e7";
    }
        .fi-sr-surfing:before {
    content: "\f4e8";
    }
        .fi-sr-surprise:before {
    content: "\f4e9";
    }
        .fi-sr-sushi:before {
    content: "\f4ea";
    }
        .fi-sr-swimmer:before {
    content: "\f4eb";
    }
        .fi-sr-sword:before {
    content: "\f4ec";
    }
        .fi-sr-symbol:before {
    content: "\f4ed";
    }
        .fi-sr-syringe:before {
    content: "\f4ee";
    }
        .fi-sr-t:before {
    content: "\f4ef";
    }
        .fi-sr-table-columns:before {
    content: "\f4f0";
    }
        .fi-sr-table-layout:before {
    content: "\f4f1";
    }
        .fi-sr-table-pivot:before {
    content: "\f4f2";
    }
        .fi-sr-table-rows:before {
    content: "\f4f3";
    }
        .fi-sr-table-tree:before {
    content: "\f4f4";
    }
        .fi-sr-tablet:before {
    content: "\f4f5";
    }
        .fi-sr-tachometer-alt-average:before {
    content: "\f4f6";
    }
        .fi-sr-tachometer-alt-fastest:before {
    content: "\f4f7";
    }
        .fi-sr-tachometer-alt-slow:before {
    content: "\f4f8";
    }
        .fi-sr-tachometer-alt-slowest:before {
    content: "\f4f9";
    }
        .fi-sr-tachometer-average:before {
    content: "\f4fa";
    }
        .fi-sr-tachometer-fast:before {
    content: "\f4fb";
    }
        .fi-sr-tachometer-fastest:before {
    content: "\f4fc";
    }
        .fi-sr-tachometer-slow:before {
    content: "\f4fd";
    }
        .fi-sr-tachometer-slowest:before {
    content: "\f4fe";
    }
        .fi-sr-tachometer:before {
    content: "\f4ff";
    }
        .fi-sr-taco:before {
    content: "\f500";
    }
        .fi-sr-tags:before {
    content: "\f501";
    }
        .fi-sr-tally:before {
    content: "\f502";
    }
        .fi-sr-target:before {
    content: "\f503";
    }
        .fi-sr-taxi:before {
    content: "\f504";
    }
        .fi-sr-temperature-down:before {
    content: "\f505";
    }
        .fi-sr-temperature-frigid:before {
    content: "\f506";
    }
        .fi-sr-temperature-high:before {
    content: "\f507";
    }
        .fi-sr-temperature-low:before {
    content: "\f508";
    }
        .fi-sr-temperature-up:before {
    content: "\f509";
    }
        .fi-sr-tenge:before {
    content: "\f50a";
    }
        .fi-sr-tennis:before {
    content: "\f50b";
    }
        .fi-sr-terrace:before {
    content: "\f50c";
    }
        .fi-sr-test-tube:before {
    content: "\f50d";
    }
        .fi-sr-test:before {
    content: "\f50e";
    }
        .fi-sr-text-check:before {
    content: "\f50f";
    }
        .fi-sr-text-slash:before {
    content: "\f510";
    }
        .fi-sr-text:before {
    content: "\f511";
    }
        .fi-sr-thermometer-half:before {
    content: "\f512";
    }
        .fi-sr-thumbs-down:before {
    content: "\f513";
    }
        .fi-sr-thumbs-up:before {
    content: "\f514";
    }
        .fi-sr-thumbtack:before {
    content: "\f515";
    }
        .fi-sr-thunderstorm-moon:before {
    content: "\f516";
    }
        .fi-sr-thunderstorm-sun:before {
    content: "\f517";
    }
        .fi-sr-thunderstorm:before {
    content: "\f518";
    }
        .fi-sr-ticket:before {
    content: "\f519";
    }
        .fi-sr-time-add:before {
    content: "\f51a";
    }
        .fi-sr-time-check:before {
    content: "\f51b";
    }
        .fi-sr-time-delete:before {
    content: "\f51c";
    }
        .fi-sr-time-fast:before {
    content: "\f51d";
    }
        .fi-sr-time-forward-sixty:before {
    content: "\f51e";
    }
        .fi-sr-time-forward-ten:before {
    content: "\f51f";
    }
        .fi-sr-time-forward:before {
    content: "\f520";
    }
        .fi-sr-time-half-past:before {
    content: "\f521";
    }
        .fi-sr-time-oclock:before {
    content: "\f522";
    }
        .fi-sr-time-past:before {
    content: "\f523";
    }
        .fi-sr-time-quarter-past:before {
    content: "\f524";
    }
        .fi-sr-time-quarter-to:before {
    content: "\f525";
    }
        .fi-sr-time-twenty-four:before {
    content: "\f526";
    }
        .fi-sr-tire-flat:before {
    content: "\f527";
    }
        .fi-sr-tire-pressure-warning:before {
    content: "\f528";
    }
        .fi-sr-tire-rugged:before {
    content: "\f529";
    }
        .fi-sr-tire:before {
    content: "\f52a";
    }
        .fi-sr-tired:before {
    content: "\f52b";
    }
        .fi-sr-tomato:before {
    content: "\f52c";
    }
        .fi-sr-tool-box:before {
    content: "\f52d";
    }
        .fi-sr-tool-crop:before {
    content: "\f52e";
    }
        .fi-sr-tool-marquee:before {
    content: "\f52f";
    }
        .fi-sr-tooth:before {
    content: "\f530";
    }
        .fi-sr-tornado:before {
    content: "\f531";
    }
        .fi-sr-tractor:before {
    content: "\f532";
    }
        .fi-sr-trailer:before {
    content: "\f533";
    }
        .fi-sr-train-side:before {
    content: "\f534";
    }
        .fi-sr-train:before {
    content: "\f535";
    }
        .fi-sr-tram:before {
    content: "\f536";
    }
        .fi-sr-transform:before {
    content: "\f537";
    }
        .fi-sr-trash:before {
    content: "\f538";
    }
        .fi-sr-treasure-chest:before {
    content: "\f539";
    }
        .fi-sr-treatment:before {
    content: "\f53a";
    }
        .fi-sr-tree-christmas:before {
    content: "\f53b";
    }
        .fi-sr-tree:before {
    content: "\f53c";
    }
        .fi-sr-triangle:before {
    content: "\f53d";
    }
        .fi-sr-trophy:before {
    content: "\f53e";
    }
        .fi-sr-truck-container:before {
    content: "\f53f";
    }
        .fi-sr-truck-couch:before {
    content: "\f540";
    }
        .fi-sr-truck-loading:before {
    content: "\f541";
    }
        .fi-sr-truck-monster:before {
    content: "\f542";
    }
        .fi-sr-truck-moving:before {
    content: "\f543";
    }
        .fi-sr-truck-pickup:before {
    content: "\f544";
    }
        .fi-sr-truck-plow:before {
    content: "\f545";
    }
        .fi-sr-truck-ramp:before {
    content: "\f546";
    }
        .fi-sr-truck-side:before {
    content: "\f547";
    }
        .fi-sr-tty:before {
    content: "\f548";
    }
        .fi-sr-turkey:before {
    content: "\f549";
    }
        .fi-sr-tv-music:before {
    content: "\f54a";
    }
        .fi-sr-typewriter:before {
    content: "\f54b";
    }
        .fi-sr-u:before {
    content: "\f54c";
    }
        .fi-sr-umbrella:before {
    content: "\f54d";
    }
        .fi-sr-underline:before {
    content: "\f54e";
    }
        .fi-sr-undo-alt:before {
    content: "\f54f";
    }
        .fi-sr-undo:before {
    content: "\f550";
    }
        .fi-sr-unlock:before {
    content: "\f551";
    }
        .fi-sr-upload:before {
    content: "\f552";
    }
        .fi-sr-usb-pendrive:before {
    content: "\f553";
    }
        .fi-sr-usd-circle:before {
    content: "\f554";
    }
        .fi-sr-usd-square:before {
    content: "\f555";
    }
        .fi-sr-user-add:before {
    content: "\f556";
    }
        .fi-sr-user-time:before {
    content: "\f557";
    }
        .fi-sr-user:before {
    content: "\f558";
    }
        .fi-sr-users-alt:before {
    content: "\f559";
    }
        .fi-sr-users:before {
    content: "\f55a";
    }
        .fi-sr-utensils:before {
    content: "\f55b";
    }
        .fi-sr-v:before {
    content: "\f55c";
    }
        .fi-sr-vector-alt:before {
    content: "\f55d";
    }
        .fi-sr-vector:before {
    content: "\f55e";
    }
        .fi-sr-venus-double:before {
    content: "\f55f";
    }
        .fi-sr-venus-mars:before {
    content: "\f560";
    }
        .fi-sr-venus:before {
    content: "\f561";
    }
        .fi-sr-video-arrow-down-left:before {
    content: "\f562";
    }
        .fi-sr-video-arrow-up-right:before {
    content: "\f563";
    }
        .fi-sr-video-camera-alt:before {
    content: "\f564";
    }
        .fi-sr-video-camera:before {
    content: "\f565";
    }
        .fi-sr-video-plus:before {
    content: "\f566";
    }
        .fi-sr-video-slash:before {
    content: "\f567";
    }
        .fi-sr-volcano:before {
    content: "\f568";
    }
        .fi-sr-volleyball:before {
    content: "\f569";
    }
        .fi-sr-volume:before {
    content: "\f56a";
    }
        .fi-sr-w:before {
    content: "\f56b";
    }
        .fi-sr-wagon-covered:before {
    content: "\f56c";
    }
        .fi-sr-wallet:before {
    content: "\f56d";
    }
        .fi-sr-water-bottle:before {
    content: "\f56e";
    }
        .fi-sr-water-lower:before {
    content: "\f56f";
    }
        .fi-sr-water-rise:before {
    content: "\f570";
    }
        .fi-sr-water:before {
    content: "\f571";
    }
        .fi-sr-watermelon:before {
    content: "\f572";
    }
        .fi-sr-wheat:before {
    content: "\f573";
    }
        .fi-sr-wheelchair:before {
    content: "\f574";
    }
        .fi-sr-whistle:before {
    content: "\f575";
    }
        .fi-sr-wifi-alt:before {
    content: "\f576";
    }
        .fi-sr-wind-warning:before {
    content: "\f577";
    }
        .fi-sr-wind:before {
    content: "\f578";
    }
        .fi-sr-windsock:before {
    content: "\f579";
    }
        .fi-sr-woman-head:before {
    content: "\f57a";
    }
        .fi-sr-world:before {
    content: "\f57b";
    }
        .fi-sr-wrench-simple:before {
    content: "\f57c";
    }
        .fi-sr-x:before {
    content: "\f57d";
    }
        .fi-sr-y:before {
    content: "\f57e";
    }
        .fi-sr-yen:before {
    content: "\f57f";
    }
        .fi-sr-z:before {
    content: "\f580";
    }
        .fi-sr-zoom-in:before {
    content: "\f581";
    }
        .fi-sr-zoom-out:before {
    content: "\f582";
    }

@font-face {
  font-family: "uicons-brands";
  src: url(/_next/static/media/uicons-brands.c69fb95c.eot#iefix) format("embedded-opentype"),
    url(/_next/static/media/uicons-brands.49167991.woff2) format("woff2"),
    url(/_next/static/media/uicons-brands.841cfe5d.woff) format("woff");
}

i[class^="fi-brands-"]:before,
i[class*=" fi-brands-"]:before,
span[class^="fi-brands-"]:before,
span[class*="fi-brands-"]:before {
  font-family: uicons-brands !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-brands-3m:before {
  content: "\f101";
}
.fi-brands-500px:before {
  content: "\f102";
}
.fi-brands-abbot-laboratories:before {
  content: "\f103";
}
.fi-brands-accusoft:before {
  content: "\f104";
}
.fi-brands-acrobat:before {
  content: "\f105";
}
.fi-brands-adobe:before {
  content: "\f106";
}
.fi-brands-aecom:before {
  content: "\f107";
}
.fi-brands-aero:before {
  content: "\f108";
}
.fi-brands-after-effects:before {
  content: "\f109";
}
.fi-brands-airbnb:before {
  content: "\f10a";
}
.fi-brands-algolia:before {
  content: "\f10b";
}
.fi-brands-amd:before {
  content: "\f10c";
}
.fi-brands-american-express:before {
  content: "\f10d";
}
.fi-brands-android:before {
  content: "\f10e";
}
.fi-brands-animate:before {
  content: "\f10f";
}
.fi-brands-app-store-ios:before {
  content: "\f110";
}
.fi-brands-apple-pay:before {
  content: "\f111";
}
.fi-brands-apple:before {
  content: "\f112";
}
.fi-brands-artstation:before {
  content: "\f113";
}
.fi-brands-astrazeneca:before {
  content: "\f114";
}
.fi-brands-asus:before {
  content: "\f115";
}
.fi-brands-atandt:before {
  content: "\f116";
}
.fi-brands-atlassian:before {
  content: "\f117";
}
.fi-brands-atom:before {
  content: "\f118";
}
.fi-brands-audition:before {
  content: "\f119";
}
.fi-brands-behance:before {
  content: "\f11a";
}
.fi-brands-bitcoin:before {
  content: "\f11b";
}
.fi-brands-blackberry:before {
  content: "\f11c";
}
.fi-brands-blogger:before {
  content: "\f11d";
}
.fi-brands-bluetooth:before {
  content: "\f11e";
}
.fi-brands-bootstrap:before {
  content: "\f11f";
}
.fi-brands-bridgestone:before {
  content: "\f120";
}
.fi-brands-burger-king:before {
  content: "\f121";
}
.fi-brands-c:before {
  content: "\f122";
}
.fi-brands-capture:before {
  content: "\f123";
}
.fi-brands-cc-apple-pay:before {
  content: "\f124";
}
.fi-brands-cc-diners-club:before {
  content: "\f125";
}
.fi-brands-cc-visa:before {
  content: "\f126";
}
.fi-brands-cc-amazon-pay:before {
  content: "\f127";
}
.fi-brands-centos:before {
  content: "\f128";
}
.fi-brands-character:before {
  content: "\f129";
}
.fi-brands-chromecast:before {
  content: "\f12a";
}
.fi-brands-cloudflare:before {
  content: "\f12b";
}
.fi-brands-confluence:before {
  content: "\f12c";
}
.fi-brands-creative-commons-by:before {
  content: "\f12d";
}
.fi-brands-creative-commons-nc-eu:before {
  content: "\f12e";
}
.fi-brands-creative-commons-nc-jp:before {
  content: "\f12f";
}
.fi-brands-creative-commons-nc:before {
  content: "\f130";
}
.fi-brands-creative-commons-nd:before {
  content: "\f131";
}
.fi-brands-creative-commons-pd-alt:before {
  content: "\f132";
}
.fi-brands-creative-commons-pd:before {
  content: "\f133";
}
.fi-brands-creative-commons-remix:before {
  content: "\f134";
}
.fi-brands-creative-commons-sa:before {
  content: "\f135";
}
.fi-brands-creative-commons-sampling-plus:before {
  content: "\f136";
}
.fi-brands-creative-commons-sampling:before {
  content: "\f137";
}
.fi-brands-creative-commons-share:before {
  content: "\f138";
}
.fi-brands-creative-commons-zero:before {
  content: "\f139";
}
.fi-brands-creative-commons:before {
  content: "\f13a";
}
.fi-brands-css3-alt:before {
  content: "\f13b";
}
.fi-brands-css3:before {
  content: "\f13c";
}
.fi-brands-dailymotion:before {
  content: "\f13d";
}
.fi-brands-deezer:before {
  content: "\f13e";
}
.fi-brands-delphi:before {
  content: "\f13f";
}
.fi-brands-dev:before {
  content: "\f140";
}
.fi-brands-devianart:before {
  content: "\f141";
}
.fi-brands-digg:before {
  content: "\f142";
}
.fi-brands-dimension:before {
  content: "\f143";
}
.fi-brands-discord:before {
  content: "\f144";
}
.fi-brands-docker:before {
  content: "\f145";
}
.fi-brands-dribbble:before {
  content: "\f146";
}
.fi-brands-dropbox:before {
  content: "\f147";
}
.fi-brands-drupal:before {
  content: "\f148";
}
.fi-brands-ebay:before {
  content: "\f149";
}
.fi-brands-elementor:before {
  content: "\f14a";
}
.fi-brands-ethereum:before {
  content: "\f14b";
}
.fi-brands-etsy:before {
  content: "\f14c";
}
.fi-brands-evernote:before {
  content: "\f14d";
}
.fi-brands-facebook-messenger:before {
  content: "\f14e";
}
.fi-brands-facebook:before {
  content: "\f14f";
}
.fi-brands-fedex:before {
  content: "\f150";
}
.fi-brands-figma:before {
  content: "\f151";
}
.fi-brands-firefox-browser:before {
  content: "\f152";
}
.fi-brands-firefox:before {
  content: "\f153";
}
.fi-brands-flickr:before {
  content: "\f154";
}
.fi-brands-flipboard:before {
  content: "\f155";
}
.fi-brands-fonts:before {
  content: "\f156";
}
.fi-brands-foursquare:before {
  content: "\f157";
}
.fi-brands-fresco:before {
  content: "\f158";
}
.fi-brands-github:before {
  content: "\f159";
}
.fi-brands-gitlab:before {
  content: "\f15a";
}
.fi-brands-goodreads:before {
  content: "\f15b";
}
.fi-brands-google:before {
  content: "\f15c";
}
.fi-brands-haskell:before {
  content: "\f15d";
}
.fi-brands-hbo:before {
  content: "\f15e";
}
.fi-brands-hotjar:before {
  content: "\f15f";
}
.fi-brands-html5:before {
  content: "\f160";
}
.fi-brands-huawei:before {
  content: "\f161";
}
.fi-brands-hubspot:before {
  content: "\f162";
}
.fi-brands-ibm:before {
  content: "\f163";
}
.fi-brands-imdb:before {
  content: "\f164";
}
.fi-brands-incopy:before {
  content: "\f165";
}
.fi-brands-indesign:before {
  content: "\f166";
}
.fi-brands-instagram:before {
  content: "\f167";
}
.fi-brands-intel:before {
  content: "\f168";
}
.fi-brands-invision:before {
  content: "\f169";
}
.fi-brands-itunes:before {
  content: "\f16a";
}
.fi-brands-janseen:before {
  content: "\f16b";
}
.fi-brands-java:before {
  content: "\f16c";
}
.fi-brands-jcb:before {
  content: "\f16d";
}
.fi-brands-jira:before {
  content: "\f16e";
}
.fi-brands-johnson-and-johnson:before {
  content: "\f16f";
}
.fi-brands-joomla:before {
  content: "\f170";
}
.fi-brands-js:before {
  content: "\f171";
}
.fi-brands-kickstarter:before {
  content: "\f172";
}
.fi-brands-line:before {
  content: "\f173";
}
.fi-brands-linkedin:before {
  content: "\f174";
}
.fi-brands-lisp:before {
  content: "\f175";
}
.fi-brands-mailchimp:before {
  content: "\f176";
}
.fi-brands-marriott-international:before {
  content: "\f177";
}
.fi-brands-mcdonalds:before {
  content: "\f178";
}
.fi-brands-media-encoder:before {
  content: "\f179";
}
.fi-brands-medium:before {
  content: "\f17a";
}
.fi-brands-meta:before {
  content: "\f17b";
}
.fi-brands-microsoft-explorer:before {
  content: "\f17c";
}
.fi-brands-mysql:before {
  content: "\f17d";
}
.fi-brands-napster:before {
  content: "\f17e";
}
.fi-brands-nestle:before {
  content: "\f17f";
}
.fi-brands-netflix:before {
  content: "\f180";
}
.fi-brands-node-js:before {
  content: "\f181";
}
.fi-brands-nvidia:before {
  content: "\f182";
}
.fi-brands-oracle:before {
  content: "\f183";
}
.fi-brands-patreon:before {
  content: "\f184";
}
.fi-brands-paypal:before {
  content: "\f185";
}
.fi-brands-pfizer:before {
  content: "\f186";
}
.fi-brands-photoshop-camera:before {
  content: "\f187";
}
.fi-brands-photoshop-express:before {
  content: "\f188";
}
.fi-brands-photoshop-lightroom-classic:before {
  content: "\f189";
}
.fi-brands-photoshop-lightroom:before {
  content: "\f18a";
}
.fi-brands-photoshop:before {
  content: "\f18b";
}
.fi-brands-php:before {
  content: "\f18c";
}
.fi-brands-pinterest:before {
  content: "\f18d";
}
.fi-brands-postgre:before {
  content: "\f18e";
}
.fi-brands-premiere-rush:before {
  content: "\f18f";
}
.fi-brands-premiere:before {
  content: "\f190";
}
.fi-brands-product-hunt:before {
  content: "\f191";
}
.fi-brands-python:before {
  content: "\f192";
}
.fi-brands-raspberry-pi:before {
  content: "\f193";
}
.fi-brands-reddit:before {
  content: "\f194";
}
.fi-brands-samsung:before {
  content: "\f195";
}
.fi-brands-sap:before {
  content: "\f196";
}
.fi-brands-sass:before {
  content: "\f197";
}
.fi-brands-shopify:before {
  content: "\f198";
}
.fi-brands-siemens:before {
  content: "\f199";
}
.fi-brands-sketch:before {
  content: "\f19a";
}
.fi-brands-skype:before {
  content: "\f19b";
}
.fi-brands-slack:before {
  content: "\f19c";
}
.fi-brands-snapchat:before {
  content: "\f19d";
}
.fi-brands-sony:before {
  content: "\f19e";
}
.fi-brands-soundcloud:before {
  content: "\f19f";
}
.fi-brands-spark:before {
  content: "\f1a0";
}
.fi-brands-spotify:before {
  content: "\f1a1";
}
.fi-brands-starbucks:before {
  content: "\f1a2";
}
.fi-brands-stock:before {
  content: "\f1a3";
}
.fi-brands-stripe:before {
  content: "\f1a4";
}
.fi-brands-substance-3d-designer:before {
  content: "\f1a5";
}
.fi-brands-substance-3d-painter:before {
  content: "\f1a6";
}
.fi-brands-substance-3d-sampler:before {
  content: "\f1a7";
}
.fi-brands-substance-3d-stager:before {
  content: "\f1a8";
}
.fi-brands-swift:before {
  content: "\f1a9";
}
.fi-brands-t-mobile:before {
  content: "\f1aa";
}
.fi-brands-telegram:before {
  content: "\f1ab";
}
.fi-brands-tencent:before {
  content: "\f1ac";
}
.fi-brands-the-home-depot:before {
  content: "\f1ad";
}
.fi-brands-tik-tok:before {
  content: "\f1ae";
}
.fi-brands-trello:before {
  content: "\f1af";
}
.fi-brands-tripadvisor:before {
  content: "\f1b0";
}
.fi-brands-tumblr:before {
  content: "\f1b1";
}
.fi-brands-twitch:before {
  content: "\f1b2";
}
.fi-brands-twitter:before {
  content: "\f1b3";
}
.fi-brands-typescript:before {
  content: "\f1b4";
}
.fi-brands-uber:before {
  content: "\f1b5";
}
.fi-brands-ubuntu:before {
  content: "\f1b6";
}
.fi-brands-unilever:before {
  content: "\f1b7";
}
.fi-brands-unity:before {
  content: "\f1b8";
}
.fi-brands-unsplash:before {
  content: "\f1b9";
}
.fi-brands-ups:before {
  content: "\f1ba";
}
.fi-brands-usaa:before {
  content: "\f1bb";
}
.fi-brands-verizon:before {
  content: "\f1bc";
}
.fi-brands-vimeo:before {
  content: "\f1bd";
}
.fi-brands-visa:before {
  content: "\f1be";
}
.fi-brands-visual-basic:before {
  content: "\f1bf";
}
.fi-brands-vk:before {
  content: "\f1c0";
}
.fi-brands-walmart:before {
  content: "\f1c1";
}
.fi-brands-whatsapp:before {
  content: "\f1c2";
}
.fi-brands-wikipedia:before {
  content: "\f1c3";
}
.fi-brands-windows:before {
  content: "\f1c4";
}
.fi-brands-wix:before {
  content: "\f1c5";
}
.fi-brands-wordpress:before {
  content: "\f1c6";
}
.fi-brands-xd:before {
  content: "\f1c7";
}
.fi-brands-xing:before {
  content: "\f1c8";
}
.fi-brands-yahoo:before {
  content: "\f1c9";
}
.fi-brands-yandex:before {
  content: "\f1ca";
}
.fi-brands-yelp:before {
  content: "\f1cb";
}
.fi-brands-youtube:before {
  content: "\f1cc";
}
.fi-brands-zoom:before {
  content: "\f1cd";
}

@font-face {
font-family: '__sfpro_93add3';
src: url(/_next/static/media/c0881e36d2b9b762-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__sfpro_93add3';
src: url(/_next/static/media/91ff01368594b0e2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__sfpro_93add3';
src: url(/_next/static/media/10e75488f67ded43-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__sfpro_Fallback_93add3';src: local("Arial");ascent-override: 88.96%;descent-override: 22.54%;line-gap-override: 0.00%;size-adjust: 107.03%
}.__className_93add3 {font-family: '__sfpro_93add3', '__sfpro_Fallback_93add3'
}.__variable_93add3 {--font-sfpro: '__sfpro_93add3', '__sfpro_Fallback_93add3'
}

